import { Container } from "react-bootstrap";
import "../../sass/layout/optionListContainer/optionListContainer.css";
import OptionPreviewList from "../options/optionPreviewList";
import { useSelector, useDispatch } from "react-redux";
import { fetchOptionDetails } from '../../services/actions/options.action';
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const OptionList = () => {
  const { option } = useSelector((state) => state.optionReducer);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    fetchOptionDetails(dispatch, id);
  }, [dispatch, id]);

  let dropdown_options = [];
  if (option) {
    dropdown_options = [...option?.dropdown_options].sort((a, b) => a.created_at - b.created_at);
  }

  return (
    <Container fluid className="option-container">
      {dropdown_options
      .filter((option) => option.deleted === false)
      .map((option, index) => {
        const newOption = {...option};
        if (option?.default_type === 'ftp') {
          const newOptionReportPeriod = option?.report_period || 'current';
          newOption.report_period = newOptionReportPeriod;
          newOption.default_category = option?.default_category + `?report_type=${newOptionReportPeriod}`;
        }
        return (
          <OptionPreviewList
            key={index}
            border={option?.id == option?.dropdown_options?.length - 1 ? false : true}
            option={newOption}
          />
        );
      })}
    </Container>
  );
};

export default OptionList;