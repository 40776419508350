import { Container } from "react-bootstrap";
import "../../sass/layout/articleListContainer/articleListContainer.css";
import ArticlePreviewList from "./articlePreviewList";
import { useSelector } from "react-redux";
import usePagination from "../../hooks/usePagination";
import Paginator from '../paginator/paginator';
import { fetchArticles } from '../../services/actions/articles.action';

const ArticleListContainer = () => {
  const {
    articles,
    meta,
  } = useSelector((state) => ({
    articles: state.articleReducer.articlesList,
    meta: state.articleReducer.articlesMeta,
  }));

  const { items, paginationParams, onClickPage } = usePagination(
    fetchArticles,
    meta,
  );

  return (
    <Container fluid className="article-container">
      {articles?.length > 0 && articles.map((article, index) => {
        return (
          <ArticlePreviewList
            key={index}
            border={article?.id == articles?.length - 1 ? false : true}
            article={article}
          />
        );
      })}
      <Container fluid className="pagination-articles">
        <Paginator
          items={items}
          filterParams={paginationParams}
          metadata={meta}
          onClickPage={onClickPage}
        />
      </Container>
    </Container>
  );
};

export default ArticleListContainer;
