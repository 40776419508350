import { createSlice } from '@reduxjs/toolkit'

const programReducer = createSlice({
  name: 'programs',
  initialState: {
    programsList: [],
  },
  reducers: {
    setProgramsList: (state, action) => {
      state.programsList = action.payload
    }
  },
 cleanState: (state) => {
      state = programReducer.initialState;
    },
})

export const { setProgramsList, setErrorPrograms } = programReducer.actions

export default programReducer.reducer