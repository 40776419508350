import "../../sass/layout/loginBtn/loginBtn.css";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, ListGroup, NavLink } from "react-bootstrap";
import {
  findOnePortalUser,
  resetDetailUser,
} from "../../services/actions/user.action";
import { Nav, Row, Popover, OverlayTrigger, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowDropDown } from "react-icons/md";
import UserService from "../.././services/api/UserService";
import { setLoginStatus } from "../../services/reducers/userReducer";
import english from "../../utils/img/flags/united-states.png";
import spanish from "../../utils/img/flags/spain.png";
import { usePath } from "../../hooks/usePath";
import { hasUserAdministrationPermission } from "../../helpers/roles";
import { useParams } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpanelIntegration";

const LoginBtn = () => {
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const navigate = useNavigate();
  const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const location = useSelector((state) => state.homeReducer.location?.data);
  const dispatch = useDispatch();
  const path = usePath();
  const [lang, setLang] = useState("EN");
  const [show, setShow] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  const handleClose = () => setShow(false);

  const { infoUser, userDetail } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (infoUser?.email && UserService.getToken()) {
      findOnePortalUser(dispatch, infoUser?.email);
    }
  }, [dispatch, infoUser]);

  return (
    <div className="loginBtn">
      <Nav.Item
        bsPrefix={
          UserService.isLoggedIn()
            ? "navbar-label-rest islogged"
            : id !== "/"
            ? "navbar-label-rest"
            : "navbar-label-rest-nolocation"
        }
      >
        {UserService.isLoggedIn() ? (
          <>
            {window.location.pathname !== "/" && (
              <div
                onClick={() => {
                  setShowPopover(!showPopover);
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "7rem",
                  marginLeft: "2rem",
                }}
              >
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  show={showPopover}
                  rootClose
                  onToggle={(e) => {
                    setShowPopover(e);
                  }}
                  overlay={
                    <Popover id={`popover-positioned-${"bottom"}`}>
                      {hasUserAdministrationPermission(userDetail) && (
                        <Popover.Header bsPrefix="header-popover">
                          <div
                            onClick={() => {
                              navigate(`/${id}/portal-administration`);
                              setShowPopover(false);
                            }}
                            className="path-item"
                          >
                            <h5 className="navbar-subtitle-logo-login">
                              {t("homepage.portaladministration")}
                            </h5>
                          </div>
                        </Popover.Header>
                      )}
                      <Popover.Body className="body-popover">
                        <Button
                          bsPrefix="btn button-popover"
                          onClick={(e) => {
                            e.preventDefault();
                            resetDetailUser(dispatch);
                            UserService.doLogout();
                          }}
                        >
                          {t("homepage.logout")}
                        </Button>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Dropdown
                  // style={{
                  //   width: "3rem !important",
                  // }}
                  >
                    {infoUser?.given_name || "User"}
                  </Dropdown>
                </OverlayTrigger>
                <MdOutlineArrowDropDown size={20} color={"black"} />
              </div>
            )}

            <Row
              style={
                loginStatus
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
                  : {
                      width: "5.2rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }
              }
            >
              <button onClick={setShow} className="btn-body">
                <label
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {lang}
                </label>
              </button>
            </Row>
          </>
        ) : (
          <Row className={id !== "/" ? "other-yellow-navbar" : ""}>
            {window.location.pathname !== "/" && (
              <NavLink
                onClick={() => {
                  UserService.doLogin();
                }}
                bsPrefix="btn-login"
              >
                {t("homepage.administratorLogin")}
              </NavLink>
            )}
            <button
              className={
                window.location.pathname === "/"
                  ? "btn-lenguage btn-body"
                  : "btn-switch-lenguage btn-body"
              }
              onClick={setShow}
            >
              <label
                style={{
                  fontSize: "12px",
                }}
              >
                {lang}
              </label>
            </button>
          </Row>
        )}
      </Nav.Item>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header
            style={{
              borderBottom: "none",
            }}
            closeButton
          >
            <Modal.Title>Language</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup>
              <ListGroup.Item
                action
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onClick={() => {
                  Mixpanel.track("Change language to English",{locationName: location?.name});
                  i18n.changeLanguage("en");
                  setLang("EN");
                  handleClose();
                }}
              >
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "1rem",
                  }}
                  src={english}
                ></img>
                English
              </ListGroup.Item>
              <ListGroup.Item
                action
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                onClick={() => {
                  Mixpanel.track("Change language to Spanish",{locationName: location?.name});
                  i18n.changeLanguage("es");
                  setLang("ES");
                  handleClose();
                }}
              >
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "1rem",
                  }}
                  src={spanish}
                ></img>
                Spanish
              </ListGroup.Item>
            </ListGroup>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};

export default LoginBtn;
