import { Container } from "react-bootstrap";
import "../sass/layout/homeSchedule/homeSchedule.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getOnlineClasses } from "../services/actions/wellnessclasses.action";
import { useEffect } from "react";
import WellnessItemHome from "./wellnessevents/wellnessitemhome";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { usePath } from "../hooks/usePath";
import usePagination from '../hooks/usePagination';

const HomeSchedule = () => {
  const { t } = useTranslation();
  const path = usePath();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    id,
    online,
    meta,
  } = useSelector((state) => ({
    id: state.homeReducer.location?.data?.uniqueNonGuessableId,
    online: state.wellnesseventsReducer.onlineClasses,
    meta: state.wellnesseventsReducer.wellnessClassesMeta,
  }));

  useEffect(() => {
    if (id) getOnlineClasses(dispatch, id);
  }, [dispatch, id])

  const { loading } = usePagination(
    getOnlineClasses,
    meta,
    { id, max: 4 }
  );

  return (
    <Container fluid className="classes-container">
      <Container fluid className="titles-box-classes">
        <h4 className="title-events-home">
          {t("homepage.wellnessupcomingclasses")}
        </h4>
        {online?.length > 0 ? (
          <div
            role={"button"}
            onClick={() => {
              navigate(`/${path}/wellnessevents`);
            }}
          >
            <span className="span-all-clases">
              {t("homepage.seeallclasses")}
            </span>
          </div>
        ) : (
          <a
            href="https://miiaqa2.blob.core.windows.net/public-for-portal-qa/assets/NoOnsiteClasses available.pdf"
            className="link-all-clases"
          >
            <span className="span-all-clases">
              {t("homepage.seeallclasses")}
            </span>
          </a>
        )}
      </Container>
      <Container fluid className="homeSchedule-container">
        {loading ? (
          <Spinner animation="border" variant="danger" className="spinner" />
        ) : (
          <Container fluid className="list-group-box">
            {online?.slice(0, 4).map((element, index) => {
              return (
                <WellnessItemHome
                  itemClass={element}
                  key={index}
                  last={index === 1}
                />
              );
            })}
          </Container>
        )}
      </Container>
    </Container>
  );
};

export default HomeSchedule;
