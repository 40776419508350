import { getLocation } from "../services/actions/home.action";
import UserService from "../services/api/UserService";

const removeExtraUrlCharacters = (path) => path.split("/")[1];

export const handleRedirection = (
  myUserInfo,
  locationIdFromURL,
  navigationBlocked,
  navigate
) => {
  const isUserLoggedIn = UserService.isLoggedIn();
  const isPublicPortal = !locationIdFromURL;
  const realUserLocation = myUserInfo?.location;
  const shouldRedirect =
    isUserLoggedIn &&
    (realUserLocation !== locationIdFromURL || isPublicPortal);

  if (shouldRedirect && !navigationBlocked) {
    navigate(`/${realUserLocation}`);
  }
};

export const handleLocationUpdate = (dispatch, userLocation) => {
  const isUserLoggedIn = UserService.isLoggedIn();
  let locationToRedirect = null;

  if (userLocation && isUserLoggedIn) {
    locationToRedirect = userLocation;
  } else {
    const locationFromURL = removeExtraUrlCharacters(window.location.pathname);

    locationToRedirect = locationFromURL;
  }

  getLocation(dispatch, locationToRedirect);
};
