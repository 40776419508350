import { Container } from "react-bootstrap";
import "../../sass/layout/articleContainer/articleContainer.css";
import Image from "react-bootstrap/Image";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { articleDetail } from "../../services/reducers/articleReducer";
import { usePath } from "../../hooks/usePath";

const ArticlePreview = ({ article }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const path = usePath();
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  const onClickArticle = () => {
    if (id !== "/") {
      navigate(`/${path}/article/${article?.id}`);
    } else {
      id === "/" && navigate(`/article/${article?.id}`);
    }
    dispatch(articleDetail({ articleSelected: article }));
  };

  return (
    <Container fluid onClick={onClickArticle}>
      <Link to={""} onClick={onClickArticle} className="link-box">
        <Image
          fluid
          src={
            article?.attributes?.image?.data?.attributes?.url + "?" + tokenMedia
          }
          className="article-img-preview"
        />
        <Container fluid className="article-preview">
          <h3>{article?.attributes?.title}</h3>
          <p>{article?.attributes?.description}</p>
        </Container>
      </Link>
    </Container>
  );
};

export default ArticlePreview;
