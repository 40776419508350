import { getSearchData } from "../reducers/searchReducer";
import axios, { authHeader } from "../api/HttpService";
import UserService from "../api/UserService";
const API_URL = process.env.REACT_APP_API_URL;

export const getSearch = (query) => (dispatch) => {
  const response1 = UserService.isLoggedIn()
    ? axios.get(
        API_URL +
          `/api/articles/findPublicAndByLocation?filters[$and][0][$or][0][tags][name][$containsi]=${query}&filters[$and][0][$or][1][title][$containsi]=${query}`,
        { headers: authHeader() }
      )
    : axios.get(
        API_URL +
          `/api/articles?filters[$or][0][tags][name][$containsi]=${query}&filters[$or][1][title][$containsi]=${query}`,
        { headers: authHeader() }
      );
  const response2 = axios.get(
    API_URL +
      `/api/recipes?filters[$or][0][tags][name][$containsi]=${query}&filters[$or][1][name][$containsi]=${query}`,
    { headers: authHeader() }
  );
  const response3 = UserService.isLoggedIn()
    ? axios.get(API_URL + `api/pages?filters[title][$containsi]=${query}`, {
        headers: authHeader(),
      })
    : null;
  const response4 = UserService.isLoggedIn()
    ? axios.get(
        API_URL + `api/contacts?filters[full_name][$containsi]=${query}`,
        { headers: authHeader() }
      )
    : null;

  UserService.isLoggedIn()
    ? Promise.all([response1, response2, response3, response4]).then(
        (values) => {
          let results = values?.filter((value) => {
            if (value?.data.data?.length > 0) return value?.data.data;
          });
          results = results.map(element => element.data.data)
          dispatch(getSearchData({ search: results }));
        }
      )
    : Promise.all([response1, response2]).then((values) => {
        let results = values?.filter((value) => {
          if (value?.data.data?.length > 0) return value?.data.data;
        });
        results = results.map(element => element.data.data)
        dispatch(getSearchData({ search: results }));
      });
};
