import { createSlice } from "@reduxjs/toolkit";

const wellnesseventsReducer = createSlice({
  name: "wellnessevents",
  initialState: {
    onlineClasses: null,
    onsiteClasses: null,
    wellnessClassSelected: null,
    errorWellnessClass: null,
    zoomClasses: null,
    wellnessClassesMeta: null,
  },
  reducers: {
    setDataOnlineClasses: (state, action) => {
      state.onlineClasses = action.payload.classes;
      state.errorWellnessClass = null;
    },
    setDataOnsiteClasses: (state, action) => {
      state.onsiteClasses = action.payload.classes;
      state.errorWellnessClass = null;
    },
    setDataZoomClasses: (state, action) => {
      state.zoomClasses = action.payload.classes;
      state.errorWellnessClass = null;
    },
    setDetailWellnessClass: (state, action) => {
      state.wellnessClassSelected = action.payload.wellnessClassSelected;
      state.errorWellnessClass = null;
    },
    setErrorWellnessClass: (state, action) => {
      state.errorWellnessClass = action.payload.errorWellnessClass;
    },
    setClassesMeta: (state, action) => {
      state.wellnessClassesMeta = action.payload.wellnessClassesMeta;
    },
    cleanState: (state) => {
      state = wellnesseventsReducer.initialState;
    },
  },
});

export const {
  setDataClasses,
  setErrorWellnessClass,
  setDetailWellnessClass,
  setDataOnlineClasses,
  setDataOnsiteClasses,
  setDataZoomClasses,
  setClassesMeta,
  cleanState,
} = wellnesseventsReducer.actions;

export default wellnesseventsReducer.reducer;
