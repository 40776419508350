import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: [],
};

export const searchReducer = createSlice({
  name: "search",
  initialState,
  reducers: {
    getSearchData: (state, action) => {
      state.search = action.payload.search;
    },
  },
});

export const { getSearchData } = searchReducer.actions;

export default searchReducer.reducer;
