import "../../sass/layout/recipeContainer/recipeContainer.css";
import RecipeContent from "./recipeContent";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Container } from "react-bootstrap";
import imgPlaceholder from "../../utils/img/imgPlaceholder.png";

const RecipeContainer = ({ componentRef, recipe }) => {
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const [printing, setPrinting] = useState(false);
  const onBeforeGetContentResolve = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        resolve();
      });
    },
  });

  const PrintHeader = () => {
    return (
      <div className="container-fluid">
        <div className="navbar-container-title">
          <h1 className="navbar-title-header">MIIA</h1>
          <h5 className="navbar-subtitle">Member Portal</h5>
        </div>
      </div>
    );
  };

  return (
    <Container fluid className="recipe-container">
      {printing && <PrintHeader />}
      <Container fluid className="container-img-recipe">
        <div
          style={{
            height: "350px",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundSize: "cover",
            backgroundImage: recipe?.image?.url
              ? `url(${
                  recipe?.image?.url +
                  "?" +
                  tokenMedia
                })`
              : `url(${imgPlaceholder})`,
          }}
        ></div>
      </Container>
      <RecipeContent
        recipe={recipe}
        componentRef={componentRef}
        setPrinting={setPrinting}
        handlePrint={handlePrint}
        onBeforeGetContentResolve={onBeforeGetContentResolve}
      />
    </Container>
  );
};

export default RecipeContainer;
