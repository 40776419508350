import "../sass/layout/navbarProfile/navbarProfile.css";
import { useTranslation } from "react-i18next";
import {
  ADVISORY_COMMUNICATION,
  ANCIALIARY_POLICY_SUMMARY,
  BEST_PRACTICES_AND_RESOURCES,
  BLUE_CROSSES_SHIELD,
  CONTRACTS,
  COVERAGE_RECS,
  CYBERNET,
  CYBER_LIABILITY,
  DECLARATION_PAGES,
  DRUG_AND_ALCOHOL,
  EKIT,
  ENDORSEMENT_ACTIVITY,
  ENDORSEMENT_ACTIVITY_BILLING,
  ENQUIRON,
  FNP_LINK,
  GRANTS_RM_FLEX,
  INVOICES,
  LOGOUV,
  LOSS_RUNS,
  MEMbER_STATEMENTS,
  MFF_SPONSOR,
  MIIA_WINNERS,
  OSHA_CHEKCLIST,
  OSHA_FORM_LOG,
  OSHA_FORM_SUMMARY,
  PREMIUM_BREAKDOWN,
  PROFESIONAL_LIABILITY_SUMMARY,
  PROPERTY_LIABILITY_DEBT,
  PROPERTY_LIABILITY_SUMMARY,
  RECORDED_TRAINING,
  REGISTER_TRAINING,
  REQUEST_TRAINING,
  REWARDS,
  REWARDS_STATUS_REPORTS,
  SCHEDULES,
  SREVICE_PLAN,
  UNEMPLOYEMENT_ACTION,
  UPDATE_FLEET_SCHEDULES,
  UTMC_LINK,
  WC_CHECK_REGISTER,
  WC_SUMARRY,
  WORKERS_COMPENSANTION,
  WORKERS_COMP_CHECK_REGISTER,
  WORKERS_COMP_CHECK_SUMMARY,
} from "../utils/constants";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PriorityNav, ToggleButton } from "react-priority-navigation";

const NavbarProfile = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="dropdowns-containers-navbar">
      <PriorityNav
        className={"priority-nav"}
        dropdown={({ dropdownItems, buttonProps, isOpen }) => {
          return (
            <>
              <ToggleButton
                {...buttonProps.bind}
                style={{ alignSelf: "flex-end !important" }}
              >
                <p className="button-more-menu">More >></p>
              </ToggleButton>
              {isOpen && (
                <Container className="container-dropdown-collapse">
                  {dropdownItems.map((item) => {
                    return (
                      <Col>
                        <p className="title-menu-collapse">
                          {item?.props.children[0].props.children}
                        </p>
                        {item?.props.children[1].props.children.map(
                          (element) => {
                            return <small>{element}</small>;
                          }
                        )}
                      </Col>
                    );
                  })}
                </Container>
              )}
            </>
          );
        }}
      >
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.propertyLiability")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <Link to="/requestcoi" className="dropdown-item">
              {t("navbar.certificatesOfInsurances")}
            </Link>
            <a className="dropdown-item" target="_blank" href={CONTRACTS}>
              {t("navbar.contracts")}
            </a>
            <a className="dropdown-item" target="_blank" href={DECLARATION_PAGES}>
              {t("navbar.declarationPages")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={ENDORSEMENT_ACTIVITY}
            >
              {t("navbar.endorsementActivitySummary")}
            </a>
            <a className="dropdown-item" target="_blank" href={PREMIUM_BREAKDOWN}>
              {t("navbar.premiumBreakdown")}
            </a>
            <a className="dropdown-item" target="_blank" href={SCHEDULES}>
              {t("navbar.schedules")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={ANCIALIARY_POLICY_SUMMARY}
            >
              {t("navbar.fleetmanagement")}
            </a>
            <a className="dropdown-item" target="_blank" href={COVERAGE_RECS}>
              {t("navbar.coverageRecs")}
            </a>
          </div>
        </div>
        {/* Second */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.health")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href={UTMC_LINK}>
              {t("navbar.enrollmentsForms")}
            </a>
            <a className="dropdown-item" target="_blank" href={EKIT}>
              {t("navbar.ekit")}
            </a>
            <a className="dropdown-item" target="_blank" href={UTMC_LINK}>
              {t("navbar.supportingforms")}
            </a>
            <a className="dropdown-item" target="_blank" href={BLUE_CROSSES_SHIELD}>
              {t("navbar.bluecrossshield")}
            </a>
          </div>
        </div>
        {/* Third */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.reportaClaim")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item"
              target="_blank"
              href={WORKERS_COMPENSANTION}
            >
              {t("navbar.workersCompensation")}
            </a>
            <Link to="/reportclaim/propertyandliability" className="dropdown-item">
              {t("navbar.propertyLiability")}
            </Link>
            <a className="dropdown-item" target="_blank" href={CYBER_LIABILITY}>
              {t("navbar.cyberLiability")}
            </a>
          </div>
        </div>
        {/* Fourth */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.billingStatementsInvoices")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href={MEMbER_STATEMENTS}>
              {t("navbar.memberStatements")}
            </a>
            <a
              target="_blank"
              className="dropdown-item"
              href={ENDORSEMENT_ACTIVITY_BILLING}
            >
              {t("navbar.endorsementActivity")}
            </a>
            <a className="dropdown-item" target="_blank" href={INVOICES}>
              {t("navbar.invoces")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={REWARDS_STATUS_REPORTS}
            >
              {t("navbar.rewardsStatusReports")}
            </a>
          </div>
        </div>
        {/* Fifth */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.training")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href={REGISTER_TRAINING}>
              {t("navbar.registertraining")}
            </a>
            <a className="dropdown-item" target="_blank" href={RECORDED_TRAINING}>
              {t("navbar.recordedtraining")}
            </a>
            <a className="dropdown-item" target="_blank" href={OSHA_FORM_SUMMARY}>
              {t("navbar.trainingCertificate")}
            </a>
            <a className="dropdown-item" target="_blank" href={REQUEST_TRAINING}>
              {t("navbar.requesttraining")}
            </a>
            <a className="dropdown-item" target="_blank" href={OSHA_FORM_SUMMARY}>
              {t("navbar.oshatraining")}
            </a>
          </div>
        </div>
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.unemploymentService")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href={FNP_LINK}>
              {t("navbar.fnp")}
            </a>
            <a className="dropdown-item" target="_blank" href={UTMC_LINK}>
              {t("navbar.utmc")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={UNEMPLOYEMENT_ACTION}
            >
              {t("navbar.uspenrollmentApplication")}
            </a>
          </div>
        </div>
        {/* Sixth */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.riskManagement")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href={GRANTS_RM_FLEX}>
              {t("navbar.grantsrmflex")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={ADVISORY_COMMUNICATION}
            >
              {t("navbar.advisoryCommunication")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={BEST_PRACTICES_AND_RESOURCES}
            >
              {t("navbar.bestPracticesAndResources")}
            </a>
            <a className="dropdown-item" target="_blank" href={REWARDS}>
              {t("navbar.rewards")}
            </a>
            <a className="dropdown-item" target="_blank" href={SREVICE_PLAN}>
              {t("navbar.oshaChecklist")}
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={UPDATE_FLEET_SCHEDULES}
            >
              {t("navbar.oshaFormSumarry")}
            </a>
          </div>
        </div>
        {/* Seven */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.resources")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href={LOGOUV}>
              {t("navbar.logogo")}
            </a>
            <a className="dropdown-item" target="_blank" href={ENQUIRON}>
              {t("navbar.enquiron")}
            </a>
            <a className="dropdown-item" target="_blank" href={CYBERNET}>
              {t("navbar.cybernet")}
            </a>
          </div>
        </div>
        {/* eigth */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.wellness")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" target="_blank" href="#">
              {t("navbar.wellnessGrantprogram")}
            </a>
            <a className="dropdown-item" target="_blank" href={MIIA_WINNERS}>
              {t("navbar.winnersMiialogin")}
            </a>
          </div>
        </div>
        {/* nine */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.claimexperience")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item"
              target="_blank"
              href={PROPERTY_LIABILITY_SUMMARY}
            >
              Property & Liability Summary by Coverage
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={PROPERTY_LIABILITY_DEBT}
            >
              Property & Liability by Department
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={WORKERS_COMP_CHECK_SUMMARY}
            >
              Workers Comp Summary Report
            </a>
            <a
              className="dropdown-item"
              target="_blank"
              href={PROFESIONAL_LIABILITY_SUMMARY}
            >
              Professional Liability Summary
            </a>
          </div>
        </div>
        {/* tenth */}
        <div className="dropdown">
          <button
            className="btn-primary "
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("navbar.sensitiveInformation")}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item"
              target="_blank"
              href={WORKERS_COMP_CHECK_REGISTER}
            >
              {t("navbar.workersComp")}
            </a>
            <a className="dropdown-item" target="_blank" href="#">
              {t("navbar.oshaFormLog")}
            </a>
            <a className="dropdown-item" target="_blank" href="#">
              {t("navbar.historytraining")}
            </a>
          </div>
        </div>
      </PriorityNav>
    </Container>
  );
};

export default NavbarProfile;
