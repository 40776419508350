import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePath } from "../../hooks/usePath";
import "../../sass/layout/homeSchedule/homeSchedule.css";
import { setDetailWellnessClass } from "../../services/reducers/wellnesseventsReducer";
import online from "../../utils/img/classes/online.svg";
import onsite from "../../utils/img/classes/presential.svg";
import zoom from "../../utils/img/classes/zoom.svg";
import { WellnessEventBadge } from "./wellnessEventBadge";
import { formatDate, formatTime, wellness, getMonth} from "../../utils/wellnessclasses";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import { useSelector } from "react-redux";
import UserService from '../../services/api/UserService';

const WellnessItemHome = ({ itemClass, last }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [month, setMonth] = useState();
  const [day, setDay] = useState();
  const path = usePath();
  const location = useSelector((state) => state.homeReducer.location?.data);

  const classMapping = {
    "MIIA Winners": {
      icon: online,
      text: "MIIA Winners",
    },
    Onsite: {
      icon: onsite,
      text: "On Site",
    },
    Zoom: {
      icon: zoom,
      text: "Zoom",
    },
    Online: {
      icon: online,
      text: "Online",
    },
  };

  useEffect(() => {
    if (itemClass) {
      const { month_, day_ } = formatDate(itemClass[wellness.START_DATE]);
      setMonth(month_);
      setDay(day_);
    }
  }, [itemClass]);

  const onClickClass = () => {
    Mixpanel.track('WellnessEvent.View', { isLogged: UserService.isLoggedIn(), wellnessClassName: itemClass[wellness.TITLE_EVENT], locationName: location?.name });
    navigate({
      pathname: `/${path}/wellnessevents/${
        itemClass[wellness.ID] || itemClass[wellness.ID_]
      }`,
    });
    dispatch(setDetailWellnessClass({ wellnessClassSelected: itemClass }));
  };

  return (
    <Container
      fluid
      onClick={onClickClass}
      className={[
        "list-group-item-action list-group-container",
        !last ? "list-group-item-border" : "list-group-item-no-border",
      ]}
    >
      <div className="homeSchedule-date">
        {
          itemClass.Category === "MIIA Winners" ?
          <p className="month-day">{`${getMonth(itemClass[wellness.START_DATE])}`}</p>
          :
          <>
            <p className="month">{month}</p>
            <p className="day">{day}</p>
          </>
        }
      </div>
      <div className="strings-container">
        <div className="mb-1 strings-title">
          <span>{itemClass[wellness.TITLE_EVENT]}</span>
        </div>
        <div className="container-data-classroom">
          {itemClass[wellness.TIME] && (
            <small className="text-muted">
              {formatTime(itemClass[wellness.TIME])}
            </small>
          )}
          <div className="flag-class">
            <img
              src={classMapping[itemClass.Category]?.icon}
              alt="class type"
              style={{
                marginRight: "5px",
                width: "20px",
                height: "20px",
              }}
            ></img>
            {classMapping[itemClass.Category]?.text}
          </div>
          <WellnessEventBadge text={itemClass[wellness.PROGRAM_STATUS]} />
        </div>
      </div>
    </Container>
  );
};

export default WellnessItemHome;
