import { createSlice } from "@reduxjs/toolkit";

const reportReducer = createSlice({
  name: "report",
  initialState: {
    report: null,
    errorReport: null,
  },
  reducers: {
    createReport: (state, action) => {
      state.report = action.payload.report;
      state.errorReport = null;
    },
    setErrorReport: (state, action) => {
      state.errorReport = action.payload.errorReport;
    },
  },
});

export const { createReport, setErrorReport } = reportReducer.actions;

export default reportReducer.reducer;
