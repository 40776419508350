import { Container, Row, Col } from "react-bootstrap";
import "../../sass/layout/newsletter/newsletter.css";
import { useTranslation } from "react-i18next";
import arrowRight from "../../utils/img/right-arrow2.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewsletter } from "../../services/actions/home.action";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import UserService from '../../services/api/UserService';

const NewsLetter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { newsletter } = useSelector((state) => state.homeReducer);
  const { tokenMedia } = useSelector((state) => state.homeReducer);
  const location = useSelector((state) => state.homeReducer.location?.data);

  useEffect(() => {
    getNewsletter(dispatch);
  }, [dispatch]);

  return (
    <Container
      fluid
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        height: "420px",
      }}
    >
      <a
        className="newsletter-title-a"
        href={newsletter?.link}
        target="_blank"
        rel="noreferrer"
      >
        <Row
        onClick={() => {Mixpanel.track("Newsletter Clicked", {isLogged: UserService.isLoggedIn(), newsletter: newsletter?.title, locationName: location?.name})}}
        >
          <Col
            style={{
              padding: "0",
            }}
          >
            <div
              className="img-newsletter"
              style={{
                backgroundImage: `url(${newsletter?.image?.url}?${tokenMedia})`,
              }}
            >
              <h3 className="miia-newsletter">
                MIIA Newsletter
                <img
                  style={{
                    marginTop: "1rem",
                  }}
                  src={arrowRight}
                  alt="arrow-right"
                ></img>
              </h3>
            </div>
            <h4 className="newsletter-title">{newsletter?.title}</h4>
          </Col>
        </Row>
      </a>
    </Container>
  );
};

export default NewsLetter;
