import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../sass/layout/itemContainerGeneral/itemContainerGeneral.css";


const ItemContainerGeneral = ({ content, logo }) => {

    const { t } = useTranslation();

    return (
        <Container fluid >
            <Row className="item">
                <Col xs={1}>
                    {logo}
                </Col>
                <Col xs={1}>
                    {content}
                </Col>
            </Row>
        </Container>
    );
};

export default ItemContainerGeneral;