import "../../sass/layout/welleneseventscontainer/wellnesseventscontainer.css";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { formatLongDate, wellness } from "../../utils/wellnessclasses";
import { useTranslation } from "react-i18next";
import { WellnessEventBadge } from './wellnessEventBadge';

const DetailEvent = () => {
  const wellnessClassSelected = useSelector(
    (state) => state.wellnesseventsReducer.wellnessClassSelected
  );
  const { t } = useTranslation();

  const FieldWithTitle = ({ title, value }) => {
    if (!value) return null;

    return (
      <>
        <p className="title-event">{title}</p>
        <p>{value}</p>
      </>
    );
  };

  return (
    <Row className="detail-container-box">
      <Col>
        <div className="detail-class-title">
          <span className="detail-title">
          {wellnessClassSelected[wellness.TITLE_EVENT]}
          </span>
          <WellnessEventBadge text={wellnessClassSelected[wellness.PROGRAM_STATUS]} />
        </div>
        <FieldWithTitle
          title={t("wellnessEvents.descriprion")}
          value={wellnessClassSelected[wellness.DESCRIPTION_PROGRAM]}
        />
        <p className="title-event">{t("wellnessEvents.extraInstructions")}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: wellnessClassSelected[wellness.EXTRA_INSTRUCTIONS],
          }}
        ></p>
        <FieldWithTitle
          title={t("wellnessEvents.serviceProvider")}
          value={wellnessClassSelected[wellness.SERVICE_PROVIDER]}
        />
        <FieldWithTitle
          title={t("wellnessEvents.classLimit")}
          value={
            wellnessClassSelected[wellness.CLASS_SIZE_LIMIT] ??
            t("wellnessEvents.noClassSizeLimit")
          }
        />
      </Col>
      <Col>
        <p className="title-event">{t("wellnessEvents.freeProgram")}</p>
        <p className="title-event">{t("wellnessEvents.date")}</p>
        <p>{`${formatLongDate(wellnessClassSelected[wellness.START_DATE])}${
          wellnessClassSelected[wellness.CATEGORY] === "MIIA Winners"
            ? ` thru ${formatLongDate(
                wellnessClassSelected[wellness.END_DATE]
              )}`
            : ""
        }`}</p>
        <FieldWithTitle
          title={t("wellnessEvents.time")}
          value={
            wellnessClassSelected[wellness.TIME]
          }
        />
        <FieldWithTitle
          title={t("wellnessEvents.location")}
          value={
            wellnessClassSelected[wellness.LOCATION]
          }
        />
        <FieldWithTitle
          title={t("wellnessEvents.locationInstructions")}
          value={
            wellnessClassSelected[wellness.LOCATION_INSTRUCTIONS]
          }
        />
        <p className="title-event">{t("wellnessEvents.registrationInfo")}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: wellnessClassSelected[wellness.REGISTRATION_INFO],
          }}
        ></p>
        <FieldWithTitle
          title={t("wellnessEvents.whocanattend")}
          value={
            wellnessClassSelected[wellness.WHO_CAN_ATTEND]
          }
        />
      </Col>
    </Row>
  );
};

export default DetailEvent;
