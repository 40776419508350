import { Container, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../sass/layout/welleneseventscontainer/wellnesseventscontainer.css";
import WellnessItemHome from "./wellnessitemhome";
import { useEffect, useState } from "react";
import { getOnlineClasses } from '../../services/actions/wellnessclasses.action';

const WellnessEventsContainer = () => {
  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState(7);
  const dispatch = useDispatch();
  const {
    id,
    onlineClasses,
  } = useSelector((state) => ({
    id: state.homeReducer.location?.data?.uniqueNonGuessableId,
    onlineClasses: state.wellnesseventsReducer.onlineClasses,
  }));

  useEffect(() => {
    if (id) getOnlineClasses(dispatch, id);
  }, [dispatch, id])

  return (
    <Container
      fluid
      className="wellness-events-container homeSchedule-container"
    >
      <>
        <Col>
          {onlineClasses
            .slice(0, numberOfVisibleItems)
            .map((element, index) => {
              return (
                <WellnessItemHome
                  itemClass={element}
                  last={index === onlineClasses.length - 1}
                />
              );
            })}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {onlineClasses.length > numberOfVisibleItems && (
              <Button
                style={{
                  marginTop: "1em",
                  backgroundColor: "rgb(143, 0, 60)",
                  border: "none",
                }}
                onClick={() =>
                  setNumberOfVisibleItems(onlineClasses.length)
                }
              >
                See more
              </Button>
            )}
          </div>
        </Col>
      </>
    </Container>
  );
};

export default WellnessEventsContainer;
