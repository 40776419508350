/* eslint-disable react/jsx-no-target-blank */
import { Accordion } from "react-bootstrap";
import { Container } from "react-bootstrap";
import "../../sass/layout/accordion/accordion.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePath } from "../../hooks/usePath";
import {
  ExternalLink,
  FTPLink,
  InternalLink,
  TableLink,
  Title,
} from "./link-types";
import { getTokenMedia } from '../../services/actions/home.action';

const AccordionProfile = () => {
  const { dropdowns, tokenMedia } = useSelector((state) => state.homeReducer);
  const location = useSelector((state) => state.homeReducer.location?.data);
  const navigate = useNavigate();
  const locationPath = usePath();
  const [link, setLink] = useState("");
  const dispatch = useDispatch();
  const style = {
    padding: "0px !important",
  };
  const [itemLoading, setItemLoading] = useState({});

  useEffect(() => {
    if (link && tokenMedia) {
      window.open(`${link}?${tokenMedia}`, "_blank");
      setLink(null);
    }
  }, [link, tokenMedia]);

  const refreshSASToken = async (type, link) => {
    if (type === "file") {
      getTokenMedia(dispatch);
      setLink(link);
    } else {
      setLink(null);
    }
  };

  const renderItem = (item, index, options) => {
    const {
      id,
      name,
      default_type,
      default_link,
      default_file,
      default_category,
    } = item;
    const { hasChildrens, isLast = false, parentIndex, firstLevel } = options;

    if (hasChildrens && default_type !== "table") {
      return <Title name={name} index={index} />;
    }

    if (default_type === "internal_link") {
      return (
        <InternalLink
          index={index}
          isLast={isLast}
          name={name}
          firstLevel={firstLevel}
          location={location}
          default_link={default_link}
          locationPath={locationPath}
        />
      );
    } else if (["external_link", "email", "file"].includes(default_type)) {
      return (
        <ExternalLink
          index={index}
          isLast={isLast}
          name={name}
          firstLevel={firstLevel}
          location={location}
          default_link={default_link}
          default_type={default_type}
          default_file={default_file}
          setLink={setLink}
          refreshSASToken={refreshSASToken}
        />
      );
    } else if (default_type === "ftp") {
      return (
        <FTPLink
          id={id}
          parentIndex={parentIndex}
          report_period={item.report_period}
          index={index}
          isLast={isLast}
          name={name}
          firstLevel={firstLevel}
          default_category={default_category}
          setItemLoading={setItemLoading}
          navigate={navigate}
          locationPath={locationPath}
          itemLoading={itemLoading}
          location={location}
        />
      );
    } else if (default_type === "table") {
      return (
        <TableLink
          id={id}
          index={index}
          isLast={isLast}
          name={name}
          location={location}
          firstLevel={firstLevel}
          locationPath={locationPath}
        />
      );
    } else {
      return <Title name={name} index={index} />;
    }
  };

  const renderItemWithChildren = (item, parentIndex) => {
    const { dropdown_options } = item;
    const listToRender = [item, ...dropdown_options];

    return listToRender.map((item, index) =>
      renderItem(item, index, {
        parentIndex,
        hasChildrens: item?.dropdown_options?.length > 0,
        isLast: index === listToRender.length - 1,
      })
    );
  };

  return (
    <Container fluid className="container-accordion">
      <Accordion style={style}>
        {dropdowns?.data?.map(
          (
            {
              id,
              attributes: {
                name,
                dropdown_options,
                default_type,
                default_category,
                default_file,
                default_link,
              },
            },
            partentIndex
          ) => (
            <div key={partentIndex}>
              {dropdown_options?.data?.length > 0 ? (
                <Accordion.Item eventKey={partentIndex} key={partentIndex}>
                  <Accordion.Header>{name}</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {dropdown_options?.data?.map((option, index) =>
                        option.attributes.dropdown_options?.length
                          ? renderItemWithChildren(
                              option.attributes,
                              partentIndex
                            )
                          : renderItem(option.attributes, index, {
                              hasChildrens: false,
                              parentIndex: partentIndex,
                              isLast: true,
                            })
                      )}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                renderItem(
                  {
                    id,
                    name,
                    default_type,
                    default_category,
                    default_file,
                    default_link,
                  },
                  partentIndex,
                  { firstLevel: true }
                )
              )}
            </div>
          )
        )}
      </Accordion>
    </Container>
  );
};

export default AccordionProfile;
