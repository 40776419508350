import React, { useEffect, useState } from "react";
import "./../../sass/layout/portalAdministrationList/portalAdministrationList.css";
import { Container, Row, Col } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import {
  userEditPlaceholder,
  deleteUser,
  fetchInitialUsers,
} from "./../../services/actions/users.action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import usePagination from "../../hooks/usePagination";
import Paginator from "../paginator/paginator";

const PortalAdministrationList = ({ prop }) => {
  const users = useSelector((state) => state.usersReducer.users.data);
  const meta = useSelector((state) => state.usersReducer.users.meta);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    localStorage.setItem("id", id);
    setShow(true);
  };

  const handleClick = (id) => {
    userEditPlaceholder(dispatch, id);
  };

  const handleDelete = (e) => {
    //CHECK
    const id = parseInt(localStorage.getItem("id"));
    users.filter((user) => user.id !== id);

    deleteUser(dispatch, id);
    setShow(false);
  };

  const { items, paginationParams, onClickPage } = usePagination(
    fetchInitialUsers,
    meta
  );

  return (
    <Container fluid className="portalAdministrationListContainer">
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item className="list-group-item-portal-administration">
              <Row>
                <Col className="name-portalAdmin">
                  <p>{t("portalAdministrationList.name")}</p>
                </Col>
                <Col className="name-portalAdmin">
                  <p>{t("portalAdministrationList.role")}</p>
                </Col>
                <Col className="name-portalAdmin">
                  <p>{t("portalAdministrationList.title")}</p>
                </Col>
                <Col className="name-portalAdmin">
                  <p>{t("portalAdministrationList.email")}</p>
                </Col>
                <Col md={2}>
                  <p></p>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Col>
            <ListGroup>
              {users &&
                users.map((item, index) => (
                  <ListGroup.Item
                    key={item.id}
                    className="item-list-portal-administration"
                  >
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      className="mobile-row"
                    >
                      <Col>
                        <Col
                          style={{
                            paddingLeft: "0px",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          sm={2}
                        >
                          {item.attributes.first_name}{" "}
                          {item.attributes.last_name}
                        </Col>
                        <Col
                          sm={10}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <>
                            <Button
                              variant=""
                              style={{
                                color: "#8F003C",
                              }}
                              onClick={() => handleShow(item.id)}
                            >
                              {t("portalAdministrationList.delete")}
                            </Button>

                            <Modal
                              show={show}
                              onHide={handleClose}
                              backdrop="static"
                              keyboard={false}
                            >
                              <Modal.Body>
                                Are you sure you want to delete this user? This
                                action can`t be undone.
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => handleDelete(item)}
                                >
                                  Yes, delete it
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </>
                          <Link
                            to={`admin/users/${item.id}`}
                            onClick={() => handleClick(item)}
                            className="path-item"
                          >
                            {t("portalAdministrationList.edit")}
                          </Link>
                        </Col>
                      </Col>
                    </Row>
                    <Row className="row-column-email-title">
                      <Col className="name-portalAdmin">
                        {item.attributes.first_name} {item.attributes.last_name}
                      </Col>
                      {
                        <Col>
                          {
                            item.attributes.portal_user_role?.data?.attributes
                              ?.name
                          }
                        </Col>
                      }
                      <Col>{item.attributes.title}</Col>
                      {<Col>{item.attributes.email}</Col>}
                      <Col
                        md={2}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                        className="name-portalAdmin"
                      >
                        <>
                          <Button
                            variant=""
                            style={{
                              color: "#8F003C",
                            }}
                            onClick={() => handleShow(item.id)}
                          >
                            {t("portalAdministrationList.delete")}
                          </Button>

                          <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Body>
                              Are you sure you want to delete this user? This
                              action can`t be undone.
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={handleClose}>
                                Cancel
                              </Button>
                              <Button
                                variant="primary"
                                onClick={() => handleDelete(item)}
                              >
                                Yes, delete it
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                        <Link
                          to={`admin/users/${item.id}`}
                          onClick={() => handleClick(item)}
                          className="path-item"
                        >
                          {t("portalAdministrationList.edit")}
                        </Link>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
            </ListGroup>
            <Paginator
              items={items}
              onClickPage={onClickPage}
              filterParams={paginationParams}
              metadata={meta}
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
export default PortalAdministrationList;
