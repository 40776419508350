import {
  setLoginStatus,
  setUserDetail,
  setUserError,
  cleanUserEdit,
} from "../reducers/userReducer";
import axios, { authHeader } from "../../services/api/HttpService";

const API_URL = process.env.REACT_APP_API_URL;

export const loginRequest = async (dispatch) => {
  try {
    dispatch(setLoginStatus({ loginStatus: true }));
  } catch (error) {
    dispatch(setUserError({ errorUser: false }));
  }
};

export const userVisitedThePortal = (uniqueCode) => {
  // If there is no uniqueCode, the user is not logged in return
  if (!uniqueCode) return;
  return axios.put(API_URL + `api/miia-users/userVisitedThePortalAtLeastOnce/${uniqueCode}`);
}; 

export const changeStatusLoginUser = () => {};

export const findOnePortalUser = async (dispatch, id) => {
  return axios
    .get(API_URL + `api/miia-users/findOnePortalUser/${id}?type=email`, {
      headers: authHeader(),
    })
    .then(({ data: { data } }) => {
      const userShouldNotBeTracked = data?.userShouldNotBeTracked;
      const email = data?.email;
      const emailIsFromSkylytics = email?.includes('@skylytics.com');
      const emailDomainIsMIIA = email?.includes('@mma.org');
      const finalShouldNotBeTracked = userShouldNotBeTracked || emailIsFromSkylytics || emailDomainIsMIIA;
      localStorage.setItem('userShouldNotBeTracked', finalShouldNotBeTracked);
      dispatch(setUserDetail({ userDetail: data }));
    })
    .catch((error) => {
      dispatch(setUserError({ errorUser: false }));
    });
};

export const resetDetailUser = async (dispatch) => {
  dispatch(cleanUserEdit({ data: null }));
};
