import "../../sass/layout/portalAdmininistration/portalAdministration.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchInitialUsers,
  resetStatus,
} from "./../../services/actions/users.action";
import MenuPath from "../path/MenuPath";
import PortalAdministrationList from "./portalAdministrationList";
import { findOnePortalUser } from "../../services/actions/user.action";
import UnauthorizedAlert from "../unauthorizedAlert/unauthorizedAlert";
import { usePath } from "../../hooks/usePath";
import { hasUserAdministrationPermission } from "../../helpers/roles";

const UserAdministration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.usersReducer.users.data);
  const { infoUser, userDetail } = useSelector((state) => state.userReducer);
  const { t } = useTranslation();
  const path = usePath();

  useEffect(() => {
    if (infoUser?.email) {
      findOnePortalUser(dispatch, infoUser?.email);
    }
  }, [infoUser]);

  useEffect(() => {
    if (!hasUserAdministrationPermission(userDetail)) {
      navigate(`/${path}`);
    }
  }, [userDetail, path]);

  return (
    <div fluid className="portalAdministrationContainer">
      <Row className="portalAdministrationRow">
        <Col xl={12}>
          <Row className="box-menu">
            <MenuPath
              className="menuPathPortalAdmin"
              name={t("portalAdministration.title")}
            />
          </Row>
          {hasUserAdministrationPermission(userDetail) ? (
            <>
              <Row>
                <Col className="box-portal-admin">
                  <h4 className="portalAdmin-title">
                    {t("portalAdministration.title")}
                  </h4>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: "50px",
                  }}
                >
                  <button
                    onClick={() => navigate("createmember")}
                    className="pCreateUser"
                  >
                    {t("portalAdministration.createUser")}
                  </button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <PortalAdministrationList prop={users} />
                </Col>
              </Row>
            </>
          ) : (
            <UnauthorizedAlert />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserAdministration;
