import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import UserService from "./services/api/UserService";
import StoreService from "./services/api/StoreService";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = StoreService.setup()

root.render(
   <App store={store}/>
);

UserService.initKeycloak(() => root, store);

