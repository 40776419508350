
import "../sass/layout/footer/footer.css";
import upArrow from "../utils/img/up-arrow.png";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { usePath } from "../hooks/usePath";
import { useSelector } from "react-redux";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = usePath();
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  return (
    <Container fluid className="footer-container">
      <Row className="firstbox-first">
        <Col md={10} lg={10} sm={10} xs={6} className="color-box">
          <Row className="color-box">
            <Col md={3} lg={2} sm={3} xs={12}>
              <a className="navbar-brand" href="#">
                <h1 className="navbar-title-header">MIIA</h1>
              </a>
            </Col>
            <Col className="col-first-box color-box">
              <a href="https://www.emiia.org/about/history-and-mission">
                {t("footer.about")}
              </a>
              <a href="https://www.emiia.org/contact">{t("footer.contact")}</a>
              <div
                onClick={() => {
                  id !== "/"
                    ? navigate(`/${path}/articleslist`)
                    : navigate("/articleslist");
                }}
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("footer.articles")}
              </div>
              <div
                onClick={() => {
                  id !== "/"
                    ? navigate(`/${path}/recipeslist`)
                    : navigate("/recipeslist");
                }}
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {t("footer.recipes")}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="button-arrow-col color-box">
          <button
            className="btn button-arrow"
            onClick={() => {
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <img className="button-arrow" src={upArrow}></img>
          </button>
        </Col>
      </Row>
      <Row md={12} className="footer-bottomBox-container">
        <Col md={12} sm={12} xs={12} className="col-footer-bottom-start">
          <a>{t("footer.legal")}</a>
          {/* <a>{t("footer.userAgreement")}</a> */}
          <Link className="privacy-policies" to="/privacy-policies">{t("footer.privacy")}</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
