import React from "react";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrivateNotifications,
  getPublicNotifications,
} from "../../services/actions/home.action";
import NavbarComponent from "../navbar";
import RedNavbar from "../redNavbar";
import "../../sass/layout/notificationscenter/notificationscenter.css";
import NavbarProfile from "../navbar-profile";
import Notification from "../notifications/notification";
import MenuPath from "../path/MenuPath";

const NotificationsCenter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const notifications = useSelector((state) => state.homeReducer.notifications);
  const privateNotifications = useSelector(
    (state) => state.homeReducer.privatenotifications
  );
  const publicNotifications = useSelector(
    (state) => state.homeReducer.publicnotifications
  );

  useEffect(() => {
    getPublicNotifications(dispatch);
    getPrivateNotifications(dispatch);
  }, []);

  return (
    <Container fluid className="notifications-center-page">
      <Container fluid className="headers-home">
        <NavbarComponent />
        <RedNavbar />
      </Container>
      <Col className="logged-navbar">{loginStatus && <NavbarProfile />}</Col>
      <Row className="row-box-container">
        <Row className="box-data-container">
          <MenuPath name={"Notification"} />
        </Row>
        <Row className="box-data-container">
          <h4>{t("notifications.title")}</h4>
        </Row>
        <Col className="box-data-container">
          {publicNotifications?.map((item, index) => {
            return (
              <Notification key={index} item={item} notificationpage={true} />
            );
          })}
        </Col>
      </Row>
    </Container>
  );
};
export default NotificationsCenter;
