import { useTranslation } from "react-i18next";
import "../../sass/layout/claimform/claimform.css";
import { createReportForm } from "../../services/actions/report.action";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { useEffect } from "react";
import { FiFile } from "react-icons/fi";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePath } from "../../hooks/usePath";

const FormPropertyLiability = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = usePath();
  const [showAlert, setShowAlert] = useState(false);
  const [statusCode, setStatusCode] = useState();

  const [show, setShow] = useState(false);

  const status = useSelector((state) => state.reportReducer.report?.status);

  const handleDelete = (e) => {
    window.location.href = "/";
    setShow(false);
  };

  useEffect(() => {
    status && setStatusCode(status);

    if (statusCode === 200) {
      setShowAlert(true);
    }
  }, [status, statusCode]);

  const handleCloseAlert = () => setShowAlert(false);

  const handleClose = () => setShow(false);

  const ClaimFormSchema = Yup.object().shape({
    district: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    contactperson: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    email: Yup.string().email(t("errors.emailformat"))
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    phonenumber: Yup.number()
      .required(t("errors.fieldrequired"))
      .typeError(t("errors.mustbenumber")),
    dateincident: Yup.date().required(t("errors.fieldrequired")),
    locationincident: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    description: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    fullnamepresenter: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    claimantphonenumber: Yup.number()
      .required(t("errors.fieldrequired"))
      .typeError(t("errors.mustbenumber")),
    claimantaddress: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    claimantaddress2: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong")),
    claimantcity: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    typeofvehicle: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong")),
    communityinvolved: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    generalcomments: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong")),
    attachedFiles: Yup.array().required(t("errors.fieldrequired")).max(10, t("errors.max10")),
  });

  const onSubmitForm = (values) => {
    const formData = new FormData();

    const reportClaim = {
      memberCommunityOrDistrict: values.district,
      memberContactPerson: values.contactperson,
      memberContactEmail: values.email,
      contactPhoneNumber: values.phonenumber,
      dateOfIncident: values.dateincident,
      locationOfIncident: values.locationincident,
      descriptionOfIncident: values.description,
      fullNameOfClaimPresenter: values.fullnamepresenter,
      claimantPhoneNumber: values.claimantphonenumber,
      claimantAddress: values.claimantaddress,
      claimantAddressLine2: values.claimantaddress2,
      claimantCity: values.claimantcity,
      typeOfVehicleInvolved: values.typeofvehicle,
      communityOrDistrictDepartmentInvolved: values.communityinvolved,
      generalComments: values.generalcomments,
    };

    formData.append("data", JSON.stringify(reportClaim));
    values.attachedFiles.map((file) => formData.append("files.attachedFiles", file));

    createReportForm(dispatch, formData);
  };

  const initialValues = {
    district: "",
    contactperson: "",
    email: "",
    phonenumber: "",
    dateincident: "",
    locationincident: "",
    description: "",
    fullnamepresenter: "",
    claimantphonenumber: "",
    claimantaddress: "",
    claimantaddress2: "",
    claimantcity: "",
    typeofvehicle: "",
    communityinvolved: "",
    generalcomments: "",
    attachedFiles: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ClaimFormSchema}
      onSubmit={onSubmitForm}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Container fluid>
            <Row>
              <Col md={9}>
                <h5>{t("claimform.titleForm")}</h5>
                <Form onSubmit={handleSubmit} className="form-container-claim">
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.memberCommunityDistrict")}
                      </Form.Label>
                      <Form.Control
                        name="district"
                        value={values.district}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.district && touched.district}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.district}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.memberContactPerson")}
                      </Form.Label>
                      <Form.Control
                        name="contactperson"
                        value={values.contactperson}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.contactperson && touched.contactperson
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contactperson}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.memberContactEmail")}
                      </Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.contactPhoneNumber")}
                      </Form.Label>
                      <Form.Control
                        name="phonenumber"
                        value={values.phonenumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.phonenumber && touched.phonenumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.phonenumber}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>{t("claimform.dateOfIncident")}</Form.Label>
                      <Form.Control
                        type="date"
                        name="dateincident"
                        value={values.dateincident}
                        onChange={handleChange}
                        isInvalid={errors.dateincident && touched.dateincident}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dateincident}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.locationOfIncident")}
                      </Form.Label>
                      <Form.Control
                        name="locationincident"
                        value={values.locationincident}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.locationincident && touched.locationincident
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.locationincident}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12}>
                      <Form.Label>
                        {t("claimform.descriptionOfIncident")}
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.description && touched.description}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.fullnameclaimpresenter")}
                      </Form.Label>
                      <Form.Control
                        name="fullnamepresenter"
                        value={values.fullnamepresenter}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.fullnamepresenter && touched.fullnamepresenter
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.fullnamepresenter}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.claimantphonenumber")}
                      </Form.Label>
                      <Form.Control
                        name="claimantphonenumber"
                        value={values.claimantphonenumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.claimantphonenumber &&
                          touched.claimantphonenumber
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.claimantphonenumber}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>{t("claimform.claimantAdress")}</Form.Label>
                      <Form.Control
                        name="claimantaddress"
                        value={values.claimantaddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.claimantaddress && touched.claimantaddress
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.claimantaddress}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>
                        {t("claimform.claimantAdressLine2")}
                      </Form.Label>
                      <Form.Control
                        name="claimantaddress2"
                        value={values.claimantaddress2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.claimantaddress2 && touched.claimantaddress2
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.claimantaddress2}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>{t("claimform.claimantCity")}</Form.Label>
                      <Form.Control
                        name="claimantcity"
                        value={values.claimantcity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.claimantcity && touched.claimantcity}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.claimantcity}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>{t("claimform.typeVehicle")}</Form.Label>
                      <Form.Control
                        name="typeofvehicle"
                        value={values.typeofvehicle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.typeofvehicle && touched.typeofvehicle
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.typeofvehicle}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6} className="community-district-input">
                      <Form.Label>
                        {t("claimform.districtDepartment")}
                      </Form.Label>
                      <Form.Control
                        name="communityinvolved"
                        value={values.communityinvolved}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.communityinvolved && touched.communityinvolved
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.communityinvolved}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>{t("claimform.attachFiles")}</Form.Label>
                      <Row className="row-attachFiles">
                        <label
                          class={
                            (values.attachedFiles?.length == 0 || !values.attachedFiles) &&
                            errors.attachedFiles &&
                            touched.attachedFiles
                              ? "custom-file-upload-is-invalid"
                              : "custom-file-upload"
                          }
                        >
                          <input
                            id="file-upload"
                            class={"form-control"}
                            multiple
                            type="file"
                            onBlur={handleBlur}
                            isInvalid={
                              errors.attachedFiles && touched.attachedFiles
                            }
                            onChange={(e) => {
                              const selectedFiles = new Array(...e.target.files);
                              if(selectedFiles?.length > 0){
                                setFieldValue("attachedFiles", [...values.attachedFiles, ...selectedFiles]);
                              }
                            }}
                          ></input>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              values.attachedFiles?.length > 0 ? (
                                <Tooltip className="tooltip-files">
                                  {values.attachedFiles?.map((element, index) => {
                                    if (values.attachedFiles?.length - 1 != index) {
                                      return <small>{element?.name}, </small>;
                                    } else {
                                      return <small>{element?.name}</small>;
                                    }
                                  })}
                                  <></>
                                </Tooltip>
                              ) : (
                                <Tooltip className="tooltip-empty"></Tooltip>
                              )
                            }
                          >
                            <p className="limit-files">
                              <FiFile className="icon-file" />
                              {values.attachedFiles?.length > 1
                                ? values.attachedFiles?.length + " files"
                                : values.attachedFiles?.length == 1
                                ? values.attachedFiles?.length + " file"
                                : "Select up to 10 files"}
                            </p>
                          </OverlayTrigger>
                        </label>
                      </Row>
                      {errors?.attachedFiles && <p className="invalid-feedback-file">
                        {errors?.attachedFiles}
                      </p>}
                      {values?.attachedFiles?.length > 0 && (
                        <span
                          onClick={() => setFieldValue("attachedFiles", [])}
                          className="file-clear"
                        >
                          Clear files
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12}>
                      <Form.Label>{t("claimform.generalcomments")}</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="generalcomments"
                        value={values.generalcomments}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.generalcomments && touched.generalcomments
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.generalcomments}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="group-bottom-claim">
                    <>
                      <button
                        onClick={() => {
                          setShow(true);
                        }}
                        className="btn style-btn-1"
                      >
                        {t("claimform.cancel")}
                      </button>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Body>
                          Are you sure you want to delete this form? This action
                          can`t be undone.
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary btn-cancel-it"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary btn-delete-it"
                            onClick={() => handleDelete()}
                          >
                            Yes, delete it
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                    <button
                      type="submit"
                      className="btn style-btn-2"
                      onClick={() => onSubmitForm(values)}
                    >
                      {t("claimform.submitClaim")}
                    </button>
                    <Modal
                      show={showAlert}
                      onHide={handleCloseAlert}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>
                        The form was submitted successfully
                      </Modal.Body>
                      <Modal.Footer>
                        <div
                          style={{
                            textDecoration: "none !important",
                            color: "#FFFFFF",
                            backgroundColor: "#8F033C",
                            width: "100px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                          className="link-userEdit"
                          to="/"
                          onClick={(e) => {
                            setShowAlert(false);
                            navigate(`/${path}`);
                          }}
                        >
                          Ok
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </Row>
                </Form>
              </Col>
              <Col className="box-data-claim">
                <div className="text-claim-box">
                  <p className="title-claim">
                    <b>{t("claimform.titleBoxData")}</b>
                  </p>
                  <p className="body-claim">{t("claimform.bodyClaims1")}</p>
                  <p className="title-claim">
                    <b>{t("claimform.itemBoxData1")}</b>
                  </p>
                  <p className="body-claim">{t("claimform.bodyClaims2")}</p>
                  <p className="title-claim">
                    <b>{t("claimform.itemBoxData2")}</b>
                  </p>
                  <p className="title-claim">
                    <b>{t("claimform.itemBoxData3")}</b>
                  </p>
                  <p className="body-claim">
                    <li>{t("claimform.report1")}</li>
                    <li>{t("claimform.report2")}</li>
                    <li>{t("claimform.report3")}</li>
                    <li>{t("claimform.report4")}</li>
                  </p>
                  <p className="title-claim">
                    <b>{t("claimform.itemBoxData4")}</b>
                  </p>
                  <p className="body-claim">
                    <li>{t("claimform.report5")}</li>
                    <li>{t("claimform.report6")}</li>
                    <li>{t("claimform.report7")}</li>
                    <li>{t("claimform.report8")}</li>
                    <li>{t("claimform.report9")}</li>
                    <li>{t("claimform.report10")}</li>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
};

export default FormPropertyLiability;
