import "../../sass/layout/homePage/homepage.css";
import "../../sass/layout/notificationscenter/notificationscenter.css";
import Alert from "react-bootstrap/Alert";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from '../../services/api/UserService';
import { getPrivateNotifications, getPublicNotifications } from '../../services/actions/home.action';

const Notification = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [position, setPosition] = useState(0);
  const dispatch = useDispatch();
  // const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const loginStatus = UserService.isLoggedIn();

  const privateNotifications = useSelector(
    (state) => state.homeReducer.privatenotifications
  );
  const publicNotifications = useSelector(
    (state) => state.homeReducer.publicnotifications
  );
  const noLocationNotifications = useSelector(
    (state) => state.homeReducer.nolocation?.data
  );

  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  useEffect(() => {
    if (loginStatus) {
      getPrivateNotifications(dispatch);
    }

    getPublicNotifications(dispatch, id);
  }, [dispatch, loginStatus, id])

  const changeClassname = (type) => {
    switch (type) {
      case "danger": {
        return "notifications-container red";
      }
      case "warning": {
        return "notifications-container yellow";
      }
      case "info": {
        return "notifications-container blue";
      }
    }
  };

  useEffect(() => {
    if ((id == "" || id == "/") && !loginStatus) {
      setNotifications(noLocationNotifications);
    } else if (!loginStatus) {
      setNotifications(publicNotifications);
    }
    if (loginStatus) {
      setNotifications(privateNotifications);
    }
  }, [publicNotifications, privateNotifications, noLocationNotifications]);

  return (
    <Container fluid className="notifications-container">
      <Row>
        {notifications?.length > 0 && (
          <Row className="header-notif">
            <Col className="buttons-page-title">
              <h3 className="notifications-title">
                {t("riskmanagement.notifications")}
              </h3>
            </Col>
            <Col className="buttons-page-notif">
              <button
                className="btn-notif"
                disabled={position == 0 ? true : false}
                onClick={() => {
                  {
                    position <= 0 ? setPosition(0) : setPosition(position - 1);
                  }
                }}
              >
                {"<"}
              </button>
              <label className="btn-notif-label">
                {`${position + 1} / ${notifications?.length}`}
              </label>
              <button
                className="btn-notif"
                onClick={() => {
                  position >= notifications?.length - 1
                    ? setPosition(notifications?.length - 1)
                    : setPosition(position + 1);
                }}
              >
                {">"}
              </button>
            </Col>
          </Row>
        )}
        <Col md={12}>
          {notifications?.length > 0 && (
            <Alert
              style={{
                marginLeft: "0.1rem",
              }}
              className={changeClassname(notifications[position]?.type)}
            >
              {notifications[position]?.url ? (
                <Alert.Link
                  href={notifications[position]?.url}
                  target="_blank"
                  className="text-decoration-underline"
                >
                  {notifications[position]?.body}
                </Alert.Link>
              ) : (
                notifications[position]?.body
              )}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Notification;
