import { Col, Container, Row } from "react-bootstrap";
import "../../sass/layout/optionListContainer/optionListContainer.css";
import { getTokenMedia } from "../../services/actions/home.action";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { downloadFile } from "../../services/download/downloadFile";
import { FiExternalLink } from "react-icons/fi";
import { usePath } from "../../hooks/usePath";

const OptionPreviewList = ({ border, option }) => {
  const dispatch = useDispatch();
  const [itemLoading, setItemLoading] = useState({});
  const [feedback, setFeedback] = useState({});
  const { tokenMedia } = useSelector((state) => state.homeReducer);
  const locationPath = usePath();
  const navigate = useNavigate();

  const refreshSASToken = (type, link) => {
    if (type === "file") {
      getTokenMedia(dispatch);
      window.open(link, "_blank");
    }
  };

  const renderItem = (item, index, options) => {
    const { name, default_type, default_link, default_file, default_category } =
      item;
    const { hasChildrens, isLast = false, parentIndex } = options;

    if (default_type === "internal_link") {
      return (
        <li
          key={index}
          className={(!hasChildrens || isLast) && "accordion-divider"}
        >
          <Link
            className="dropdown-item"
            to={
              default_link
                ? `/${locationPath}/${default_link}`
                : `/${locationPath}`
            }
            key={index}
          >
            <FiExternalLink className="option-icon" />
            {name}
          </Link>
        </li>
      );
    } else if (["external_link", "email", "file"].includes(default_type)) {
      const buildLink = () => {
        if (default_type === "external_link") {
          return default_link;
        } else if (default_type === "email") {
          return `mailto:${default_link}`;
        } else if (default_type === "file") {
          return "#";
        }
      };

      return (
        <li
          key={index}
          className={(!hasChildrens || isLast) && "accordion-divider"}
        >
          <a
            href={buildLink()}
            onMouseDown={() =>
              refreshSASToken(
                default_type,
                `${default_file?.url}?${tokenMedia}`
              )
            }
            className={"dropdown-item"}
            target={buildLink() !== "#" ? "_blank" : undefined}
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </li>
      );
    } else if (default_type === "ftp") {
      const identifier = `${parentIndex}-${index}`;

      return (
        <li
          key={index}
          className={(!hasChildrens || isLast) && "accordion-divider"}
        >
          <a
            href="#"
            className="dropdown-item"
            key={index}
            rel="noopener noreferrer"
            onClick={() =>
              downloadFile(
                default_category,
                identifier,
                setItemLoading,
                setFeedback,
                navigate,
                locationPath
              )
            }
          >
            <FiExternalLink className="option-icon" />
            {name}
            {itemLoading[identifier] && <Spinner animation="grow" size="sm" />}
          </a>
        </li>
      );
    }
  };

  return (
    <Container fluid className="no-border-container">
      <Row className={border ? "option-item-border" : "option-item"}>
        <Col className="text">
          {renderItem(option, 0, { hasChildrens: false })}
        </Col>
      </Row>
    </Container>
  );
};

export default OptionPreviewList;
