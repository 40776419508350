/* eslint-disable import/no-anonymous-default-export */
export default {
  login: {
    email: "Email",
    password: "Password",
    forgotpassword: "Forgot your password?",
    login: "Log in",
  },
  profile: {
    healthcoverage: "Health plan benefits | Coverage overview",
    retireesonly: "Retirees only",
    wellnessclass: "Wellness class schedule for ",
    registerClasses: "Register for online Wellness classes",
    healthVisionDental: "Health, vision and dental services forms",
    contactenrollment: "Contact your enrollment representative",
    classesscheduled: "Classes you scheduled",
    featuredrecipes: "Featured Recipes",
    featuredarticles: "Featured Articles",
    riskmanagement: "Risk Management Training",
    employeeAssistanceProgram: "Employee Assistance Program (EAP)",
  },
  recipe: {
    printThisRecipe: "Print this recipe",
    seeRecipe: "See this Recipe",
    recipes: "Recipes",
    instructions: "Instructions",
    ingredients: "Ingredients",
    printThisRecipe: "Print this recipe",
  },
  divlinks: {
    myblue: "MyBlue instant access",
    healthPortal: "Find your health portal",
    phone: "Contact Blue Cross Member Services: 1-800-262-2583",
  },
  article: {
    more: "More",
    articles: "Articles",
    seeThisArticle: "See this Article",
  },
  retirees: {
    retirees: "Retirees",
    only: "only",
  },
  healthportal: {
    findportal: "Find your health portal",
  },
  path: {
    home: "Home",
    retirees: "Retirees",
    findyourportal: "Find your health portal",
    programs: "Programs",
    healthandwellness: "Health & Wellness ",
  },
  wellnessEvents: {
    wellnesstitle1: "Wellness",
    wellnesstitle2: "upcoming events",
    wellnessPath: "Wellness Classes",
    register: "Register",
    descriprion: "DESCRIPTION",
    whocanattend: "WHO CAN ATTEND?",
    freeProgram: "FREE PROGRAM",
    registration: "REGISTRATION",
    noInformation: "No data available",
    noClassSizeLimit: "No limit",
    extraInstructions: "EXTRA INSTRUCTIONS",
    serviceProvider: "SERVICE PROVIDER",
    classLimit: "CLASS SIZE LIMIT",
    date: "DATE",
    time: "TIME",
    registrationInfo: "REGISTRATION INFO",
    location: "LOCATION",
    locationInstructions: "LOCATION INSTRUCTIONS",
    registerOnsite:
      "Please register with Mary Harrington at mharrington@mma.org. MIIA/Blue Cross members have priority in registering when space is an issue. Please let Mary know if you are a MIIA/Blue Cross member. Only MIIA/Blue Cross members are eligible for prizes or awards.",
  },
  utils: {
    by: "By",
  },
  contact: {
    link: "Contact MIIA team",
    title: "Contact your MIIA team",
    department: "Department",
    name: "Full Name",
    email: "Email",
    phoneNumber: "Phone Number",
    position: "Position",
  },
  riskManagment: {
    advisories: "Advisories/ Communications",
    bestPractices: "Best Practices",
    osha: "OSHA Resources",
  },
  programs: {
    miiaWinners: "Online programs, videos, recipes.",
    headSpace: "Mindfulness app",
    quizzify: "Health literacy",
    omPractice: "Yoga Classes",
    mindWise: "Mental Health Screening",
    learnToLive: "Behavorial health tool",
    goodHealth: "Diabetes Management",
    smartShopper: "Healthcare Savings Tool",
    miiaEmployee: "Employee Assistance Program",
    programs: "Programs",
    miiaGrants: "Risk Management & Flex Grant Program",
    cybernet: "Cyber resources and training platform",
    eap: "Employee Assistance Program",
    enquiron: "Human Resources online platform",
    localGov: "E-learning platform",
    mfaa: "MIIA/MFAA sponsorship program",
    miiaAgility: "Disaster preparedness and recovery program",
    oshaCertificate: "OSHA Safety & Health Fundamentals Program",
    police: "Police Certification/Accreditation Reimbursement Program",
    viewAll: "Program Descriptions",
    EAP: "Employee assistance program available 24 X 7",
    brightline: "Virtual behavioral health care for kids and teens",
    jointstrong: "Live, virtual care for back, neck, and joint pain (pilot program)",
    exprogram: "Digital tobacco/vape cessation program",
    telephonicWellness: "Phone coaching sessions with a certified coach",
    secondMD: "Live, virtual, independent second opinion service",
  },
  notifications: {
    title: "Notifications",
  },
  navbar: {
    pcInsuranceRecords: "P&C Insurance Records",
    certificatesOfInsurances: "Certificate of Insurance (COI) Request",
    contracts: "Contract",
    reportAClaim: "Report a Claim",
    contractSchedules: "Contract Schedules AS 1/1",
    declarationPages: "Declaration Pages",
    endorsementActivitySummary: "Endorsement activity summary",
    premiumBreakdown: "Request a Premium Breakdown",
    fleet: "Fleet",
    fleetmanagement: "Fleet Management",
    cyberLiaibility: "Cyber Liability",
    applications: "Applications",
    coverageChangeRequests: "Coverage Change Request Forms",
    autoChangeRequest: "Auto Change Request",
    coverageForms: "Coverage Forms",
    payroll: "Payroll",
    autoChangeRequestTransfer: "Auto Change Request Transfer Dept",
    equipmentChangeRequest: "Equipment Change Request",
    professionalLiabilitySchool: "Professional Liability - School Board",
    professionalLiabilityPublicOfficials:
      "Professional Liability - Public Officials",
    professionalLiabilityLawEnforcement:
      "Professional Liability - Law Enforcement",
    statementsOfValues: "Statements of Values",
    specialPropertySupplement: "Special Property Supplement",
    schedules: "Contract Schedules (view and print)",
    bluecrossshieldbenefits: "Blue Cross Blue Shield Benefits",
    coverageRecs: "P&L coverage forms",
    reportaClaim: "Report a Claim",
    certificatesAndInvoices: "Certificates and Invoices",
    enroll_training: "How to Enroll Training Video",
    workersCompensation: "Workers Compensation",
    workersCompensationPostingNotice: "Workers Compensation Posting Notice",
    propertyLiability: "Property & Liability",
    pandlcontractperiodreports: "P&L Contract Period Summary Reports",
    propertyCassualty: "Property & Casualty",
    professionalLiability: "Professional Liability",
    pandlOpenClaimsDetail: "P&L Open Claims Detail",
    workersCompOpenClaimsDetail: "Workers Compensation Open Claims Detail",
    cyberLiability: "Cyber Liability / Report a Breach",
    WCAuditActivity: "WC Audit Activity",
    firstReport: "First Report",
    auto: "Auto",
    current: "Current",
    previous: "Previous",
    unusedCredits: "Unused Credits",
    property: "Property",
    generalLiability: "General Liability",
    unemployment: "Unemployment Services",
    billingStatementsInvoices: "Billing",
    billing: "Billing",
    memberStatements: "Member Statements",
    endorsementActivity: "Endorsment Activity",
    invoces: "Certificate and Invoices",
    rewardsStatusReports: "MIIA Rewards Status Reports",
    training: "Training",
    registertraining: "Register for a Training",
    historytraining: "Training History",
    recordedtraining: "Recorded Trainings",
    trainingCertificate: "Request Training Certificates",
    requesttraining: "Request a Training",
    oshatraining: "OSHA Training",
    oshaChecklist: "OSHA Checklists",
    oshaFormLog: "OSHA Form 300 log",
    oshaFormSumarry: "OSHA Form 300 Summary",
    unemploymentService: "Unemployment",
    fnp: "FNP",
    utmc: "UTMC",
    riskManagement: "Risk Management",
    grantsrmflex: "Grants (RM and Flex)",
    advisoryCommunication: "Advisory/Communications",
    bestPracticesAndResources: "Best Practices and Resources",
    rewards: "Rewards",
    servicePlan: "Service Plan",
    updateFleetSchedules: "Update fleet schedules",
    resources: "Resources",
    logogo: "LocalGovU",
    injuredOnDuty: "Police, Fire, Accident - Injured on Duty",
    enquiron: "Enquiron",
    agilityPreparedness: "Agility/Preparedness - Disaster Recovery",
    employeeAssistance: "Employee Assistance Program (EAP)",
    cybernet: "CyberNET",
    drugAndAlcohol: "Drug and Alcohol Testing",
    mffasponsor: "MFAA Sponsorship",
    wellness: "Wellness",
    health: "Health",
    claimexperience: "Claim Experience Reports",
    lossruns: "Loss Runs",
    wcsummary: "WC Summary Report",
    wcCheckregister: "WC Check register",
    seeAll: "See All",
    actionPlan: "Action Plan",
    sensitiveInformation: "Sensitive Information",
    employeeAssistanceProgram: "Employee Assistance Program (EAP)",
    winnersMiialogin: "MIIA Winners Login",
    wellnessGrantprogram: "Wellness Grant Program",
    scheduleWellnessClass: "Schedue a Wellness Class",
    ekit: "eKit",
    enrollmentsForms: "Enrollment Forms",
    supportingforms: "Supporting Forms",
    bluecrossshield: "Blue Cross Blue Shield",
    workersComp: "Workers Comp Check Register",
    uspenrollmentApplication: "USP Enrollment Application",
    portalAdministration: "Portal Administration",
  },

  portalAdministration: {
    title: "Portal Administration",
    createUser: "CREATE A USER",
    titleEditUser: "Edit User",
    deleteUser: "DELETE USER",
    createUserTag: "Create a user",
    noContact: "No contacts found",
  },
  portalAdministrationList: {
    name: "NAME",
    role: "ROLE",
    title: "TITLE",
    email: "EMAIL",
    edit: "EDIT",
    delete: "DELETE",
  },
  editUser: {
    name: "Name",
    role: "Role",
    title: "Title",
    email: "Email",
    password: "Password",
    lastName: "Last Name",
    dropdown1: "Health",
    dropdown2: "Report a claim",
    dropdown3: "Billing",
    dropdown4: "Training",
    dropdown5: "Unemployement",
    dropdown6: "Risk Management",
    dropdown7: "Resources",
    dropdown8: "Wellness",
    dropdown9: "Claim Experience Reports",
    dropdown10: "Sensitive Info",
    dropdown11: "Property & Liability",
    dropdown12: "Edit and create users",
    cancel: "Cancel",
    saveChanges: "Save Changes",
    discard: "Discard changes",
    success: "You have successfully edited the user",
    successCreate: "You have successfully created the user",
    editUser: "Edit User",
    ok: "Ok",
    accesssettings: "Access Settings",
    sectionsreports: "Sections and reports",
    lostchanges:
      "Are you sure you want to cancel this edition? Your changes will be lost.",
    selectRole: "Select a role",
  },

  claimform: {
    memberCommunityDistrict: "Member Community / District *",
    memberContactPerson: "Member Contact Person *",
    memberContactEmail: "Member Contact Email *",
    contactPhoneNumber: "Contact Phone Number *",
    dateOfIncident: "Date of Incident (mm/dd/yyyy) *",
    locationOfIncident: "Location of Incident *",
    descriptionOfIncident: "Description of Incident *",
    fullnameclaimpresenter: "Full Name of Claim Presenter *",
    claimantphonenumber: "Claimant Phone number *",
    claimantAdress: "Claimant Address *",
    claimantAdressLine2: "Claimant Address line 2",
    claimantCity: "Claimant City *",
    typeVehicle: "Type of vehicle Involved (if applicable)",
    districtDepartment: "Community / District department Involved *",
    generalcomments: "General Comments",
    titleBoxData: "IMPORTANT",
    bodyClaims1:
      "Claims, or potential claims, can come in many forms. Some are obvious matters that should be reported and others may cause confusion. Your general rule should be:",
    itemBoxData1: "When in doubt, report!",
    bodyClaims2:
      "By doing so, you will protect the coverage afforded, assist us in conducting immediate and effective investigations. help to control potentially troublesome situations and allow for timely involvement or legal counsel as appropriate.",
    itemBoxData2:
      "Report all claims or potential claims immediately, even if all details are not available at the time.",
    itemBoxData3: "What to report:",
    report1: "Damage or loss to municipal vehicles, buildings or property",
    report2:
      "Damage or loss to vehicles or property of others potentially or allegedly caused by the municipality or its employees",
    report3: "Injury to municipal employees, agents or volunteers",
    report4:
      "Injury or loss to others potentially allegedly caused by the municipality or its employees",
    itemBoxData4: "What to include when reporting:",
    report5: "Date of loss or incident",
    report6: "Contact person at town with phone number",
    report7: "Year, make and model of town vehicle for auto claims",
    report8:
      "Name, address and phone number of person or entity involved in incident",
    report9: "Brief description of incident or allegation",
    report10:
      "All correspondence, documents, reports or notices associated with the incident",
    cancel: "CANCEL",
    submitClaim: "SUBMIT CLAIM",
    titleForm: "Property & Liability Claim Submission",
    claimreport: "Report a Claim",
    propertyLiability: "Property and Liability",
    attachFiles: "Attach files",
  },
  reports: {
    data: "Data",
    wareHouse: "Warehouse",
  },
  coi: {
    title: "Certificate of Insurance (COI) Request",
    subtitle: "Complete for certificate holder",
    namebussiness: "Name of Business",
    address: "Street Address",
    city: "City",
    state: "State",
    zipcode: "Zip Code",
    explaincoverage: "Explain Coverage Needed",
    datecoi: "Date COI is needed",
    emailcoi: "Email of the member requesting COI",
    attachfiles: "Attach copy of contract and other supporting documents",
    submit: "SUBMIT REQUEST",
    fileslimit: "Select up to 10 files",
    rightsidebarwarning: "If the certificate holder is an ADDITIONAL INSURED, the contract(s) must be endorsed. A statement on this certificate does not confer rights to certificate holder in lieu of such endorsement(s).",
  },
  errors: {
    fieldrequired: "This field is required",
    tooShort: "Too Short!",
    tooLong: "Too Long!",
    max10: "Select up to 10 files",
    mustbenumber: "This field must be a number",
    emailFormat: "Invalid email format",
    invalidDate: "Certificate request date cannot be a past date",
    emailformat: "This field must be in a valid email format.",
  },
  homepage: {
    featuredarticles: "Featured Articles",
    featuredarticle: "Featured Article",
    featuresrecipes: "Featured Recipes",
    seeallrecipes: "See all Recipes",
    seeallarticles: "See all Articles",
    seeallclasses: "See all Classes",
    wellnessupcomingclasses: "Wellness upcoming classes",
    quick: "Quick ",
    access: "Access",
    articles: "Articles",
    recipes: "Recipes",
    contactmiia: "CONTACT YOUR MIIA TEAM",
    about: "ABOUT",
    contact: "CONTACT",
    contact2: "Contact",
    login: "LOGIN",
    logout: "Log out",
    search: "Search...",
    welcome: "Welcome to the ",
    member: "MIIA Member Portal ",
    of: "of",
    youarein: "You're in:",
    youareseeing: "You're seeing:",
    memberportal: "Member Portal",
    portaladministration: "Portal Administration",
    twitter: "Recent tweets",
    administratorLogin: "Administrator Login",
    membership: "is a Membership Service of the",
  },
  quickAccess: {
    title: "Quick Access",
  },
  contentDownload: {
    title: "Content Download",
  },
  newsletter: {
    title: "Read the latest issue",
  },
  footer: {
    about: "About",
    contact: "Contact",
    articles: "Articles",
    recipes: "Recipes",
    miiaAddress: "MIIA address",
    phone: "Phone",
    legal: "© 2022 MIIA. All rights Reserved.",
    userAgreement: "Legal Agreement",
    privacy: "Privacy Policy",
  },
  results: {
    title: "Results",
    noResults: "We couldn't find what you're looking for.",
  },
  riskmanagement: {
    programs: "Risk Management Programs",
    notifications: "NOTIFICATIONS",
    riskTraining: "Risk Management Training",
    register: "Register for a training",
    request: "Request a training",
    recorded: "Recorded trainings",
    osha: "OSHA training",
  },
};
