import { createSlice } from '@reduxjs/toolkit'

const optionReducer = createSlice({
  name: 'counter',
  initialState: {
    option: null,
    optionsList: null,
    errorOptions: null
  },
  reducers: {
    setOptionDetail: (state, action) => {
      state.option = action.payload.data
      state.errorOptions = null
    },
    setOptionsList: (state, action) => {
      state.optionsList = action.payload
        state.errorOptions = null
    },
    setErrorOptions: (state, action) => {
      state.errorOptions = action.payload.errorOptions
    },
    cleanState: (state) => {
      state = optionReducer.initialState
    }
  },
})

export const { setOptionDetail, setOptionsList, setErrorOptions } = optionReducer.actions

export default optionReducer.reducer