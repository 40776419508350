import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';
import UserService from '../services/api/UserService';

const NUMBER_OF_MILLISECONDS_ON_A_MINUTE = 60_000;
const VALID_MINUTES_OF_SESSION = 30 * NUMBER_OF_MILLISECONDS_ON_A_MINUTE // 30 minutes

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
const trackingEnabled = process.env.REACT_APP_MIXPANEL_TRACKING_ENABLED.toLowerCase() === 'true';
const debuggingEnabled = process.env.REACT_APP_MIXPANEL_DEBUGGING_ENABLED.toLowerCase() === 'true';
const mixpanelUrl = process.env.REACT_APP_MIXPANEL_URL;
mixpanel.init(MIXPANEL_TOKEN, { api_host: mixpanelUrl, debug: debuggingEnabled });
// get a variable from the local storage to check if the user events should be tracked or not
const userShouldNotBeTracked = () => localStorage.getItem('userShouldNotBeTracked') === 'true' || localStorage.getItem('userShouldNotBeTracked') === true;


const getCustomSessionId = () => {

	const currentSessionId = JSON.parse(localStorage.getItem('session_id'))
	const currentDate = new Date()
	if (!currentSessionId || currentSessionId.expiresAt < new Date().toISOString()) { // the session will be valid for 30 minutes
		// Set the session because either the session does not exist or the session has expired
		// Session does not exist or the session has expired
		const expiresAt = new Date(currentDate.getTime() + VALID_MINUTES_OF_SESSION);
		const randomId = uuidv4()
		localStorage.setItem('session_id', JSON.stringify({ id: randomId, expiresAt }))
	} else {
		// Session exists and is not expired
		// Increasing the session expiration time by 30 minutes
		const newExpiration  = new Date(currentDate.getTime() + VALID_MINUTES_OF_SESSION).toISOString();
		localStorage.setItem('session_id', JSON.stringify({ id: currentSessionId.id, expiresAt: newExpiration }));
	}

	 const id = JSON.parse(localStorage.getItem('session_id'))?.id;
	 return id;
}

const actions = {
	identify: (userEmail) => {
		if (trackingEnabled && !userShouldNotBeTracked()) mixpanel.identify(userEmail);
		
	},
	track: (action, data) => {
		if (trackingEnabled && !userShouldNotBeTracked()) {
			const userIsLogged = UserService.isLoggedIn();
			const custom_session_id = getCustomSessionId()
			mixpanel.track(action, { ...data, isLogged: userIsLogged, customSessionId: custom_session_id  });
		}
	},
	register: (data) => {
		if (trackingEnabled && !userShouldNotBeTracked()) mixpanel.register({ ...data });
	},
};

export const Mixpanel = actions;