import React, { useEffect, useState, useRef } from "react";
import "../../sass/layout/userManagment/userManagment.css";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MenuPath from "../path/MenuPath";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchAssociatedDropdowns,
  fetchAllRoles,
  userEditPlaceholder,
  requestPasswordReset,
  userEditInfo,
  fetchDropdownSelectOptions,
} from "../../services/actions/users.action";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { usePath } from "../../hooks/usePath";
import { hasUserAdministrationPermission } from "../../helpers/roles";
import Select from "react-select";

const UserManagmentEdit = () => {
  const { t } = useTranslation();

  const {
    userDetail,
    userEdit,
    userEditInfoSuccess,
    roleList,
    dropdownsForUser,
    dropdownSelectOptions,
  } = useSelector((state) => ({
    userDetail: state.userReducer.userDetail,
    userEdit: state.usersReducer.userEdit,
    userEditInfoSuccess: state.usersReducer.userEditInfoSuccess,
    roleList: state.usersReducer.roleList?.data,
    dropdownsForUser: state.usersReducer.dropdownsForUser,
    dropdownSelectOptions: state.usersReducer.dropdownSelectOptions,
  }));

  const dispatch = useDispatch();
  const [submittedForm, setSubmittedForm] = useState(false);
  const [showSuccessEdition, setShowSuccessEdition] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleClose = () => setShowSuccessEdition(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [sectionsAndReports, setSectionsAndReports] = useState([]);
  const navigate = useNavigate();
  const path = usePath();
  const [roleManuallySelected, setRoleManuallySelected] = useState(false);
  const { userId } = useParams();

  const initialValues = {
    first_name: userEdit?.first_name,
    last_name: userEdit ? userEdit.last_name : "",
    email: userEdit ? userEdit.email : "",
    role: selectedRole,
    title: userEdit ? userEdit.title : "",
    sections_and_reports: sectionsAndReports,
  };

  const processSectionsAndReports = (userDetails) => {
    if (!userDetails) return [];
    if (!userDetails.portal_user_role) return [];

    const allowed =
      userDetails.portal_user_custom_entitlements_allowed_sections_and_reports ||
      [];
    const denied =
      userDetails.portal_user_custom_entitlements_denied_sections_and_reports ||
      [];

    const roleSectionsAndReports =
      userDetails.portal_user_role?.sections_and_reports.map(
        ({ id }) => `${id}`
      );

    const newSectionsAndReports = [
      ...roleSectionsAndReports,
      ...allowed,
    ].filter((sr) => !denied.includes(sr));

    return newSectionsAndReports || [];
  };

  useEffect(() => {
    if(!userId) return;
    fetchAllRoles(dispatch);
    fetchDropdownSelectOptions(dispatch);
    userEditPlaceholder(dispatch, { id: userId })
  }, [dispatch, userId]);

  useEffect(() => {
    if (userEditInfoSuccess && submittedForm) {
      setShowSuccessEdition(true);
      setRoleManuallySelected(false);
    }
  }, [dispatch, userEditInfoSuccess, submittedForm]);

  useEffect(() => {
    if (selectedRole?.value) {
      fetchAssociatedDropdowns(dispatch, selectedRole?.value);
    }
  }, [dispatch, selectedRole?.value]);

  useEffect(() => {
    if (userEdit) {
      setSectionsAndReports(processSectionsAndReports(userEdit));
    }
  }, [userEdit]);

  const handleCancel = (e) => {
    navigate(`/${path}/portal-administration`);
    setShowAlert(false);
  };

  const handleCloseAlert = () => setShowAlert(false);

  const UserEditForm = Yup.object().shape({
    first_name: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    last_name: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    email: Yup.string()
      .email(t("errors.emailFormat"))
      .required(t("errors.fieldrequired")),
    title: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    role: Yup.mixed().required(t("errors.fieldrequired")),
    sections_and_reports: Yup.mixed(),
  });


  const processRoleDetails = (roleDetails) => {
    const sectionsAndReports = roleDetails?.sections_and_reports?.map(
      ({ id }) => `${id}`
    );
    return sectionsAndReports || [];
  };

  useEffect(() => {
    if(!userEdit?.portal_user_role) return;

    const { id, name } = userEdit?.portal_user_role;

    if (!id) return;

    setSelectedRole({
      value: id,
      label: name,
    });

    fetchAssociatedDropdowns(dispatch, id);
  }, [userEdit, dispatch]);

  useEffect(() => {
    if (!selectedRole && !roleManuallySelected) return;
    fetchAssociatedDropdowns(dispatch, selectedRole?.value);
  }, [selectedRole, roleManuallySelected, dispatch]);

  useEffect(() => {
    if (userEdit) {
      setSectionsAndReports(processSectionsAndReports(userEdit));
    }
  }, [userEdit, dispatch]);

  useEffect(() => {
    if (!dropdownsForUser || !roleManuallySelected) return;
    setSectionsAndReports(processRoleDetails(dropdownsForUser));
  }, [dropdownsForUser, roleManuallySelected]);

  useEffect(() => {
    if (!hasUserAdministrationPermission(userDetail)) {
      navigate(`/${path}`);
    }
  }, [userDetail, path, navigate]);

  const onSubmitForm = (values) => {
    const {
      first_name,
      last_name,
      email,
      role,
      title,
      portal_user_administration,
      phone,
    } = values;


    const roleSectionsAndReports = dropdownsForUser?.sections_and_reports?.map(
      ({ id }) => `${id}`
    );

    const portal_user_custom_entitlements_allowed_sections_and_reports =
      sectionsAndReports.filter(
        (sr) => !roleSectionsAndReports?.includes(sr)
      );

    const portal_user_custom_entitlements_denied_sections_and_reports =
      roleSectionsAndReports.filter(
        (sr) => !sectionsAndReports?.includes(sr)
      );

    const portal_user_custom_entitlements_user_administration =
      portal_user_administration ? "ENABLED" : "DISABLED";

    const emailChanged = userEdit?.email !== email;

    const formBody = {
      first_name,
      last_name,
      email: !emailChanged ? undefined : email,
      title,
      location: userEdit?.location?.id,
      phone_number: phone,
      portalUserRoleId: role?.value,
      portal_user_custom_entitlements_allowed_sections_and_reports,
      portal_user_custom_entitlements_denied_sections_and_reports,
      portal_user_custom_entitlements_user_administration,
    };

    for (const key in formBody) {
      if (formBody[key] === "" || formBody[key] === undefined) {
        delete formBody[key];
      }
    }

    userEditInfo(dispatch, formBody, userId);
    setSubmittedForm(true);
  };

  return (
    <div className="form-page portalAdministrationContainer">
      <Row className="row-box-container">
        <Col className="request-container-page">
          <Row className="">
            <MenuPath
              className="menuPathPortalAdmin"
              name={t("portalAdministration.title")}
              nameItem={t("portalAdministration.titleEditUser")}
            />
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="box-container box-container-position"
          >
            <Col className="box-container-title">
              <h3>{t("portalAdministration.titleEditUser")}</h3>
            </Col>
            <Col className="box-container-form">
              {initialValues?.first_name ? (
                <Formik
                  initialValues={initialValues}
                  validationSchema={UserEditForm}
                  onSubmit={onSubmitForm}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form>
                      <Row>
                        <Col md={4} className="box-container-form-input">
                          <Form.Group>
                            <Form.Label>{t("editUser.name")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="first_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_name}
                            />
                            {touched.first_name && errors.first_name ? (
                              <div
                                style={{
                                  color: "#8F003C",
                                }}
                              >
                                {errors.first_name}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={4} className="box-container-form-input">
                          <Form.Group>
                            <Form.Label>{t("editUser.lastName")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="last_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.last_name}
                            />
                            {touched.last_name && errors.last_name ? (
                              <div
                                style={{
                                  color: "#8F003C",
                                }}
                              >
                                {errors.last_name}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={4} className="box-container-form-input">
                          <Form.Group>
                            <Form.Label>{t("editUser.email")}</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              placeholder={userEdit?.email}
                            />
                            {touched.email && errors.email ? (
                              <div
                                style={{
                                  color: "#8F003C",
                                }}
                              >
                                {errors.email}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} className="box-container-form-input">
                          <Form.Group>
                            <Form.Label>{t("editUser.role")}</Form.Label>
                            <Select
                              options={roleList.map((role) => ({
                                value: role.id,
                                label: role.attributes?.name,
                              }))}
                              name="role"
                              value={values.role}
                              onChange={(option) => {
                                const { value, label } = option;
                                setRoleManuallySelected(true);
                                setSelectedRole({ value, label });
                                setFieldValue("role", { value, label });
                              }}
                              isInvalid={!selectedRole && touched.role}
                              onBlur={handleBlur}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                            {!selectedRole && touched.role ? (
                              <div
                                style={{
                                  color: "#8F003C",
                                }}
                              >
                                {t("errors.fieldrequired")}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={4} className="box-container-form-input">
                          <Form.Group>
                            <Form.Label>{t("editUser.title")}</Form.Label>
                            <Form.Control
                              type="text"
                              name="title"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.title}
                              placeholder={userEdit?.title}
                            />
                            {touched.title && errors.title ? (
                              <div
                                style={{
                                  color: "#8F003C",
                                }}
                              >
                                {errors.title}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col md={4} className="box-container-form-input">
                          <Form.Group className="password-reset-button-container">
                            <Form.Label>{t("editUser.password")}</Form.Label>
                            <Button
                              className="form-action"
                              onClick={() =>
                                requestPasswordReset(userEdit?.email)
                              }
                            >
                              Request Password Update
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="rowBottomBox">
                        <Col>
                          <h5 className="access-settings-portal-admin">
                            {t("editUser.accesssettings")}
                          </h5>
                          <h6 className="sections-reports-portal-admin">
                            {t("editUser.sectionsreports")}
                          </h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Col>
                            <Form className="form-checkbox">
                              {dropdownSelectOptions?.data?.map(
                                ({ id, attributes: { name } }, index) => (
                                  <Form.Check
                                    type="checkbox"
                                    label={name}
                                    name={name}
                                    value={id}
                                    checked={sectionsAndReports?.includes(
                                      id.toString()
                                    )}
                                    onChange={(e) => {
                                      setSectionsAndReports(
                                        e.target.checked
                                        ? [
                                            ...sectionsAndReports,
                                            e.target.value,
                                          ]
                                        : sectionsAndReports.filter(
                                            (item) =>
                                              item !== e.target.value
                                          )
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                )
                              )}
                            </Form>
                          </Col>
                        </Col>
                      </Row>
                      <Row className="row-btns-userManagment">
                        <>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setShowAlert(true);
                            }}
                            className="btn style-btn-1 btn-cancel-coi"
                          >
                            {t("claimform.cancel")}
                          </button>
                          <Modal
                            show={showAlert}
                            onHide={handleCloseAlert}
                            backdrop="static"
                            keyboard={false}
                          >
                            <Modal.Body>{t("editUser.lostchanges")}</Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary btn-cancel-it"
                                onClick={() => handleCloseAlert()}
                              >
                                {t("editUser.cancel")}
                              </Button>
                              <Button
                                variant="primary btn-delete-it"
                                onClick={() => handleCancel()}
                              >
                                {t("editUser.discard")}
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </>
                        <button onClick={handleSubmit} className="btn btn-primary">
                          {t("editUser.saveChanges")}
                        </button>
                      </Row>
                      <Modal
                        show={(showSuccessEdition && submittedForm)}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Body>{t("editUser.success")}</Modal.Body>
                        <Modal.Footer>
                          <Link
                            style={{
                              textDecoration: "none !important",
                              color: "#FFFFFF",
                              backgroundColor: "#8F033C",
                              width: "100px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                            }}
                            className="link-userEdit"
                            to={`/${path}/portal-administration`}
                          >
                            {t("editUser.ok")}
                          </Link>
                        </Modal.Footer>
                      </Modal>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Row className="container-spinner">
                  <Spinner
                    animation="border"
                    variant="danger"
                    className="spinner"
                  />
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UserManagmentEdit;
