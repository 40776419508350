// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  background-color: #e4e6eb;
}

.webpack-dev-server-client-overlay-div,
#webpack-dev-server-client-overlay
{
  display: none !important;
}


::-webkit-scrollbar {
  width: 9px !important;
  background-color: #ffff !important;
}
/* ::-webkit-scrollbar-track {
} */
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5) !important;
  border-radius: 20px !important;
  border: transparent !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;;;EAGE,wBAAwB;AAC1B;;;AAGA;EACE,qBAAqB;EACrB,kCAAkC;AACpC;AACA;GACG;AACH;EACE,qDAAqD;EACrD,8BAA8B;EAC9B,8BAA8B;AAChC","sourcesContent":["html {\n  background-color: #e4e6eb;\n}\n\n.webpack-dev-server-client-overlay-div,\n#webpack-dev-server-client-overlay\n{\n  display: none !important;\n}\n\n\n::-webkit-scrollbar {\n  width: 9px !important;\n  background-color: #ffff !important;\n}\n/* ::-webkit-scrollbar-track {\n} */\n::-webkit-scrollbar-thumb {\n  background-color: rgba(155, 155, 155, 0.5) !important;\n  border-radius: 20px !important;\n  border: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
