// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.twitter-container {
  width: 100%;
}

.twitter-timeline > iframe {
  display: block !important;
  border-radius: 12px;
}

@media (max-width: 1200px) {
  .twitter-container {
    height: 400px;
    margin-bottom: 5rem;
  }
}
.font-twitter-title {
  font-family: "Lato", sans-serif !important;
  font-size: 22px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/twitterTimeline/twitterTimeline.scss","webpack://./src/sass/layout/twitterTimeline/twitterTimeline.css","webpack://./src/sass/utilities/_variables.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;ACFF;;ADKA;EACE,yBAAA;EACA,mBAAA;ACFF;;ADKA;EACE;IACE,aAAA;IACA,mBAAA;ECFF;AACF;ADIA;EACE,0CEjBK;EFkBL,eAAA;EACA,gBAAA;ACFF","sourcesContent":["@import \"../../utilities/mixins\";\n@import \"../../utilities/variables\";\n\n.twitter-container {\n  width: 100%;\n}\n\n.twitter-timeline > iframe {\n  display: block !important;\n  border-radius: 12px;\n}\n\n@media (max-width: 1200px) {\n  .twitter-container {\n    height: 400px;\n    margin-bottom: 5rem;\n  }\n}\n.font-twitter-title {\n  font-family: $Font;\n  font-size: 22px;\n  font-weight: 700;\n}\n",".twitter-container {\n  width: 100%;\n}\n\n.twitter-timeline > iframe {\n  display: block !important;\n  border-radius: 12px;\n}\n\n@media (max-width: 1200px) {\n  .twitter-container {\n    height: 400px;\n    margin-bottom: 5rem;\n  }\n}\n.font-twitter-title {\n  font-family: \"Lato\", sans-serif !important;\n  font-size: 22px;\n  font-weight: 700;\n}/*# sourceMappingURL=twitterTimeline.css.map */","// Utils\n$DecorationNone: none;\n$Font: \"Lato\", sans-serif !important;\n$Font-size-navbar: 12px;\n$LetterSpacing: 0px;\n\n// Colors\n$Transparent: rgba(0, 0, 0, 0);\n$RedColor: rgb(143, 0, 60, 1);\n$White: rgba(255, 255, 255, 1);\n$Black: rgba(26, 25, 25, 1);\n$MainBackgroundColor: rgba(247, 248, 249, 1);\n$SecondaryBackgroundColor: rgba(221, 208, 208, 0.5);\n$GrayColor: rgba(204, 204, 204, 1);\n$LinkColor: rgba(18, 101, 183, 1);\n$LinkSecondColor: rgba(78, 125, 162, 1);\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
