import { React, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import QuickAccess from "../quickAccess";
import SideNavBar from "../sideBar/sideBar";
import "../../sass/layout/layout/layout.css";
import NavbarComponent from "../navbar";
import LoginBtn from "../views/loginBtn";
import Footer from "../footer";
import { getMyUserInfo, getTokenMedia } from '../../services/actions/home.action';
import { setBlockPortalRedirections } from '../../services/reducers/homeReducer';
import { handleLocationUpdate, handleRedirection } from '../../utils/home';
import UserService from '../../services/api/UserService';
import { useNavigate } from 'react-router-dom';
import { setStatus } from '../../services/actions/report.action';
import MobileQuickAccessNav from "../quickAccessMobile";

const Layout = (props) => {
  const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const locationIdFromURL = window.location.pathname.split('/')?.at(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isQAMenuOpen, setIsQAMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsQAMenuOpen(!isQAMenuOpen);
  };

  const { location, myUserInfo, blockPortalRedirections } = useSelector(
    (state) => ({
      isUserLoggedIn: state.userReducer.loginStatus,
      location: state.homeReducer.location?.data,
      myUserInfo: state.homeReducer.myUserInfo,
      blockPortalRedirections: state.homeReducer.blockPortalRedirections,
    })
  );

  const isUserLoggedIn = UserService.isLoggedIn();
  const userLocation = myUserInfo?.location;
  const id = location?.uniqueNonGuessableId;

  useEffect(() => {
    if(isUserLoggedIn) {
      getMyUserInfo(dispatch);
    }
  }, [isUserLoggedIn, dispatch]);

  useEffect(() => {
    //Reset to allow redirection from other useEffects
    dispatch(setBlockPortalRedirections(false));
  }, [dispatch, isUserLoggedIn]);

  // Get location data
  useEffect(
    () => handleLocationUpdate(dispatch, userLocation),
    [dispatch, userLocation, isUserLoggedIn, navigate]
  );

  // Redirect if trying to access wrong member
  useEffect(
    () =>
      handleRedirection(
        myUserInfo,
        locationIdFromURL,
        blockPortalRedirections,
        navigate
      ),
    [dispatch, locationIdFromURL, navigate, myUserInfo, isUserLoggedIn, blockPortalRedirections]
  );

  useEffect(() => {
    if (id && id !== "/") {
      sessionStorage.setItem("id", id);
    }
    setStatus(dispatch);
    getTokenMedia(dispatch);
  }, [dispatch, id]);


  return (
    <Container fluid className="view-container-page">
      <SideNavBar />
      <Row className="content-layout">
        <Col
          className={
            loginStatus
              ? "container-quick-access-layout"
              : "container-quickAccess-logged-layout"
          }
        >
          <QuickAccess />
        </Col>
        <Col md={12} lg={12} xl={9} xxl={9} className="container-data-layout">
          <Row className="navbar-layout">
        <div className="navbar-component">
              <NavbarComponent />
            </div>
            <div className="loginbutton-component">
              <LoginBtn />
            </div>


          </Row>
          <Row >
     
            <MobileQuickAccessNav
            handleMenuToggle={handleMenuToggle}
            isQAMenuOpen={isQAMenuOpen}
            />
    
          </Row>
          <div className={`children ${isQAMenuOpen ? 'quick-access-mobile-displaced' : ''}`} >
          {props.children}
          </div>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
