import { createSlice } from "@reduxjs/toolkit";

const recipeReducer = createSlice({
  name: "counter",
  initialState: {
    recipe: null,
    recipeshome: null,
    recipesList: null,
    recipesMeta: null,
    errorRecipes: null,
  },
  reducers: {
    recipeDetail: (state, action) => {
      state.recipe = action.payload.recipeSelected;
      state.errorRecipes = null;
    },
    setInitialRecipes: (state, action) => {
      state.recipeshome = action.payload.recipesHome;
      state.errorRecipes = null;
    },
    setRecipes: (state, action) => {
      state.recipesList = action.payload.recipesList;
      state.errorRecipes = null;
    },
    setRecipesMeta: (state, action) => {
      state.recipesMeta = action.payload.recipesMeta;
    },
    setErrorRecipes: (state, action) => {
      state.errorRecipes = action.payload.errorRecipes;
    },
    cleanState: (state) => {
      state = recipeReducer.initialState;
    },
  },
});

export const {
  recipeDetail,
  setInitialRecipes,
  setRecipes,
  setErrorRecipes,
  setRecipesMeta,
  cleanState,
} = recipeReducer.actions;

export default recipeReducer.reducer;
