import Keycloak from "keycloak-js";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import { setInfoUser, setLoginStatus } from "../reducers/userReducer";

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback, store) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoFallback: false, // for firefox and safari - to check
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
      redirectUri: window.location.href + '/',
    })
    .then((authenticated) => {
      if (authenticated) {

        Mixpanel.identify(_kc.tokenParsed.email);
        Mixpanel.register({
          user_email: _kc.tokenParsed.email,
        });
        Mixpanel.track("user logged in", {
          email: _kc.tokenParsed.email,
          locationName: store.getState().homeReducer.location?.data?.name,
        });


        store.dispatch(setLoginStatus({ loginStatus: isLoggedIn() }));
        store.dispatch(setInfoUser({ infoUser: getUsername() }));
        onAuthenticatedCallback();
      } else {
        store.dispatch(setLoginStatus({ loginStatus: isLoggedIn() }));
      }
    })
    .catch((error) => {
      console.log("error init keycloack", error);
      // window.location.reload();
    });
};

const doLogin = () => {
  window.history.pushState({ page: 1 }, "title 1", "?page=1"); // hack to fix keycloak redirect bug
  // Without this hack, the user is redirected to a blank if uses the back button on the login page
  _kc.login();
}
const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => {
  return _kc.tokenParsed;
};

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;
