import { useState, useEffect } from "react";
import { fetchRecipeById } from "../../services/actions/recipes.action";

const fetchRecipeData = async (recipeId) => {
  const { data } = await fetchRecipeById(recipeId);
  return data;
};

export const useFetchRecipeById = (recipeId) => {
  const [recipe, setRecipe] = useState();

  useEffect(() => {
    const getRecipe = async () => {
      const recipeData = await fetchRecipeData(recipeId);
      setRecipe(recipeData);
    };

    getRecipe();
  }, [recipeId]);

  return recipe;
};
