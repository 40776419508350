import { Link } from "react-router-dom";
import "../../sass/layout/quickAccess/quickAccess.css";
import UserService from "../../services/api/UserService";
import { usePath } from "../../hooks/usePath";
import { Mixpanel } from "../../utils/mixpanelIntegration";
const { useSelector } = require("react-redux");

const QuickAccessItem = ({ itemquickacces, isMobile=false }) => {
  const { tokenMedia } = useSelector((state) => state.homeReducer);
  const locationPath = usePath();
  const location = useSelector((state) => state.homeReducer.location?.data);
  
  const trackQuickAccessUse = (quickLink, quickLinkName) => {
    Mixpanel.track("QuickLink.View", {
      isLogged: false,
      quickLink: quickLink,
      quickLinkName,
      locationName: location?.name,
    });
  };

  const buildLink = (type) => {
    return (
      <a
        className="link-external"
        href={
          ["pdf_file", "file"].includes(type)
            ? `${itemquickacces?.file?.url}?${tokenMedia}`
            : itemquickacces?.link
        }
        onClick={() =>
          trackQuickAccessUse(itemquickacces?.link, itemquickacces?.name)
        }
        target="_blank"
        rel="noreferrer"
      >
        {itemquickacces?.name}
      </a>
    );
  };

  const mapTypeToLink = {
    internal_link: (
      <Link
        className="link-external"
        to={
          itemquickacces?.link
            ? `/${locationPath}/${itemquickacces?.link}`
            : `/${locationPath}`
        }
        onClick={() =>
          trackQuickAccessUse(itemquickacces?.link, itemquickacces?.name)
        }
      >
        {itemquickacces?.name}
      </Link>
    ),
    external_link: buildLink("external_link"),
    pdf_file: buildLink("pdf_file"),
    file: buildLink("file"),
  };

  return (
    <li
      className={
        UserService.isLoggedIn()
          ? "li-quickAccess-public"
          : "li" + ( isMobile ? " my-3" : "" )
      }
    >
      {mapTypeToLink[itemquickacces?.type]}
    </li>
  );
};

export default QuickAccessItem;
