import React, { useEffect, useState } from "react";
import "./../../sass/layout/portalAdministrationList/portalAdministrationList.css";
import { Container, Row, Col } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ContactList = ({ prop }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Container fluid className="portalAdministrationListContainer">
      <Row>
        <Col>
          <ListGroup>
            <ListGroup.Item className="list-group-item-portal-administration">
              <Row className="row-contactMIIATeam">
                <Col>
                  <p>{t("contact.name")}</p>
                </Col>
                <Col>
                  <p>{t("contact.position")}</p>
                </Col>
                <Col>
                  <p>{t("contact.email")}</p>
                </Col>
                <Col>
                  <p>{t("contact.phoneNumber")}</p>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Col>
            <ListGroup>
              {prop &&
                prop.map((item, index) => (
                  <ListGroup.Item
                    key={item.id}
                    className="item-list-portal-administration"
                  >
                    <Col
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        paddingLeft: "0px",
                      }}
                      className="col-contactMIIATeam"
                    >
                      {item?.attributes?.full_name}
                    </Col>
                    <Row className="row-contact">
                      <Col className="full-name-contact">
                        {item?.attributes?.full_name}
                      </Col>
                      {<Col>{item?.attributes?.contact_role?.data?.attributes?.title}</Col>}
                      {<Col>{item?.attributes?.email}</Col>}
                      <Col>{item?.attributes?.phone_number}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
export default ContactList;
