import { Row, Col } from "react-bootstrap";
import "../../sass/layout/homePage/homepage.css";
import DivLinks from "../divLinks";
import FeaturedArticules from "../featuredArticules";
import FeaturedRecipes from "../featuredRecipes";
import TwitterTimelineComponent from "../twitterTimeline";
import { useSelector } from "react-redux";
import HomeSchedule from "../homeSchedule";
import Notification from "../notifications/notification";
import Programs from "../programs/programs";
import Carrousel from "../carrousel/carrousel";
import RiskManagment from "../riskManagment/riskManagment";
import { useEffect, useRef, useState } from "react";
import UserService from "../../services/api/UserService";
import FeaturedNews from "../featured-news";
import NewsLetter from "./newsletter";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import CheckingCredentials from "./checkingCredentials/checkingCredentials";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import { userVisitedThePortal } from "../../services/actions/user.action";

const showTwitterFeed = process.env.REACT_APP_SHOW_TWITTER_FEED === "true";

const HomePage = () => {
  const navigate = useNavigate();
  const [searchParams,] = useSearchParams();
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState(false);
  const isUserLoggedIn = UserService.isLoggedIn();

  const { location } = useSelector((state) => ({
    isUserLoggedIn: state.userReducer.loginStatus,
    location: state.homeReducer.location?.data,
    myUserInfo: state.homeReducer.myUserInfo,
    blockPortalRedirections: state.homeReducer.blockPortalRedirections,
  }));

  const id = location?.uniqueNonGuessableId;
  const products = location?.purchasedProduct?.map(
    (item) => item?.product?.name
  );

  const hasProduct = (requiredProduct, purchasedProducts) =>
    purchasedProducts?.find((product) => product === requiredProduct);

  useEffect(() => {
    if (id && !hasProduct("Health", products)) {
      navigate("riskManagement");
    }
  }, [id, products, navigate]);

  const [effectExecuted, setEffectExecuted] = useState(false); // used to prevent sending the event twice
  useEffect(() => {
    const userIsLoggedIn = UserService.isLoggedIn();
    if (!userIsLoggedIn && location?.name && !effectExecuted) {
       Mixpanel.track("NonLogin.View", { locationName: location?.name })
        setEffectExecuted(true); // Mark the effect as executed
       };
  }, [location?.name, effectExecuted ]);

  useEffect(() => {
    setLoader(true);

    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, [location?.uniqueNonGuessableId]);

  useEffect(() => {
    const code = searchParams.get('code');
    userVisitedThePortal(code);
  }, [searchParams]);


  return !loader ? (
    <div>
      <Col md={12} xl={12} className="homePage-content">
        <Row style={{ padding: "0rem 1rem", margin: "0px" }}>
          <Notification
            setShow={setShow}
            show={show}
            notificationpage={true}
            className="homePage-notification"
          />
        </Row>

        <Row style={{ padding: "0rem 1rem", margin: "0px" }}>
          <Col md={12} xl={12} className="homePage-programs">
            {!isUserLoggedIn ? <Programs /> : <FeaturedNews />}
          </Col>
        </Row>

        {isUserLoggedIn && hasProduct("Risk Management", products) ? (
          <Row style={{ padding: "0px", margin: "0px" }}>
            <Col md={12} xl={12} className="homePage-riskManagment">
              <RiskManagment />
            </Col>
          </Row>
        ) : null}

        <Row style={{ padding: "0px", margin: "0px" }}>
          <Col md={12} xl={12} className="homePage-risk-managment">
            <Carrousel />
          </Col>
        </Row>

        <Row style={{ padding: "0px", margin: "15px 0px" }}>
          <Col md={12} xl={6} className="homePage-wellness-classes">
            <HomeSchedule />
          </Col>
          <Col md={12} xl={6} className="homePage-twitter">
            <DivLinks />
          </Col>
        </Row>

     <Row style={{ padding: "0px", margin: "0px", marginBottom: "-5rem" }}>

          { showTwitterFeed && (
          <Col md={{ order: 1 }} xl={12} className="homePage-twitter-timeline">
            <TwitterTimelineComponent />
            
          </Col>
          )}


          <Col
            md={{ order: 1 }}
            xl={12}
            className="homePage-featured-articles"
          >
            <NewsLetter />
          </Col>
        {/* </Row>
        <Row style={{ padding: "0px", margin: "0px" }}> */}
          <Col md={{order: 2}} xl={6} className="homePage-featured-recipes" style={{marginTop: "1rem"}} >
            <FeaturedRecipes />
          </Col>
          <Col md={{order: 2}} xl={6} className="homePage-featured-recipes" style={{marginTop: "1rem"}}>
            <FeaturedArticules />
          </Col>
        </Row>

   
      </Col>
    </div>
  ) : (
    <CheckingCredentials />
  );
};

export default HomePage;
