import "../../sass/layout/recipeList/recipeList.css";
import Row from "react-bootstrap/Row";
import RecipeListContainer from "../recipes/recipeListContainer";
import MenuPath from "../path/MenuPath";
import { useEffect } from "react";
import { t } from "i18next";

const RecipeList = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Row className="row-menu-container">
        <MenuPath name={t("recipe.recipes")} />
      </Row>
      <RecipeListContainer />
    </div>
  );
};

export default RecipeList;
