import { Container } from "react-bootstrap";
import "../../sass/layout/articleContainer/articleContainer.css";
import ArticleContent from "./articleContent";
import { useSelector } from "react-redux";
import imgPlaceholder from "../../utils/img/imgPlaceholder.png";
import { useFetchArticleById } from '../../hooks/article/useFetchArticleById';

const ArticleContainer = ({ articleId }) => {
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const article = useFetchArticleById(articleId);

  return (
    <Container fluid className="article-box-data">
      <Container fluid className="container-img-article">
        <div
          style={{
            height: "350px",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundSize: "cover",
            backgroundImage: article?.image?.url
              ? `url(${
                  article?.image?.url +
                  "?" +
                  tokenMedia
                })`
              : `url(${imgPlaceholder})`,
          }}
        ></div>
      </Container>
      <ArticleContent article={article} />
    </Container>
  );
};

export default ArticleContainer;
