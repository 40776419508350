import { useEffect, useState } from 'react';

const getNextSlide = (currentSlide, carouselSlides) => {
  if (!carouselSlides?.length) return;

  const currentIndex = carouselSlides.findIndex(
    (slide) => slide.id === currentSlide
  );
  return currentIndex + 1 < carouselSlides.length
    ? carouselSlides[currentIndex + 1].id
    : carouselSlides[0].id;
};

export const useCarouselClicked = (carouselSlides, carouselSpeed) => {
  const [clicked, setClicked] = useState(null);

  useEffect(() => {
    if (!clicked) setClicked(carouselSlides?.at(0)?.id);

    const timeout = setTimeout(() => {
      setClicked(getNextSlide(clicked, carouselSlides));
    }, carouselSpeed);

    return () => clearTimeout(timeout);
  }, [clicked, carouselSlides, carouselSpeed]);

  return [clicked, setClicked];
};
