import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../sass/layout/featuredArticules/featuredArticules.css";
import { articleDetail } from "../services/reducers/articleReducer";
import { fetchInitialArticles } from "../services/actions/articles.action";
import imgPlaceholder from "../utils/img/imgPlaceholder.png";

const FeaturedArticules = () => {
  const articles = useSelector((state) => state.articleReducer.articleshome);
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setClicked(articles && articles[0]?.id);
  }, [articles]);

  const [clicked, setClicked] = useState();
  const [showArticles, setShowArticles] = useState(0);

  useEffect(() => {
    fetchInitialArticles(dispatch);
  }, []);

  useEffect(() => {
    if (clicked && articles?.length > 0) {
      setShowArticles(articles?.find((article) => article.id == clicked));
    }
  }, [clicked, articles]);

  const identi = articles?.find((article) => article?.id == clicked);

  const onClickArticle = () => {
    navigate({
      pathname: `article/${identi?.id}`,
    });
    dispatch(articleDetail({ articleSelected: identi }));
  };

  const onClickSlide = (e) => {
    setClicked(e.target.value);
  };

  const style = {
    backgroundImage: showArticles?.attributes?.image?.data?.attributes?.url
      ? `url(${
          showArticles?.attributes?.image?.data?.attributes?.url +
          "?" +
          tokenMedia
        })`
      : `url(${imgPlaceholder})`,

    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <Container fluid className="box-container">
      <Container fluid className="titles-box">
        <h4 className="title-featured-article">
          {t("homepage.featuredarticles")}
        </h4>
        <Link to="articleslist">
          <span className="span-recipes">{t("homepage.seeallarticles")}</span>
        </Link>
      </Container>
      <div className="featured-container">
        <div
          style={style}
          onClick={onClickArticle}
          className="featured-image"
        ></div>
        <div onClick={onClickArticle} className="featured-text">
          <h5>
            <strong>{showArticles?.attributes?.title}</strong>
          </h5>
          <p className="featured-p">{showArticles?.attributes?.description}</p>
        </div>
        <div className="featured-pagination-card">
          {articles?.slice(0, 4).map((element) => {
            return (
              <button
                key={element?.id}
                value={element?.id}
                onClick={onClickSlide}
                className="featured-btns"
                style={
                  clicked == element?.id ? { backgroundColor: "#1A1919" } : {}
                }
              ></button>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default FeaturedArticules;
