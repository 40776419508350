import "../../sass/layout/recipe/recipe.css";
import RecipeContainer from "../recipes/recipeContainer";
import Row from "react-bootstrap/Row";
import React, { useEffect, useRef } from "react";
import { ComponentToPrint } from "../../utils/printComponent";
import { useSelector, useDispatch } from "react-redux";
import MenuPath from "../path/MenuPath";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePath } from "../../hooks/usePath";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import UserService from '../../services/api/UserService';
import { useFetchRecipeById } from '../../hooks/recipe/useFetchRecipeById';

const Recipe = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const path = usePath();
  const componentRef = useRef();
  const location = useSelector((state) => state.homeReducer.location?.data);
  const { id: recipeId } = useParams();
  const recipe = useFetchRecipeById(recipeId);
  const idItem = window.location.pathname.split("/")[2];
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  const onClickRecipesList = () => {
    id !== "/"
      ? navigate({
          pathname: `/${path}/recipeslist`,
        })
      : navigate({
          pathname: `/recipeslist`,
        });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (recipeId) Mixpanel.track("Recipe.View", { isLogged: UserService.isLoggedIn(), recipeId, locationName: location?.name});
  }, [idItem]);

  return (
    <div>
      <Row className="row-menu-container">
        <MenuPath
          name={t("recipe.recipes")}
          nameItem={recipe?.title}
          onClickItem={onClickRecipesList}
        />
      </Row>
      <ComponentToPrint
        ref={componentRef}
        component={<RecipeContainer recipe={recipe} componentRef={componentRef} />}
      />
    </div>
  );
};

export default Recipe;
