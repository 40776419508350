import { compose, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "../reducers/store";
import { configureStore } from "@reduxjs/toolkit";
import axiosMiddleware from "redux-axios-middleware";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import axios from "./HttpService";

let composeEnhancers = compose;

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  enhancer: composeEnhancers(
    applyMiddleware(ReduxThunk),
    axiosMiddleware(axios)
  ),
});


const setup = () => {
  return store
};


const StoreService = {
  setup,
};



export default StoreService;

export let persistor = persistStore(store)
