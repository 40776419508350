import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../sass/layout/articleListContainer/articleListContainer.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePath } from "../../hooks/usePath";
import imgPlaceholder from "../../utils/img/imgPlaceholder.png";

const RecipePreviewList = ({ border, recipe }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = usePath();
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  return (
    <Container fluid>
      <Row className={border ? "article-item-border" : "article-item"}>
        <Col xs={12} md={5}>
          <div
            style={{
              height: "15rem",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundPositionY: "center",
              backgroundSize: "cover",
              backgroundImage: recipe?.attributes?.image?.data?.attributes?.url
                ? `url(${
                    recipe?.attributes?.image?.data?.attributes?.url +
                    "?" +
                    tokenMedia
                  })`
                : `url(${imgPlaceholder})`,
            }}
          ></div>
        </Col>
        <Col className="text">
          <h2 className="h2-recipeList">{recipe?.attributes.name}</h2>
          <p className="description">{recipe?.attributes.description}</p>
          <div
            className="seeArticle"
            onClick={() => {
              id !== "/" && navigate(`/${path}/recipe/${recipe?.id}`);
              id === "/" && navigate(`/recipe/${recipe?.id}`);
            }}
          >
            {t("recipe.seeRecipe")}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RecipePreviewList;
