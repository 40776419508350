import { Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../../sass/layout/retireesContainer/retireesContainer.css";
import ItemContainerGeneral from "../itemContainerGeneral";
import { FiFileText } from "react-icons/fi";

const RetireesItem = () => {

    const { t } = useTranslation();

    const RetireesContent = () => {
        return <div className="retiree-content">
            <h6>2022 SUMMARY OF BENEFITS</h6>
            <p>Managed Blue for Seniors</p>
        </div>
    }

    const Icon = () => {
        return <FiFileText className="icon-retiree" />
    }

    return (
        <ItemContainerGeneral content={<RetireesContent />} logo={<Icon />} />
    );
};

export default RetireesItem;