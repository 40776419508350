import "../../../sass/layout/userManagment/userManagment.css";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MenuPath from "../../path/MenuPath";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  createMember,
  fetchAssociatedDropdowns,
  resetStatusCode,
  fetchAllRoles,
  fetchDropdownSelectOptions,
} from "../../../services/actions/users.action";
import { usePath } from "../../../hooks/usePath";
import { findOnePortalUser } from "../../../services/actions/user.action";
import { hasUserAdministrationPermission } from "../../../helpers/roles";
import Select from "react-select";

const CreateMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const handleClose = () => setShow(false);
  const [roleChecked, setRoleChecked] = useState();
  const path = usePath();
  const [sectionsAndReports, setSectionsAndReports] = useState([]);

  const {
    infoUser,
    userDetail,
    roleList,
    location,
    dropdownsAssociatedList,
    memberCreated,
    memberCreatedError,
    dropdownSelectOptions,
  } = useSelector((state) => ({
    roleList: state.usersReducer.roleList?.data,
    location: state.homeReducer.location,
    dropdownsAssociatedList: state.usersReducer.dropdownsForUser,
    memberCreated: state.usersReducer.memberCreated,
    memberCreatedError: state.usersReducer.memberCreatedError,
    dropdownSelectOptions: state.usersReducer.dropdownSelectOptions,
    infoUser: state.userReducer.infoUser,
    userDetail: state.userReducer.userDetail,
  }));

  useEffect(() => {
    memberCreated === 200 && setShow(true);
    memberCreatedError.response?.status === 400 && setError(true);
    resetStatusCode(dispatch);
  }, [memberCreated, memberCreatedError]);

  useEffect(() => {
    fetchAllRoles(dispatch);
    fetchDropdownSelectOptions(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (roleChecked?.value) {
      fetchAssociatedDropdowns(dispatch, roleChecked?.value);
    }
  }, [dispatch, roleChecked?.value]);

  useEffect(() => {
    if (infoUser?.email) {
      findOnePortalUser(dispatch, infoUser?.email);
    }
  }, [dispatch, infoUser]);

  useEffect(() => {
    if (!hasUserAdministrationPermission(userDetail)) {
      navigate(`/${path}`);
    }
  }, [userDetail, navigate, path]);

  const handleDelete = (e) => {
    navigate(`/${path}/portal-administration`);
    setShowAlert(false);
  };

  const handleCloseAlert = () => setShowAlert(false);

  const handleCheck = (e) => {
    if (sectionsAndReports.includes(e.target.value)) {
      sectionsAndReports.splice(sectionsAndReports.indexOf(e.target.value), 1);
    } else {
      sectionsAndReports.push(e.target.value);
    }
    setSectionsAndReports(sectionsAndReports);
  };

  const UserEditForm = Yup.object().shape({
    first_name: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    last_name: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    email: Yup.string()
      .email(t("errors.emailFormat"))
      .required(t("errors.fieldrequired")),
    title: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    role: Yup.string().required(t("errors.fieldrequired")),
    sections_and_reports: Yup.mixed(),
  });

  const onSubmitForm = (values) => {
    values.location = location?.data.uniqueNonGuessableId;
    createMember(dispatch, values, sectionsAndReports, dropdownsAssociatedList);
  };

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    title: "",
    role: "",
    sections_and_reports: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UserEditForm,
    onSubmit: onSubmitForm,
  });

  const processRoleDetails = (role) => {
    const sectionsAndReports = role?.sections_and_reports?.map(
      ({ id }) => `${id}`
    );
    return sectionsAndReports;
  };

  useEffect(() => {
    if (!dropdownsAssociatedList || !roleChecked) return;
    setSectionsAndReports(processRoleDetails(dropdownsAssociatedList));
  }, [dropdownsAssociatedList, roleChecked]);

  return (
    <div className="form-page portalAdministrationContainer">
      <Col md={12} xl={12} className="row-box-container">
        <Col className="request-container-page">
          <Row className="">
            <MenuPath
              className="menuPathPortalAdmin"
              name={t("portalAdministration.title")}
              nameItem={t("portalAdministration.createUserTag")}
            />
          </Row>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="box-container box-container-position"
          >
            <Col className="box-container-title">
              <h3>{t("portalAdministration.createUserTag")}</h3>
            </Col>
            <Col className="box-container-form">
              <Formik
                initialValues={initialValues}
                validationSchema={UserEditForm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form autoComplete="off">
                    <Row>
                      <Col md={4} className="box-container-form-input">
                        <Form.Group>
                          <Form.Label>{t("editUser.name")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.first_name}
                            isInvalid={
                              formik.touched.first_name &&
                              formik.errors.first_name
                            }
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div
                              style={{
                                color: "#8F003C",
                              }}
                            >
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={4} className="box-container-form-input">
                        <Form.Group>
                          <Form.Label>{t("editUser.lastName")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.last_name}
                            isInvalid={
                              formik.touched.first_name &&
                              formik.errors.first_name
                            }
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div
                              style={{
                                color: "#8F003C",
                              }}
                            >
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={4} className="box-container-form-input">
                        <Form.Group>
                          <Form.Label>{t("editUser.email")}</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            isInvalid={
                              formik.touched.first_name &&
                              formik.errors.first_name
                            }
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div
                              style={{
                                color: "#8F003C",
                              }}
                            >
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} className="box-container-form-input">
                        <Form.Group>
                          <Form.Label>{t("editUser.role")}</Form.Label>
                          <Select
                            name="role"
                            options={roleList?.map((role) => ({
                              value: role.id,
                              label: role.attributes?.name,
                            }))}
                            onChange={(option) => {
                              const { value, label } = option;
                              setRoleChecked({ value, label });
                              formik.setFieldValue("role", value);
                            }}
                            isInvalid={!roleChecked && formik.touched.role}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                          {!roleChecked && formik.touched.role ? (
                            <div
                              style={{
                                color: "#8F003C",
                              }}
                            >
                              {t("errors.fieldrequired")}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={4} className="box-container-form-input">
                        <Form.Group>
                          <Form.Label>{t("editUser.title")}</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            autoComplete="title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            isInvalid={
                              formik.touched.first_name &&
                              formik.errors.first_name
                            }
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div
                              style={{
                                color: "#8F003C",
                              }}
                            >
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={4} className="box-container-form-input">
                        <Form.Group>
                          <Form.Label>{t("editUser.password")}</Form.Label>
                          <Form.Control
                            disabled
                            placeholder="Password will be generated automatically"
                            autoComplete="new-password"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="rowBottomBox">
                      <Col>
                        <h5 className="access-settings-portal-admin">
                          {t("editUser.accesssettings")}
                        </h5>
                        <h6 className="sections-reports-portal-admin">
                          {t("editUser.sectionsreports")}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form className="form-checkbox">
                          {dropdownSelectOptions?.data?.map(
                            ({ id, attributes: { name } }, index) => (
                              <Form.Check
                                type="checkbox"
                                label={name}
                                name={name}
                                value={id}
                                checked={sectionsAndReports?.includes(
                                  id.toString()
                                )}
                                onChange={handleCheck}
                                onBlur={formik.handleBlur}
                              />
                            )
                          )}
                        </Form>
                      </Col>
                    </Row>
                    <Row className="row-btns-userManagment">
                      <>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setShowAlert(true);
                          }}
                          className="btn style-btn-1 btn-cancel-coi"
                        >
                          {t("claimform.cancel")}
                        </button>
                        <Modal
                          show={showAlert}
                          onHide={handleCloseAlert}
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Body>{t("editUser.lostchanges")}</Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary btn-cancel-it"
                              onClick={() => handleCloseAlert()}
                            >
                              {t("editUser.cancel")}
                            </Button>
                            <Button
                              variant="primary btn-delete-it"
                              onClick={() => handleDelete()}
                            >
                              {t("editUser.discard")}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                      <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        className="btn btn-primary"
                      >
                        {t("editUser.saveChanges")}
                      </button>
                    </Row>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>{t("editUser.successCreate")}</Modal.Body>
                      <Modal.Footer>
                        <div
                          style={{
                            textDecoration: "none !important",
                            color: "#FFFFFF",
                            backgroundColor: "#8F033C",
                            width: "100px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                          className="link-userEdit"
                          onClick={() =>
                            navigate(`/${path}/portal-administration`)
                          }
                        >
                          Ok
                        </div>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={error}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>
                        User already exists with this email
                      </Modal.Body>
                      <Modal.Footer>
                        <div
                          style={{
                            textDecoration: "none !important",
                            color: "#FFFFFF",
                            backgroundColor: "#8F033C",
                            width: "100px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                          className="link-userEdit"
                          onClick={() =>
                            navigate(`/${path}/portal-administration`)
                          }
                        >
                          Ok
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Col>
    </div>
  );
};

export default CreateMember;
