import { useEffect, useState } from 'react';
import { getCarouselAutoScrollTime } from '../../services/actions/home.action';

const FALLBACK_SPEED = 4000;

const fetchAutoScrollTime = async () => {
  const { data: autoScrollTime } = await getCarouselAutoScrollTime();
  return autoScrollTime;
};

export const useCarouselSpeed = () => {
  const [carouselSpeed, setCarouselSpeed] = useState(FALLBACK_SPEED);

  useEffect(() => {
    const getSpeed = async () => {
      const autoScrollTime = await fetchAutoScrollTime();
      const speed = autoScrollTime > 0 ? autoScrollTime * 1000 : FALLBACK_SPEED;
      setCarouselSpeed(speed);
    };

    getSpeed();
  }, []);

  return carouselSpeed;
};
