import "../../../sass/layout/programs/programs.css";
import chevron from "../../../utils/img/chevron-right.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { ProgramCard } from "../programCard";
import { useEffect } from "react";
import { fetchProgramsDetails } from "../../../services/actions/programs.action";
import { useDispatch, useSelector } from "react-redux";

const ProgramRisk = () => {
  const { t } = useTranslation();

  const [showLeft, setShowLeft] = useState(false);

  const dispatch = useDispatch();

  const programs = useSelector((state) => state.programReducer.programsList);

  useEffect(() => {
    fetchProgramsDetails(dispatch, 'RM');
  }, [dispatch]);


  return (
    <Container fluid className="container-programs">
      <a
        className="btn-left"
        style={{
          position: "absolute",
          top: "6.5rem",
          outline: "none",
        }}
        onClick={() => {
          const element = document.getElementsByClassName("overflow-x");
          setShowLeft(false);
          element[0].scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        {showLeft && (
          <img
            src={chevron}
            style={{
              transform: "rotate(180deg)",
              width: "50px",
              zIndex: "100",
            }}
            alt="arrow"
          />
        )}
      </a>
      <a
        className="btn-right"
        style={{
          width: "50px",
          position: "absolute",
          top: "6.5rem",
          zIndex: "100",
          right: "0",
          paddingLeft: "2rem",
        }}
        onClick={() => {
          setShowLeft(true);
          const element = document.getElementsByClassName("overflow-x");
          const sleft = element[0].scrollLeft;
          element[0].scrollTo({
            top: 0,
            left: sleft + 400,
            behavior: "smooth",
          });
        }}
      >
        <img
          src={chevron}
          style={{
            width: "50px",
            zIndex: "100",
          }}
          alt="arrow"
        />
      </a>

      <h4 className="programs-title">
        <div>
          <strong> {t("riskmanagement.programs")}</strong>
        </div>
      </h4>
      <Row className="overflow-x">
        {programs.map(
          ({ name, image, link, tooltip }) => (
            <ProgramCard
              name={name}
              img={image}
              url={link}
              tooltip={tooltip}
            />
          )
        )}
      </Row>
    </Container>
  );
};

export default ProgramRisk;
