import {
  setClassesMeta,
  setDataOnlineClasses,
  setDetailWellnessClass,
} from "../reducers/wellnesseventsReducer";

import axios from "../api/HttpService";

const API_URL = process.env.REACT_APP_API_URL;

export const getOnlineClasses = async (dispatch, id, selectedClassId = null) => {
  try {
    if (!id) return;
    // If id is a number, return. Location id should be at least 5 characters long
    if (typeof id === 'number') {
      return;
    }
    if (id && id.length < 5) return; // If the id is not valid, return. Location id should be at least 5 characters long
    const online = await axios.get(
      API_URL + `api/classes/get-online-classes/${id}`
    );
    dispatch(setDataOnlineClasses({ classes: online.data.data }));
    dispatch(setClassesMeta({ wellnessClassesMeta: online.data.meta }));
    if (selectedClassId) {
      const selectedClass = online.data.data.find((c) => c.ID === selectedClassId);
      dispatch(setDetailWellnessClass({ wellnessClassSelected: selectedClass }));
    }
  } catch (error) {}
};
