/* eslint-disable jsx-a11y/anchor-is-valid */
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import { downloadFile } from "../../services/download/downloadFile";
import Spinner from "react-bootstrap/Spinner";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props}
  </Tooltip>
);

export const InternalLink = ({
  index,
  isLast,
  name,
  firstLevel,
  location,
  default_link,
  locationPath,
}) => {
  return (
    <li
      key={index}
      className={isLast ? "accordion-divider" : "list-unstyled"}
    >
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(name)}
        onEntering={(e) => {
          e.children[0].style.backgroundColor = "#ffffff";
          e.children[0].style.color = "#000000";
          e.children[1].style.backgroundColor = "#ffffff";
          e.children[1].style.color = "#000000";
        }}
        className="accordion-tooltip"
      >
        <Link
          className={`dropdown-item ${firstLevel ? "first-level-link" : ""}`}
          to={
            default_link
              ? `/${locationPath}/${default_link}`
              : `/${locationPath}`
          }
          key={index}
          onClick={() => {
            Mixpanel.track("Dropdowns.View", {
              DropdownName: name,
              locationName: location?.name,
            });
          }}
        >
          {name}
        </Link>
      </OverlayTrigger>
    </li>
  );
};

export const ExternalLink = ({
  index,
  isLast,
  name,
  firstLevel,
  location,
  default_link,
  default_type,
  refreshSASToken,
  default_file,
  setLink,
}) => {
  const buildLink = () => {
    if (default_type === "external_link") {
      return default_link;
    } else if (default_type === "email") {
      return `mailto:${default_link}`;
    } else if (default_type === "file") {
      return "#";
    }
  };

  return (
    <li key={index} className={isLast ? "accordion-divider" : "list-unstyled"}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(name)}
        onEntering={(e) => {
          e.children[0].style.backgroundColor = "#ffffff";
          e.children[0].style.color = "#000000";
          e.children[1].style.backgroundColor = "#ffffff";
          e.children[1].style.color = "#000000";
        }}
        className="accordion-tooltip"
      >
        <a
          href={buildLink()}
          onClick={() => {}}
          onMouseDown={
            default_type === "file"
              ? () => {
                  Mixpanel.track("Dropdowns.view", {
                    DropdownName: name,
                    locationName: location?.name,
                  });
                  refreshSASToken(default_type, default_file?.url);
                }
              : () => {
                  Mixpanel.track("Dropdowns.view", {
                    DropdownName: name,
                    locationName: location?.name,
                  });
                  setLink(null);
                }
          }
          className={`dropdown-item ${firstLevel ? "first-level-link" : ""}`}
          target={buildLink() !== "#" ? "_blank" : undefined}
          rel="noopener noreferrer"
        >
          {name}
        </a>
      </OverlayTrigger>
    </li>
  );
};

export const FTPLink = ({
  id,
  parentIndex,
  index,
  isLast,
  name,
  firstLevel,
  default_category,
  report_period = "current",
  setItemLoading,
  navigate,
  locationPath,
  itemLoading,
  location,
}) => {
  const identifier = `${parentIndex}-${id}`;
  const category = default_category +  `?report_type=${report_period}`;


  return (
    <li key={index} className={isLast ? "accordion-divider" : "list-unstyled"}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(name)}
        onEntering={(e) => {
          e.children[0].style.backgroundColor = "#ffffff";
          e.children[0].style.color = "#000000";
        }}
        className="accordion-tooltip"
      >
        <a
          href="#"
          className={`dropdown-item ${firstLevel ? "first-level-link" : ""}`}
          rel="noopener noreferrer"
          onClick={() => {
            Mixpanel.track("Dropdowns.View", {
              DropDownName: name,
              locationName: location?.name,
            });
            downloadFile(
              category,
              identifier,
              setItemLoading,
              navigate,
              locationPath
            );
          }}
        >
          {name}
          {itemLoading[identifier] && <Spinner animation="grow" size="sm" />}
        </a>
      </OverlayTrigger>
    </li>
  );
};

export const TableLink = ({
  index,
  isLast,
  name,
  location,
  firstLevel,
  locationPath,
  id,
}) => {
  return (
    <li key={index} className={isLast ? "accordion-divider" : "list-unstyled"}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(name)}
        onEntering={(e) => {
          e.children[0].style.backgroundColor = "#ffffff";
          e.children[0].style.color = "#000000";
        }}
        className="accordion-tooltip"
      >
        <Link
          className={`dropdown-item ${firstLevel ? "first-level-link" : ""}`}
          to={`/${locationPath}/optionlist/${id}`}
          key={index}
          onClick={() =>
            Mixpanel.track("Dropdowns.View", {
              DropDownName: name,
              locationName: location?.name,
            })
          }
        >
          {name}
        </Link>
      </OverlayTrigger>
    </li>
  );
};

export const Title = ({ index, name }) => {
  return (
    <li key={index} 
    style={{listStyleType: "none"}}
    >
      <span className="accordion-item-title">{name}</span>
    </li>
  );
};
