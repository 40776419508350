import "../sass/layout/features-news/features-news.css";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Mixpanel } from "../utils/mixpanelIntegration";
import UserService from "../services/api/UserService";
import { useEffect } from 'react';
import { getFeaturedNews } from '../services/actions/home.action';

const FeaturedNews = () => {
  const news = useSelector((state) => state.homeReducer.featuredNews?.data);
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const currentLocation = useSelector((state) => state.homeReducer.location?.data);
  const dispatch = useDispatch();

  useEffect(()=>{
    getFeaturedNews(dispatch);
  }, [dispatch])

  return (
    <Container fluid className="featured-news-container">
      <Row className="row-news">
        {news?.map((item, index) => {
          return (
            <Col
              key={index}
              className="col-news"
              onClick={() =>
                Mixpanel.track("Featured News Clicked", {
                  isLogged: UserService.isLoggedIn(),
                  "News Title": item.attributes.title,
                  locationName: currentLocation?.name,
                })
              }
            >
              <a target="_blank" href={item.attributes?.mma_url}>
                <div id={item.id} className="news-container">
                  <img
                    src={
                      item.attributes?.image?.data?.attributes?.url +
                      "?" +
                      tokenMedia
                    }
                    alt="news"
                    className="news-image"
                  />
                  <div className="news-content">
                    <h4 className="news-head">
                      {item.attributes.category_name}
                    </h4>
                    <h3 className="news-title">{item.attributes.title}</h3>
                    <div className="news-description">
                      {item.attributes.description}
                    </div>
                  </div>
                </div>
              </a>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default FeaturedNews;
