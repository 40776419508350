export const CERTIFICATES_OF_INSURANCES =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FChelmsford%20Certificate%20FY23%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford";
export const CONTRACTS =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FContract%20FY22%2FChelmsford%2021%2D22%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FContract%20FY22";
export const DECLARATION_PAGES =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FContract%20FY22%2FChelmsford%2021%2D22%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FContract%20FY22";
export const ENDORSEMENT_ACTIVITY =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/Member%20Endorsement%20Invoices/FY23%20October%20Endorsements%20-%20Current/Chelmsford.pdf?csf=1&web=1&e=X6e6Eu";
export const PREMIUM_BREAKDOWN = "";
export const ANCIALIARY_POLICY_SUMMARY =
  "https://miiaqa2.blob.core.windows.net/public-for-portal-qa/assets/Need Content.pdf";
export const SCHEDULES =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FContract%20FY22%2FChelmsford%2021%2D22%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FContract%20FY22";
export const COVERAGE_RECS =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/FY23%20MIIA%20Policy%20Forms/FY23%20PolicyCoverage%20documents.pdf?csf=1&web=1&e=ekbR5c";

export const WORKERS_COMPENSANTION =
  "https://www.emiia.org/my-miia/report-a-claim/workers-compensation";
export const PROPERTY_LIABILITY = "";

export const CYBER_LIABILITY =
  "https://www.emiia.org/my-miia/report-a-claim/cyber-breach";
export const PROFFESIONAL_LIABILITY_SCHOOL_BOARD =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/P%26L%20Forms/SB%20Prof%20App%20Fillable.pdf?csf=1&web=1&e=TORYxy";
export const PROFFESIONAL_LIABILITY_PUBLIC_OFFICIALS =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/P%26L%20Forms/PO%20Prof%20App%20Fillable.pdf?csf=1&web=1&e=tLKw4B";
export const PROFFESIONAL_LIABILITY_LAW_ENFORCEMENT =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/P%26L%20Forms/LE%20Prof%20App%20Fillable.pdf?csf=1&web=1&e=VTZOQq";

export const AUTO_CHANGE_REQUEST =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/P%26L%20Forms/Auto%20Change%20Form%20Fillable.pdf?csf=1&web=1&e=a9BJbO";
export const AUTO_CHANGE_REQUEST_TRANSFER_DEPT =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/P%26L%20Forms/Auto%20Transfer%20Departments%20Form%20Fillable.pdf?csf=1&web=1&e=Qa5sAz";
export const EQUIPMENT_CHANGE_REQUEST =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/P%26L%20Forms/Equipment%20Change%20Form%20Fillable.pdf?csf=1&web=1&e=moehB9";

export const INJURED_ON_DUTY =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/MIIA%20Police%20and%20Fire%20Program_2022%20(1).pdf?csf=1&web=1&e=AH9z3X";

export const DECLARATION_PAGES_WC =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/Declaration%20Pages/WC%20and%20EL%20Declaration%20page_Chelmsford.pdf?csf=1&web=1&e=DiNvRH";
export const COVERAGE_FORMS_WC =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/FY23%20MIIA%20Policy%20Forms/FY23%20MIIA%20Policy%20Forms/MWC%20001%20Workers%20Comp%20Coverage%20form.pdf?csf=1&web=1&e=QpJq4F";
export const WORKERS_COMP_POSTING_NOTICE =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/FY23%20WC%20Posting/Chelmsford.pdf?csf=1&web=1&e=FfZK4h";

export const UNEMPLOYEMENT = "";

export const BILLING = "";
export const MEMbER_STATEMENTS =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FBilling%2FChelmsford%20Statements%20FY2022%2006%2E22%2E22%2D2%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FBilling";
export const ENDORSEMENT_ACTIVITY_BILLING = "";
export const INVOICES =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FBilling%2FChelmsford%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FBilling";
export const REWARDS_STATUS_REPORTS =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FRisk%20Management%20Docs%2Fchelmsford%20fy22%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FRisk%20Management%20Docs";

export const OSHA_CHEKCLIST = "";
export const OSHA_FORM_LOG = "";
export const OSHA_FORM_SUMMARY = "https://www.emiia.org/risk-management-resources/osha-municipal-cert-programs";

export const FNP_LINK = "https://portal.firstnonprofitcompanies.com/webview/";
export const UTMC_LINK = "https://cms.utmcorp.com/#/login";
export const SUPPORTING_FORMS =
  "https://www.emiia.org/health-dental-and-vision-insurance/helpful-resources";
export const UNEMPLOYEMENT_ACTION =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FExamples%20of%20content%2F060322%5FFINAL%20Unemployment%20Participation%20Application%20%20v%2E17%205%2D31%2D22%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FExamples%20of%20content";

export const FIRST_REPORT_AUTO =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/MIIA%20First%20Report%20-%20Automobile%20Claim%20-%20Fillable%20Form%20(1).pdf?csf=1&web=1&e=nKayAB";
export const FIRST_REPORT_PROPERTY =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/MIIA%20First%20Report%20-%20Property%20Claim%20-%20Fillable%20Form%20(1).pdf?csf=1&web=1&e=OnACjx";
export const FIRST_REPORT_GENERAL =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/MIIA%20First%20Report%20-%20General%20Liability%20Claim%20-%20Fillable%20Form%20(1).pdf?csf=1&web=1&e=FeOiaN";

export const CERT_INVOICES_CURRENT =
  "https://mmaorg.sharepoint.com/:u:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/Renewal%20Packets%20FY23/Renewal%20Packets%20FY23.zip?";
export const ENDORSEMENT_ACTIVITY_CURRENT =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/Member%20Endorsement%20Invoices/FY23%20October%20Endorsements%20-%20Current/Chelmsford.pdf?csf=1&web=1&e=X6e6Eu";
export const ENDORSEMENT_ACTIVITY_PREVIOUS =
  "https://mmaorg.sharepoint.com/:f:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/Member%20Endorsement%20Invoices/FY23%20September%20Endorsements%20-%20Previous?csf=1&web=1&e=xPkoD2";

export const WC_AUDIT_ACTIVITY_CURRENT =
  "https://mmaorg.sharepoint.com/:f:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/WC%20Audit%20Activity/WC%20Audits-Current?csf=1&web=1&e=U9nebJ";
export const WC_AUDIT_ACTIVITY_PREVIOUS =
  "https://mmaorg.sharepoint.com/:f:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/WC%20Audit%20Activity/WC%20Audit-Previous?csf=1&web=1&e=ythrh0";

export const REGISTER_TRAINING =
  "https://www.emiia.org/risk-management-resources/training/2022/8#filter";
export const REQUEST_TRAINING =
  "https://www.emiia.org/risk-management-resources/schedule-a-training";
export const RECORDED_TRAINING =
  "https://www.emiia.org/risk-management-resources/recordings-handouts";

export const GRANTS_RM_FLEX =
  "https://www.emiia.org/risk-management-resources/grant-program";
export const ADVISORY_COMMUNICATION =
  "https://www.emiia.org/risk-management-resources/communications-archive";
export const BEST_PRACTICES_AND_RESOURCES =
  "https://www.emiia.org/risk-management-resources/best-practices-and-resources";
export const REWARDS =
  "https://www.emiia.org/risk-management-resources/rewards";
export const SREVICE_PLAN =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FRisk%20Management%20Docs%2FChelmsfordFY22MIIA%20Risk%20Management%20Survey%20Plan%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FRisk%20Management%20Docs";
export const UPDATE_FLEET_SCHEDULES =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FWC%205%2E31%2E22%2FWorkers%20Compensation%20OSHA%20300A%5F2022%2D05%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FWC%205%2E31%2E22";

export const LOGOUV = "https://www.localgovu.com/miia/";
// export const ENQUIRON = "https://login.enquiron.com/miiahrresourcecenter";
export const ENQUIRON =
  "https://www.emiia.org/risk-management-resources/enquiron";

export const AGILITY_PREPAREDNESS =
  "https://www.emiia.org/risk-management-resources/disaster-recovery";
export const EMPLOYEE_ASSISTANCE = "https://myassistanceprogram.com/miia-eap/";
// export const CYBERNET = "https://emiia.nascybernet.com/index.php";
export const CYBERNET =
  "https://www.emiia.org/cyber-liability-protection/coverage-overview";
export const DRUG_AND_ALCOHOL =
  "https://www.emiia.org/risk-management-resources/19/view-additional-resource?title=Drug+%26amp%3B+Alcohol+Testing";
export const MFF_SPONSOR =
  "https://www.emiia.org/risk-management-resources/231/view-additional-resource?title=MFAA+Sponsorship+Program";

export const LOSS_RUNS =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FWorkers%20Compensation%20Claim%20Summary%5F2022%2D03%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford";
export const WC_CHECK_REGISTER =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FWorkers%20Compensation%20Check%20Register%5F2022%2D03%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford";
export const WC_SUMARRY =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FWorkers%20Compensation%20Claim%20Summary%5F2022%2D03%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford";

export const EKIT =
  "https://planinfo.bluecrossma.com/customblue/2021/miiatownofchelmsford";
export const BLUE_CROSSES_SHIELD = "https://www.bluecrossma.org/";

export const ENROLL_TRAINING =
  "https://www.brainshark.com/1/player/bcbsma?fb=0&r3f1=ead0aefdf1fca4b6e6abd4bbe7e8e1f8f6e7b9d4afece2fdf1eabab6d9f4eaeee2b1&custom=miia_enrollment_ekit";

export const MIIA_WINNERS = "https://www.miiawinners.org/";

export const WORKERS_COMP_CHECK_SUMMARY =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FWC%205%2E31%2E22%2FWorkers%20Compensation%20Claim%20Summary%5F2022%2D05%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FWC%205%2E31%2E22";
export const WORKERS_COMP_CHECK_REGISTER =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FWorkers%20Compensation%20Check%20Register%5F2022%2D05%2D31%5F56%5FRedacted%20%281%29%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford";
export const PROPERTY_LIABILITY_SUMMARY =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FP%26C%203%2E31%2E22%2FProperty%20%26%20Liability%20Summary%20By%20Coverage%5F2022%2D03%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FP%26C%203%2E31%2E22";
export const PROPERTY_LIABILITY_DEBT =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FP%26C%203%2E31%2E22%2FProperty%20%26%20Liability%20Summary%20By%20Department%5F2022%2D03%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FP%26C%203%2E31%2E22";
export const PROFESIONAL_LIABILITY_SUMMARY =
  "https://mmaorg.sharepoint.com/sites/MemberPortalCollaboration/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FP%26C%203%2E31%2E22%2FProfessional%20Period%20Summary%28Member%20Version%29%5F2022%2D03%2D31%5F56%2Epdf&parent=%2Fsites%2FMemberPortalCollaboration%2FShared%20Documents%2FChelmsford%2FLoss%20Runs%2FChelmsford%20Example%2FP%26C%203%2E31%2E22";
export const WELLNESS_GRANT_PROGRAM =
  "https://mmaorg.sharepoint.com/:b:/r/sites/MemberPortalCollaboration/Shared%20Documents/Content/introducing_miia_wellness_grant_2023f_8.22.22.pdf?";




  export const EXTERNAL_LINK = 'external_link'
  export const INTERNAL_LINK = 'internal_link'


  // Risk Management
  
  export const REGISTER_FOR_A_TRAINING = 'https://www.emiia.org/risk-management-resources/training/'
