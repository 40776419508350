import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
  reportsView: [],
};

export const reportsReducer = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReports: (state, action) => {
      state.reports = action.payload.reportsView;
    },
    getDownloadReports: (state, action) => {
      state.reportsView = action.payload.downloadReports;
    },
  },
});

export const { getReports, getDownloadReports } = reportsReducer.actions;

export default reportsReducer.reducer;
