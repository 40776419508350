import { useState, useEffect } from 'react';
import { paginate, populatePagination } from '../utils/pagination';
import { useDispatch } from 'react-redux';

const usePagination = (fetchPaginatedData, meta, options = {}) => {
  const { id, max = 8 } = options;
	const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [paginationParams, setPaginationParams] = useState({
    current: 1,
    max,
  });
	
	const onClickPage = (number) => {
		setPaginationParams((prev) => ({
			...prev,
			current: number,
		}));
	};

	useEffect(() => {
    setLoading(true);
    const { current: page, max: pageSize } = paginationParams;
    fetchPaginatedData(dispatch, page, pageSize, id);
  }, [dispatch, paginationParams.current]);

  useEffect(() => {
    const paginateResult = paginate({
      current: paginationParams?.current,
      max: meta?.pagination?.pageCount,
    });

    if(!paginateResult) return;

    const paginationList = populatePagination(
      paginateResult?.items,
      onClickPage,
      paginationParams?.current,
    );

    setItems(paginationList);
  }, [paginationParams.current, meta?.pagination?.pageCount]);

  useEffect(() => {
    setLoading(false);
  }, [meta?.pagination?.page]);

	return { items, paginationParams, onClickPage, loading };
};

export default usePagination;