import { useTranslation } from "react-i18next";
import "../../sass/layout/recipeContainer/recipeContainer.css";
import { Col, Container, Row } from "react-bootstrap";
import { FiPrinter } from "react-icons/fi";
import ReactMarkdown from "react-markdown";

const RecipeContent = ({ handlePrint, recipe }) => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row className="recipe-content-detail">
        <Col sm={7} className="recipe-left">
          <h2>{recipe?.name}</h2>
          <p>
            <b>{t("recipe.instructions")}</b>
          </p>
          <ReactMarkdown children={recipe?.instructions} />
          {recipe?.source && (
            <p>Source: {recipe?.source}</p>
          )}
        </Col>
        <Col className="recipe-right">
          <Container fluid className="box-print" onClick={handlePrint}>
            <FiPrinter />
            <b className="label">{t("recipe.printThisRecipe")}</b>
            <div></div>
          </Container>
          <Container fluid className="box-data">
            <h5>{t("recipe.ingredients")}</h5>
            <ReactMarkdown children={recipe?.ingredients} />
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default RecipeContent;
