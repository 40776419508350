const roleEqualTo = (userDetail, value) => {
	return userDetail?.portal_user_custom_entitlements_user_administration === value;
}

const permissionNotSetted = (userDetail) => {
	return !userDetail?.portal_user_custom_entitlements_user_administration;
}

export const hasUserAdministrationPermission = (userDetail) => {
	const rolePermission = userDetail?.portal_user_role?.portal_user_administration;

	if (roleEqualTo(userDetail, "ENABLED")) return true;
	else if (roleEqualTo(userDetail, "DISABLED")) return false;
	else if(roleEqualTo(userDetail, "NONE") || permissionNotSetted(userDetail) ) return rolePermission;
}