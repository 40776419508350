import "../../sass/layout/paginator/paginator.css";
import Pagination from "react-bootstrap/Pagination";

const Paginator = ({ items, onClickPage, filterParams, metadata }) => {
  return (
    <Pagination
      className="pagination-container"
      onClick={() =>
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      }
    >
      <Pagination.First onClick={() => onClickPage(1)} />
      <Pagination.Prev
        onClick={() =>
          onClickPage(filterParams?.current > 1 ? filterParams?.current - 1 : 1)
        }
      />
      {items}
      <Pagination.Next
        onClick={() =>
          onClickPage(
            filterParams?.current < metadata?.pagination?.pageCount
              ? filterParams?.current + 1
              : metadata?.pagination?.pageCount
          )
        }
      />
      <Pagination.Last
        onClick={() => onClickPage(metadata?.pagination?.pageCount)}
      />
    </Pagination>
  );
};

export default Paginator;