import React from "react";
import { Col, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Tooltip from "react-bootstrap/Tooltip";
import { useSelector } from "react-redux";

export const ProgramCard = ({ url, img, name, classOverride, tooltip }) => {
  const { t } = useTranslation();
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    {tooltip}
    </Tooltip>
  );

  return (
    <Col className="columna">
      <a
        href={url.includes("http") ? url : "https://" + url}
        target="_blank"
        className={classOverride ?? "columna-box-risk"}
        rel="noreferrer"
      >
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          id="localGov"
          name="localGov"
          overlay={renderTooltip}
        >
          <img className="img-fluid" src={img + "?" + tokenMedia}
           alt={name} />
        </OverlayTrigger>
      </a>
    </Col>
  );
};
