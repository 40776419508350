import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changeLocation,
  getLocations,
} from "../../services/actions/home.action";

function DropdownLocation() {
  const navigate = useNavigate();
  const locationsData = useSelector((state) => state.homeReducer.locations);
  const userDetail = useSelector((state) => state.userReducer.userDetail);
  const dispatch = useDispatch();
  const locations = locationsData?.data?.filter(
    (location) => location.title !== null
  );

  useEffect(() => {
    getLocations(dispatch);
  }, [dispatch]);

  const locationSelected = async (uniqueNonGuessableId) => {
    await changeLocation(dispatch, uniqueNonGuessableId, navigate);
  }

  const filterLocations = (locations, value) =>
    locations?.filter(
      (location) =>
        !value ||
        location.title?.toLowerCase().startsWith(value.toLowerCase()) ||
        location.type?.toLowerCase().startsWith(value.toLowerCase())
    ) || [];

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      style={{
        height: "25px",
        width: "140px",
        padding: "0",
        overflow: "hidden",
        position: "relative",
        display: "inline-block",
        margin: "0 5px 0 5px",
        textAlign: "center",
        textDecoration: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: "#FFFFFF",
        backgroundColor: "#000000",
        alignContent: "center",
        marginTop: "5px",
        fontSize: "12px",
        lineHeight: "25px",
        padding: "0 10px",
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <Form.Control
            className="mx-3 my-2 w-auto search-input-role"
            placeholder="Search..."
            onChange={(e) => {
              setValue(e.target.value);
            }}
            key={value.id}
            value={value}
          />
          <ul className="list-unstyled">
            {filterLocations(locations, value)?.length === 0 && (
              <Dropdown.Item
                eventKey="0"
                disabled
                style={{ textAlign: "center" }}
              >
                No results found
              </Dropdown.Item>
            )}
            {filterLocations(locations, value)?.map((location) => (
              <div
                onClick={async (e) => {
                  if (!e.target.id) return;
                  locationSelected(e.target.id);
                }}
                key={location.uniqueNonGuessableId}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Dropdown.Item
                  id={location.uniqueNonGuessableId}
                  eventKey={location.uniqueNonGuessableId}
                >
                  <>{location.title}</>
                </Dropdown.Item>
                <label
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    textAlign: "right",
                    color: "grey",
                    paddingRight: "1rem",
                  }}
                >
                  <>{location.type}</>
                </label>
              </div>
            ))}
          </ul>
        </div>
      );
    }
  );
  return (
    <Dropdown autoClose="outside" className="dropdownRole">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {userDetail?.location?.title || "Select Location"}
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          width: "491px",
          overflowY: "scroll",
          height: "20rem",
          marginTop: "0.7rem",
          maxHeight: "306px",
        }}
        as={CustomMenu}
      >
        {locations?.map((location) => (
          <Dropdown.Item eventKey={location?.uniqueNonGuessableId}>
            {location?.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownLocation;
