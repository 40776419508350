import { Container } from "react-bootstrap";
import RetireesItem from "./retireesItem";
import "../../sass/layout/retireesContainer/retireesContainer.css";

const RetireesContainer = () => {
    return (
        <Container fluid>
            <RetireesItem />
            <RetireesItem />
            <RetireesItem />
        </Container>
    );
};

export default RetireesContainer;
