// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
  display: flex;
  justify-content: flex-end !important;
  padding: 2em;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/paginator/paginator.scss","webpack://./src/sass/layout/paginator/paginator.css"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,oCAAA;EACA,YAAA;ACCD","sourcesContent":[".pagination-container{\n\tdisplay: flex;\n\tjustify-content: flex-end !important;\n\tpadding: 2em;\n}",".pagination-container {\n  display: flex;\n  justify-content: flex-end !important;\n  padding: 2em;\n}/*# sourceMappingURL=paginator.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
