import { useEffect, useState } from "react";
import "../sass/layout/featuredRecipes/featuredRecipes.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { recipeDetail } from "../services/reducers/recipeReducer";
import { fetchInitialRecipes } from "../services/actions/recipes.action";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imgPlaceholder from "../utils/img/imgPlaceholder.png";

const FeaturedRecipes = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [clicked, setClicked] = useState(0);
  const [showData, setShowData] = useState(0);
  const [showRecipes, setShowRecipes] = useState();

  const recipes = useSelector((state) => state.recipeReducer.recipeshome);
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);

  useEffect(() => {
    fetchInitialRecipes(dispatch);
  }, []);

  useEffect(() => {
    if (recipes?.length > 0) {
      setShowRecipes(recipes?.slice(0, 4));
    }
  }, [recipes]);

  useEffect(() => {
    if (showRecipes?.length > 0) {
      setShowData(showRecipes[clicked]);
    }
  }, [clicked, showRecipes]);

  const onClickRecipe = () => {
    navigate({
      pathname: `recipe/${showRecipes[clicked]?.id}`,
    });
    dispatch(recipeDetail({ recipeSelected: showRecipes[clicked] }));
  };

  const style = {
    backgroundImage: showData?.attributes?.image?.data?.attributes?.url
      ? `url(${
          showData?.attributes?.image?.data?.attributes?.url + "?" + tokenMedia
        })`
      : `url(${imgPlaceholder})`,

    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <Container fluid className="box-container">
      <Container fluid className="titles-box">
        <h4 className="title-featured-recipe">
          {t("homepage.featuresrecipes")}
        </h4>
        <Link to="recipeslist">
          <span className="span-recipes">{t("homepage.seeallrecipes")}</span>
        </Link>
      </Container>
      <div className="featuredRecipes-container">
        <div
          style={style}
          className="featuredRecipes-image"
          onClick={onClickRecipe}
        ></div>
        <div onClick={onClickRecipe} className="featuredRecipes-text">
          <h5>
            <strong>{showData?.attributes?.name}</strong>
          </h5>
          <p className="description-recipe">
            {showData?.attributes?.description}
          </p>
        </div>
        <div className="featuredRecipes-pagination-card">
          {showRecipes?.map((element, index) => {
            return (
              <button
                key={element?.id}
                value={element?.id}
                onClick={() => setClicked(index)}
                className="featuredRecipes-btns"
                style={clicked == index ? { backgroundColor: "#1A1919" } : {}}
              ></button>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default FeaturedRecipes;
