import moment from "moment";

export const formatTime = (time) => {
  if (time) {
    const arrayTimes = time.split(",");
    return arrayTimes[0];
  }
};

export const formatDate = (date) => {
  const day = moment(date).format("DD");
  const month = moment(date).format("MMM");
  return { month_: month, day_: day };
};

export const formatDateDetail = (date) => {
  const day = moment(date).format("DD");
  const month = moment(date).format("MMM");
  return month + " " + day;
};

export const formatLongDate = (date) => {
  return moment(date).format("MMMM DD, YYYY");
}

export const formatMonthAndDay = (date) => {
  return moment(date).format("MMM DD");
}

export const getMonth = (date) => {
  return moment(date).format("MMM");
}

export const wellness = {
  CATEGORY: "Category",
  TITLE_EVENT: "Program Name",
  DESCRIPTION_PROGRAM: "Program Description",
  EXTRA_INSTRUCTIONS: "Extra Instructions",
  SERVICE_PROVIDER: "Service Provider",
  CLASS_SIZE_LIMIT: "Class Size Limit",
  TIME: "Time",
  LOCATION: "Location",
  LOCATION_INSTRUCTIONS: "Location Instructions",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  DESCRIPTION: "Description",
  REGISTRATION_INFO: "Registration Info",
  WHO_CAN_ATTEND: "Who can attend",
  PROGRAM_STATUS: "Program Status",
  ID: "ID",
  ID_: " ID",
};
