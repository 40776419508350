import "../../sass/layout/menuPath/menuPath.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePath } from "../../hooks/usePath";
import { useSelector } from "react-redux";

const MenuPath = ({ name, nameItem, onClickItem }) => {
  const [pathsMenu, setPathMenu] = useState();
  const locationId = useSelector((state) => state.homeReducer.location);
  let location = useLocation();
  let navigate = useNavigate();

  const path = usePath();
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  useEffect(() => {
    if (location) {
      const paths = location?.pathname?.split("/");
      setPathMenu(paths);
    }
  }, [location]);

  return (
      <div className="path-title">
        <div
          onClick={() => {
            id !== "/"
              ? navigate(`/${id ?? ""}`)
              : navigate("/");
          }}
          className="path-item"
        >
          Home
        </div>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;{">"}&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <span className="path-item" onClick={onClickItem}>
          {name}
        </span>
        {nameItem && (
          <>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;{">"}&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span>{nameItem}</span>
          </>
        )}
      </div>
  );
};

export default MenuPath;
