import axios from "../api/HttpService";
import {
  setArticlesHome,
  setArticlesList,
  setArticlesMeta,
  setErrorArticles,
} from "../reducers/articleReducer";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchInitialArticles = async (dispatch) => {
  try {
    axios.get(API_URL + `api/articles?populate=image&sort[0]=order:desc
    `).then((response) => {
      dispatch(setArticlesHome({ articleshome: response.data.data }));
    });
  } catch (error) {
    dispatch(setErrorArticles({ errorArticles: error.response.data }));
  }
};

export const fetchArticles = async (dispatch, page, pageSize) => {
  try {
    axios
      .get(
        API_URL +
          `api/articles?populate=image&pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort[0]=order:desc`
      )
      .then((response) => {
        dispatch(setArticlesList({ articlesList: response.data.data }));
        dispatch(setArticlesMeta({ articlesMeta: response.data.meta }));
      });
  } catch (error) {
    dispatch(setErrorArticles({ errorArticles: error.response.data }));
  }
};

export const fetchArticleById = async (id) => {
  try {
    const article = await axios.get(API_URL + `api/articles/${id}`);
    return article;
  } catch (error) {
    console.log(error);
    return { data: null };
  }
};
