import "./i18n";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import { Provider } from "react-redux";
import AppRoutes from "./routes/routes";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./services/api/StoreService";
import Layout from "./components/layout/layout";

const App = ({ store }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider
            breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
            minBreakpoint="xxs"
          >
            <Layout>
              <AppRoutes />
            </Layout>
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
