import "../sass/layout/redNavbar/redNavbar.css";
import man from "../utils/img/man.png";
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdOutlineSearch } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { getSearch } from "../services/actions/search.action";

const RedNavbar = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [lenguage, setLenguage] = useState(true);
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    dispatch(getSearch(search));
  };

  return (
    <Container fluid className="redNavbar-container">
      <Col className="redNavbar-buttons">
        <Row className="row-container">
          <InputGroup bsPrefix="input-search-form-group">
            <Form.Control
              bsPrefix="input-search-form"
              placeholder={t("homepage.search")}
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputGroup.Text bsPrefix="input-search-icon" id="basic-addon1">
              <NavLink
                onClick={(e) => {
                  if (search === "") {
                    e.preventDefault();
                  } else {
                    handleSearch();
                  }
                }}
                to="/searchresults"
              >
                <MdOutlineSearch className="icon-search" />
              </NavLink>
            </InputGroup.Text>
          </InputGroup>
          <button className="btn-body">
            <img src={man}></img>
          </button>
          <button
            onClick={() => {
              setLenguage(!lenguage);
              i18n.changeLanguage(lenguage ? "en" : "es");
            }}
            className="btn-body"
          >
            <label>EN</label>
          </button>
        </Row>
      </Col>
    </Container>
  );
};

export default RedNavbar;
