// import "bootstrap/dist/css/bootstrap.min.css";
import MMALogo from "../utils/img/MMA-logo.png";
import { useTranslation } from "react-i18next";
import "../sass/layout/navbar/navbar.css";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";

import { ListGroup, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserService from "../services/api/UserService";
import { useSelector } from "react-redux";
import AccordionProfile from "./accordion/accordion";
import QuickAccessItem from "./quickaccess/quickaccessitem";

function QuickAccessMobileNav({ handleMenuToggle, isQAMenuOpen }) {
  const hasProduct = (requiredProduct, purchasedProducts) =>
    purchasedProducts?.find((product) => product === requiredProduct);

  const { t } = useTranslation();
  const { location, myUserInfo, quickAccessLinks } = useSelector((state) => ({
    quickAccessLinks: state.homeReducer.quickaccess,
    location: state.homeReducer.location?.data,
    myUserInfo: state.homeReducer.myUserInfo,
  }));

  const id = location?.uniqueNonGuessableId;
  const products = location?.purchasedProduct?.map(
    (item) => item?.product?.name
  );

  const loginStatus = UserService.isLoggedIn();

  const isBasePath = () =>
    !!(window.location.pathname === "/" || !id || id === "/" || !location);

  return (
    <Navbar expand="lg" className="quick-access-mobile">
      <Navbar.Brand
        style={{
          backgroundColor: "#ffffff",
          marginLeft: "1rem",
          fontSize: "1.1rem",
          fontWeight: "bold",
        }}
        href="#home"
      >
        Quick Access
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav-qa-mobile"
        onClick={handleMenuToggle}
        className="toggler-custom-mobile"
        children={
          !isQAMenuOpen ? (
            <GoTriangleDown
              style={{
                fontSize: "0.7rem",
              }}
            />
          ) : (
            <GoTriangleUp
              style={{
                fontSize: "0.7rem",
              }}
            />
          )
        }
      />
      <Navbar.Collapse id="responsive-navbar-nav-qa-mobile">
        <Nav
        className="quick-access-mobile-nav"
        >
          {loginStatus ? (
            <div>
              <AccordionProfile />
            </div>
          ) : (
            <>
              <Nav.Item
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              >
                <a
                  href="https://myassistanceprogram.com/miia-eap/"
                  target="_blank"
                  rel="noreferrer"
                  className="quick-access-subtitle-mobile"
                >
                  MIIA Employee Assistance Program
                </a>
              </Nav.Item>

              {false ? (
                <AccordionProfile />
              ) : (
                <>
                  {isBasePath() ? (
                    <div
                    style={{
                      width: "85%",
                      alignSelf: "center",
                    }}
                    >
                      <Nav.Link
                        className="quick-access-mobile-link"
                        href="/articleslist"
                      >
                        {t("homepage.articles")}
                      </Nav.Link>
                      <Nav.Link
                        className="quick-access-mobile-link"
                        href="/recipeslist"
                      >
                        {t("homepage.recipes")}
                      </Nav.Link>
                      <Nav.Link
                        className="quick-access-mobile-link"
                        href="https://www.emiia.org/contact"
                      >
                        {t("homepage.contact2")}
                      </Nav.Link>
                    </div>
                  ) : (
                    hasProduct("Health", products) && (
                      <>
                        <ul className="quickAccess-ul list-unstyled">
                          {quickAccessLinks?.map((itemLink, index) => {
                            return (
                              <QuickAccessItem
                                isMobile={true}
                                key={index}
                                itemquickacces={itemLink}
                              />
                            );
                          })}
                        </ul>
                      </>
                    )
                  )}
                </>
              )}
              <div 
              style={{
                marginTop: "1.9rem",
                borderTop: "1px solid #e5e5e5",
                display: "flex",
                justifyContent: "center",
              }}
              >
                <div className="text-bottom-quickAccess">
                  <h4
                    style={{
                      width: "max-content",
                      padding: "0 5px",
                      color: "#8F003C",
                    }}
                  >
                    MIIA
                  </h4>
                  <p
                  style={{
                    fontSize: "0.7rem",
                    color: "#b0b2b8",
                    marginTop: "auto"
                  }}
                  >is a Membership Service of the</p>
                  <label
                    style={{
                      fontSize: "0.8rem",
                      width: "max-content",
                      padding: "0 0px",
                    }}
                  ></label>
                  <img className="mma-logo" src={MMALogo} />
                </div>
              </div>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default QuickAccessMobileNav;
