import { useEffect, useState } from "react";
import { getCarouselSlides } from "../../services/actions/home.action";

const fetchCarouselData = async (memberID) => {
  const {
    data: { data: slides },
  } = await getCarouselSlides(memberID);
  return slides;
};

export const useCarouselSlides = (memberID) => {
  const [carouselSlides, setCarouselSlides] = useState([]);

  useEffect(() => {
    const getSlides = async () => {
      const slides = await fetchCarouselData(memberID);
      setCarouselSlides(slides);
    };

    getSlides();
  }, [memberID]);

  return carouselSlides;
};
