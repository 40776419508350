import { createSlice } from "@reduxjs/toolkit";

const articleReducer = createSlice({
  name: "counter",
  initialState: {
    article: null,
    articleshome: null,
    articlesList: null,
    articlesMeta: null,
    errorArticles: null,
  },
  reducers: {
    articleDetail: (state, action) => {
      state.article = action.payload.articleSelected;
      state.errorArticles = null;
    },
    setArticlesHome: (state, action) => {
      state.articleshome = action.payload.articleshome;
      state.errorArticles = null;
    },
    setArticlesList: (state, action) => {
      state.articlesList = action.payload.articlesList;
      state.errorArticles = null;
    },
    setArticlesMeta: (state, action) => {
      state.articlesMeta = action.payload.articlesMeta;
    },
    setErrorArticles: (state, action) => {
      state.errorArticles = action.payload.errorArticles;
    },
    cleanState: (state) => {
      state = articleReducer.initialState;
    },
  },
});

export const {
  articleDetail,
  setArticlesHome,
  setArticlesList,
  setErrorArticles,
  setArticlesMeta,
} = articleReducer.actions;

export default articleReducer.reducer;
