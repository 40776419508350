import "../i18n";
import "../sass/layout/claimform/claimform.css";
import { Route, Routes } from "react-router-dom";
import RecipeList from "../components/views/recipesList";
import Recipe from "../components/views/recipe";
import ArticleList from "../components/views/articlesList";
import OptionList from "../components/views/optionList";
import Article from "../components/views/article";
import PrivateRoute from "../helpers/PrivateRoute";
import ReportPropertyLiability from "../components/views/reportpropertyliability";
import Retirees from "../components/views/retirees";
import WellnessEvents from "../components/views/wellnessevents";
import WellnessEventDetail from "../components/views/wellnesseventdetail";
import PortalAdministration from "../components/views/portalAdministration";
import UserManagment from "../components/views/userManagment";
import HomePage from "../components/views/homepage";
import RequestCOI from "../components/views/requestcoi";
import NotificationsCenter from "../components/views/notificationscenter";
import CreateMember from "../components/views/createMember/createMember";
import ContactMIIAteams from "../components/views/contactMIIAteam";
import ProgramsList from "../components/views/programsList";
import Welcome from "../components/views/welcome";
import RiskManagmentLanding from "../components/views/riskManagmentLanding";
import ProtectedRoute from "../utils/ProtectedRoute";
import NoResults from "../components/views/noResults";
import RiskProgramsList from "../components/views/riskProgramsList";
import PrivacyPolicies from "../components/views/privacyPolicies";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={`/recipeslist`} element={<RecipeList />} />
      <Route path={`/:location/recipeslist`} element={<RecipeList />} />
      <Route path={`/recipe/:id`} element={<Recipe />} />
      <Route path={`/:location/recipe/:id`} element={<Recipe />} />
      <Route path={`/privacy-policies`} element={<PrivacyPolicies />} />
      <Route path={`/:location/privacy-policies`} element={<PrivacyPolicies />} />
      <Route path={`/articleslist`} element={<ArticleList />} />
      <Route path={`/:location/articleslist`} element={<ArticleList />} />
      <Route path={`/article/:id`} element={<Article />} />
      <Route path={`/:location/article/:id`} element={<Article />} />
      <Route path={`/:location/programslist`} element={<ProgramsList />} />
      <Route path={`/:location/optionlist/:id`} element={<OptionList />} />

      <Route
        path={`/:location/riskprogramslist`}
        element={
          <ProtectedRoute>
            <RiskProgramsList />
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={`/:location/reportclaim/propertyandliability`}
        element={
          <ProtectedRoute>
            <ReportPropertyLiability />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={`/:location/requestcoi`}
        element={
          <ProtectedRoute>
            <RequestCOI />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/notificationscenter`}
        element={
          <PrivateRoute>
            <NotificationsCenter />
          </PrivateRoute>
        }
      />
      <Route path={`/retirees`} element={<Retirees />} />
      <Route path={`:location/wellnessevents`} element={<WellnessEvents />} />
      <Route
        path={`:location/wellnessevents/:id`}
        element={<WellnessEventDetail />}
      />
      <Route
        element={
          <ProtectedRoute>
            <PortalAdministration />
          </ProtectedRoute>
        }
        path={`/:location/portal-administration`}
      />
      <Route
        path={`/:location/portal-administration/admin/users/:userId`}
        element={
          <ProtectedRoute>
            <UserManagment />
          </ProtectedRoute>
        }
      />
      <Route
        element={
          <ProtectedRoute>
            <CreateMember />
          </ProtectedRoute>
        }
        path={"/:location/portal-administration/createmember"}
      />
      {/* <Route path={"/searchresults"} element={<SearchBar />} />
      <Route path={"/:location/searchresults"} element={<SearchBar />} /> */}
      <Route path={"/:location/noresults"} element={<NoResults />} />
      <Route
        path={"/:location/contactMIIAteam"}
        element={
          <ProtectedRoute>
            <ContactMIIAteams />
          </ProtectedRoute>
        }
      />
      <Route
        element={
          // <ProtectedRoute>
            <RiskManagmentLanding />
          // </ProtectedRoute>
        }
        path={"/:location/riskManagement"}
      />
      <Route path={`/home`} element={<HomePage />} />
      <Route path={`/:location`} element={<HomePage />} />
      <Route path={"/"} element={<Welcome />} />
    </Routes>
  );
};

export default AppRoutes;
