import { useState } from "react";
import { useEffect } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../sass/layout/quickAccess/quickAccess.css";
import QuickAccessItem from "./quickaccess/quickaccessitem";
import { Navbar, Col } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import MMALogo from "../utils/img/MMA-logo.png";
import AccordionProfile from "../components/accordion/accordion";
import UserService from "../services/api/UserService";
import { fetchComponentQuickAccess, getDropdowns } from '../services/actions/home.action';

const QuickAccess = ({ isMobile = false }) => {
  // const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const loginStatus = UserService.isLoggedIn();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isBasePath = () =>
    !!(window.location.pathname === "/" || !id || id === "/" || !location);

  const { location, myUserInfo, quickAccessLinks } = useSelector((state) => ({
    quickAccessLinks: state.homeReducer.quickaccess,
    location: state.homeReducer.location?.data,
    myUserInfo: state.homeReducer.myUserInfo,
  }));

  const id = location?.uniqueNonGuessableId;
  const products = location?.purchasedProduct?.map(
    (item) => item?.product?.name
  );

  useEffect(() => {
    if (id) {
      fetchComponentQuickAccess(dispatch, id);
      if (loginStatus && myUserInfo?.location) {
        getDropdowns(dispatch, myUserInfo?.location);
      }
    }
  }, [dispatch, id, loginStatus, myUserInfo]);

  const hasProduct = (requiredProduct, purchasedProducts) =>
    purchasedProducts?.find((product) => product === requiredProduct);

  return (
    <Container bsPrefix="container" className="quickAcces-container">
      <Col className="container-title-sutitle">
        <Navbar.Brand>
          <NavLink to={id !== "/" ? `/${id ?? ""}` : "/"} className="path-item">
            <h1 className="navbar-title-header">MIIA</h1>
          </NavLink>
        </Navbar.Brand>
        <Navbar.Brand>
          <Link to={id !== "/" ? `/${id ?? ""}` : "/"} className="path-item">
            <h5 className="navbar-subtitle-logo qa-logo">
              {t("homepage.memberportal")}
            </h5>
          </Link>
        </Navbar.Brand>
      </Col>
      <div className="divisor-line">-</div>
      <>
        <div className="quickAccess-list-container">
          {loginStatus ? (
            <AccordionProfile />
          ) : (
            <>
              <a
                href="https://myassistanceprogram.com/miia-eap/"
                target="_blank"
                rel="noreferrer"
                className="quickAccess-subtitle"
              >
                MIIA Employee Assistance Program
              </a>
              {isBasePath() ? (
                <ListGroup className="quickAccess-list-group">
                  <Link to="/articleslist">
                    <ListGroup.Item className="quickAccess-list-group-item">
                      {t("homepage.articles")}
                    </ListGroup.Item>
                  </Link>
                  <Link to="/recipeslist">
                    <ListGroup.Item className="quickAccess-list-group-item">
                      {t("homepage.recipes")}
                    </ListGroup.Item>
                  </Link>
                  <a href="https://www.emiia.org/contact">
                    <ListGroup.Item className="quickAccess-list-group-item">
                      {t("homepage.contact2")}
                    </ListGroup.Item>
                  </a>
                </ListGroup>
              ) : (
                hasProduct("Health", products) && (
                  <>
                    <h5 className="quickAccess-title">Quick Access</h5>
                    <ul className="quickAccess-ul">
                      {quickAccessLinks?.map((itemLink, index) => {
                        return (
                          <QuickAccessItem
                            key={index}
                            isMobile={isMobile}
                            itemquickacces={itemLink}
                          />
                        );
                      })}
                    </ul>
                  </>
                )
              )}
            </>
          )}
        </div>
        <div className="ultimate-items">
          <div className="text-bottom-quickAccess">
            <h4
              style={{
                width: "max-content",
                padding: "0 5px",
                color: "#8F003C",
              }}
            >
              MIIA
            </h4>
            <label
              style={{
                fontSize: "0.8rem",
                width: "max-content",
                padding: "0 0px",
              }}
            >
              {t("homepage.membership")}
            </label>
            <img className="mma-logo" src={MMALogo} />
          </div>
        </div>
      </>
    </Container>
  );
};

export default QuickAccess;
