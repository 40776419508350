import "../../sass/layout/welleneseventscontainer/wellnesseventscontainer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuPath from "../path/MenuPath";
import TitlePage from "../titlePage";
import { useTranslation } from "react-i18next";
import WellnessEventsContainer from "../wellnessevents/WellnessEventsContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { getOnlineClasses } from "../../services/actions/wellnessclasses.action";

const WellnessEvents = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locationId = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  const [loadingData, setLoadingData] = useState(true);

  // Fetch classes from the api
  useEffect(() => {
    if (locationId) getOnlineClasses(dispatch, locationId);
  }
  , [dispatch, locationId]);
    

  const onlineClasses = useSelector(
    (state) => state.wellnesseventsReducer.onlineClasses
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (
      onlineClasses?.length > 0
    ) {
      setLoadingData(false);
    }
  }, [onlineClasses]);

  return (
    <div>
      <Col md={12} lg={12} xl={12} className="container-data-wellness">
        <Row className="row-menu-container">
          <MenuPath
            className="menu-path"
            name={t("wellnessEvents.wellnessPath")}
          />
        </Row>
        <TitlePage
          boldText={t("wellnessEvents.wellnesstitle1")}
          commonText={t("wellnessEvents.wellnesstitle2")}
        />
        {loadingData ? (
          <Container fluid className="container-spinner">
            <Spinner animation="border" variant="danger" className="spinner" />
          </Container>
        ) : (
          <WellnessEventsContainer />
        )}
      </Col>
    </div>
  );
};

export default WellnessEvents;
