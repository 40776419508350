import "../../sass/layout/portalAdmininistration/portalAdministration.css";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { fetchMIIAContacts } from "./../../services/actions/users.action";
import ContactList from "./contactList";
import MenuPath from "../path/MenuPath";
import TitlePage from "../titlePage";
import Paginator from '../paginator/paginator';
import usePagination from '../../hooks/usePagination';

const ContactMIIAteams = () => {
  const { t } = useTranslation();
  const contacts = useSelector(
    (state) => state.usersReducer.miiaContacts?.data
  );
  const meta = useSelector(
    (state) => state.usersReducer.miiaContacts?.meta
  );
  const { items, paginationParams, onClickPage } = usePagination(fetchMIIAContacts, meta);

  return (
    <div>
      <Row className="box-menu">
        <MenuPath className="menuPathPortalAdmin" name={t("contact.link")} />
      </Row>
      <Row>
        <TitlePage boldText={t("contact.title")} commonText={""} />
      </Row>
      <Row>
        <Col className="box-portal-admin">
          {contacts?.length > 0 ? (
            <ContactList prop={contacts} />
          ) : (
            <h4 className="portalAdmin-title">
              {t("portalAdministration.noContact")}
            </h4>
          )}
        </Col>
      </Row>
      <Container fluid className="pagination-contacts">
        <Paginator
          items={items}
          onClickPage={onClickPage}
          filterParams={paginationParams}
          metadata={meta}
        />
      </Container>
    </div>
  );
};

export default ContactMIIAteams;
