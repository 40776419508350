import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "../../sass/layout/articleContainer/articleContainer.css";
import ArticlePreview from "./articlePreview";
import ReactMarkdown from "react-markdown";
import { useEffect, useState } from "react";
import { fetchArticles } from "../../services/actions/articles.action";

const ArticleContent = ({ article }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [previewsArticles, setPreviesArticles] = useState();
  const articles = useSelector((state) => state.articleReducer.articlesList);

  useEffect(() => {
    fetchArticles(dispatch, 1, 5);
  }, [dispatch]);

  useEffect(() => {
    if (articles?.length > 0) {
      const previewArts = articles
        ?.slice(0, 5)
        .filter((element) => element?.id != article?.id);
      setPreviesArticles(previewArts);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [articles, article]);

  return (
    <Container
      fluid
      style={{
        padding: "0px",
      }}
    >
      <Row className="article-box">
        <Col className="article-detail">
          <h3>{article?.title}</h3>
          <h5>{article?.subtitle}</h5>
          <h6 className="by-author">
            <b>
              {t("utils.by")} {article?.author}
            </b>
          </h6>
          <ReactMarkdown children={article?.body} />
        </Col>
        <Col className="more-articles">
          <Container fluid className="box-more">
            <p>
              {t("article.more")} <b>{t("article.articles")}</b>
            </p>
          </Container>
          {previewsArticles?.slice(0, 2).map((element, index) => {
            return <ArticlePreview key={index} article={element} />;
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleContent;
