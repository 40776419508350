import { useTranslation } from "react-i18next";
import "../../sass/layout/requestcoi/requestcoi.css";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { FiFile } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { createCertificateHolderForm } from "../../services/actions/report.action";
import { usePath } from "../../hooks/usePath";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const CertificateOfInsurance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FILE_SIZE = "1GB";
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const path = usePath();

  const status = useSelector((state) => state.reportReducer.report?.status);

  useEffect(() => {
    status && setStatusCode(status);

    if (statusCode === 200) {
      setShowAlert(true);
    }
  }, [status, statusCode]);

  const handleClose = () => setShowAlert(false);
  const handleShow = (id) => {
    setShow(true);
  };

  const handleDelete = (e) => {
    window.location.href = "/";
    setShow(false);
  };

  const COIFormSchema = Yup.object().shape({
    namebusiness: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(100, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    streetaddress: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(100, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    city: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(100, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    state: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(100, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    zipcode: Yup.number()
      .typeError(t("errors.mustbenumber"))
      .required(t("errors.fieldrequired")),

    explaincoverage: Yup.string()
      .min(2, t("errors.tooShort"))
      .max(50, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    datecoi: Yup.date()
      .min(moment().subtract(1, "days"), t("errors.invalidDate"))
      .required(t("errors.fieldrequired")),
    email: Yup.string()
      .email(t("errors.emailFormat"))
      .min(2, t("errors.tooShort"))
      .max(100, t("errors.tooLong"))
      .required(t("errors.fieldrequired")),
    attachedFiles: Yup.array()
      .required(t("errors.fieldrequired"))
      .max(10, t("errors.max10")),
    // attachedFiles: Yup.mixed().test('fileSize', "File Size is too large", value => value.size >= FILE_SIZE)
    //     .required(t('errors.fieldrequired')),
  });

  const onSubmitForm = (values) => {
    const formData = new FormData();

    const formBody = {
      nameOfBusiness: values.namebusiness,
      streetAddress: values.streetaddress,
      city: values.city,
      state: values.state,
      explainCoverageNeeded: values.explaincoverage,
      coiDate: values.datecoi,
      coiEmail: values.email,
      zipCode: values.zipcode,
    };

    values.attachedFiles.map((file) => formData.append("files.attachedFiles", file));

    // formData.append("files.attachedFiles", values.attachedFiles);
    formData.append("data", JSON.stringify(formBody));
    createCertificateHolderForm(dispatch, formData);
  };

  const initialValues = {
    namebusiness: "",
    streetaddress: "",
    city: "",
    state: "",
    zipcode: "",
    explaincoverage: "",
    datecoi: "",
    email: "",
    attachedFiles: [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={COIFormSchema}
      onSubmit={() => onSubmitForm()}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          errors,
          touched,
          handleBlur,
          setFieldValue,
        } = formik;

        return (
          <Container fluid className="form-container-fluid">
            <Row className="form-container-row">
              <Col md={12} lg={10} className="forminputs-data-important">
                <h5>{t("coi.title")}</h5>
                <p>{t("coi.subtitle")}</p>
                <Form
                  onSubmit={formik.handleSubmit}
                  bsPrefix="form-container-claim"
                >
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>{t("coi.namebussiness")}</Form.Label>
                      <Form.Control
                        name="namebusiness"
                        value={values.namebusiness}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        min={Yup.date(Date.now())}
                        isInvalid={errors.namebusiness && touched.namebusiness}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.namebusiness}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>{t("coi.address")}</Form.Label>
                      <Form.Control
                        name="streetaddress"
                        value={values.streetaddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.streetaddress && touched.streetaddress
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.streetaddress}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label>{t("coi.city")}</Form.Label>
                      <Form.Control
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.city && touched.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label>{t("coi.state")}</Form.Label>
                      <Form.Control
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.state && touched.state}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label bsPrefix="item-col">
                        {t("coi.zipcode")}
                      </Form.Label>
                      <Form.Control
                        name="zipcode"
                        value={values.zipcode}
                        onChange={handleChange}
                        isInvalid={errors.zipcode && touched.zipcode}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.zipcode}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label bsPrefix="item-col">
                        {t("coi.explaincoverage")}
                      </Form.Label>
                      <Form.Control
                        name="explaincoverage"
                        value={values.explaincoverage}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          errors.explaincoverage && touched.explaincoverage
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.explaincoverage}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col sm={12} md={6}>
                      <Form.Label bsPrefix="item-col">
                        {t("coi.datecoi")}
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="datecoi"
                        value={values.datecoi}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.datecoi && touched.datecoi}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.datecoi}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={12} md={6}>
                      <Form.Label bsPrefix="item-col">
                        {t("coi.emailcoi")}
                      </Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="item-form">
                    <Col md={6}>
                      <Form.Label>{t("coi.attachfiles")}</Form.Label>
                      <Row>
                        <label
                          class={
                            (values.attachedFiles?.length == 0 ||
                              !values.attachedFiles) &&
                            errors.attachedFiles &&
                            touched.attachedFiles
                              ? "custom-file-upload-is-invalid"
                              : "custom-file-upload"
                          }
                        >
                          <input
                            id="file-upload"
                            class={"form-control"}
                            multiple
                            type="file"
                            onBlur={handleBlur}
                            isInvalid={
                              errors.attachedFiles && touched.attachedFiles
                            }
                            onChange={(e) => {
                              const selectedFiles = new Array(
                                ...e.target.files
                              );
                              if (selectedFiles?.length > 0) {
                                setFieldValue("attachedFiles", [
                                  ...values.attachedFiles,
                                  ...selectedFiles,
                                ]);
                              }
                            }}
                          ></input>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              values.attachedFiles?.length > 0 ? (
                                <Tooltip className="tooltip-files">
                                  {values.attachedFiles?.map(
                                    (element, index) => {
                                      if (
                                        values.attachedFiles?.length - 1 !=
                                        index
                                      ) {
                                        return <small>{element?.name}, </small>;
                                      } else {
                                        return <small>{element?.name}</small>;
                                      }
                                    }
                                  )}
                                </Tooltip>
                              ) : (
                                <Tooltip className="tooltip-empty"></Tooltip>
                              )
                            }
                          >
                            <p className="limit-files">
                              <FiFile className="icon-file" />
                              {values.attachedFiles?.length > 1
                                ? values.attachedFiles?.length + " files"
                                : values.attachedFiles?.length == 1
                                ? values.attachedFiles?.length + " file"
                                : "Select up to 10 files"}
                            </p>
                          </OverlayTrigger>
                        </label>
                      </Row>
                      {errors?.attachedFiles && (
                        <p className="invalid-feedback-file">
                          {errors?.attachedFiles}
                        </p>
                      )}
                      {values?.attachedFiles?.length > 0 && (
                        <span
                          onClick={() => setFieldValue("attachedFiles", [])}
                          className="file-clear"
                        >
                          Clear files
                        </span>
                      )}
                    </Col>
                    <Col md={6} className="col-button-submit">
                      <>
                        <button
                          onClick={() => {
                            setShow(true);
                          }}
                          className="btn style-btn-1 btn-cancel-coi"
                        >
                          {t("claimform.cancel")}
                        </button>
                        <Modal
                          show={show}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                        >
                          <Modal.Body>
                            Are you sure you want to delete this form? This
                            action can`t be undone.
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary btn-cancel-it"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary btn-delete-it"
                              onClick={() => handleDelete()}
                            >
                              Yes, delete it
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                      <button
                        type="submit"
                        className="btn style-btn-2"
                        onClick={() => {
                          onSubmitForm(formik.values);
                        }}
                      >
                        {t("coi.submit")}
                      </button>
                      <Modal
                        show={showAlert}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Body>
                          You have successfully sent the form
                        </Modal.Body>
                        <Modal.Footer>
                          <div
                            style={{
                              textDecoration: "none !important",
                              color: "#FFFFFF",
                              backgroundColor: "#8F033C",
                              width: "100px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5px",
                            }}
                            className="link-userEdit"
                            onClick={(e) => {
                              setShowAlert(false);
                              navigate(`/${path}`);
                            }}
                          >
                            Ok
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={11} lg={2} className="box-data-important">
                <div className="text-claim-box">
                  <p className="title-claim">
                    <b>{t("claimform.titleBoxData")}</b>
                  </p>
                  <p className="body-claim">
                  {t("coi.rightsidebarwarning")}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }}
    </Formik>
  );
};

export default CertificateOfInsurance;
