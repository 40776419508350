import { Container, Row, Col } from 'react-bootstrap';
import searchIcon from "../../utils/img/lupa.png";
import { useTranslation } from "react-i18next";
import "../../sass/layout/searchResults/searchResults.css"

const NoResults = () => {
	const { t } = useTranslation();

	return (
		<Container fluid>
			<Row className="no-results">
				<div className="no-results-dialog">
					<img className="search-icon" src={searchIcon}></img>
					<h3>{t("results.noResults")}</h3>
				</div>
			</Row>
		</Container>
	);
};

export default NoResults;