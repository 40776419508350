import "../../sass/layout/claimform/claimform.css";
import Row from "react-bootstrap/Row";
import React from "react";
import MenuPath from "../path/MenuPath";
import FormPropertyLiability from "../claimpropertyliability/formPropertyLiability";
import { useTranslation } from "react-i18next";
const ReportPropertyLiability = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="row-menu-container">
        <MenuPath
          name={t("claimform.claimreport")}
          nameItem={t("claimform.propertyLiability")}
        />
      </Row>
      <FormPropertyLiability />
    </div>
  );
};

export default ReportPropertyLiability;
