import "../../sass/layout/articleListContainer/articleListContainer.css";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePath } from "../../hooks/usePath";
import imgPlaceholder from "../../utils/img/imgPlaceholder.png";

const ArticlePreviewList = ({ border, article }) => {
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const navigate = useNavigate();
  const path = usePath();
  const { t } = useTranslation();
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  return (
    <Container fluid className="no-border-container">
      <Row className={border ? "article-item-border" : "article-item"}>
        <Col lg={5} md={5} sm={12} xs={12}>
          <div
            style={{
              height: "15rem",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundPositionY: "center",
              backgroundSize: "cover",
              backgroundImage: article?.attributes?.image?.data?.attributes?.url
                ? `url(${
                    article?.attributes?.image?.data?.attributes?.url +
                    "?" +
                    tokenMedia
                  })`
                : `url(${imgPlaceholder})`,
            }}
          ></div>
        </Col>
        <Col className="text">
          <h4>{article?.attributes?.title}</h4>
          <p className="description">{article?.attributes?.description}</p>
          <div
            className="seeArticle"
            onClick={() => {
              id !== "/" && navigate(`/${path}/article/${article?.id}`);
              id === "/" && navigate(`/article/${article?.id}`);
            }}
          >
            {t("article.seeThisArticle")}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ArticlePreviewList;
