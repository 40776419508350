import "../../sass/layout/retirees/retirees.css";
import Footer from "../footer";
import NavbarComponent from "../navbar";
import QuickAccess from "../quickAccess";
import RedNavbar from "../redNavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RetireesContainer from "../retirees/retireesContainer";
import MenuPath from "../path/MenuPath";
import TitlePage from "../titlePage";
import { useTranslation } from "react-i18next";

const Retirees = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="retirees-page">
      <Container fluid className="headers-home">
        <NavbarComponent />
        <RedNavbar />
      </Container>
      <Row>
        <Col sm={3} className="column-small quickAccess-container-retirees">
          <QuickAccess />
        </Col>
        <Col sm={8} fluid className="container-data-retirees">
          <MenuPath name={"Retirees"} />
          <TitlePage
            boldText={t("retirees.retirees")}
            commonText={t("retirees.only")}
          />
          <RetireesContainer />
          <div className="col- footer-container-retirees">
            <Footer />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Retirees;
