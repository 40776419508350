import { authHeader } from "../api/HttpService";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const downloadFile = async (
  category,
  index,
  setLoading,
  navigate,
  location
) => {
  setLoading({ [index]: true });

  await axios
    .get(API_URL + `api/imported-report/download-report/${category}`, {
      headers: authHeader(),
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      setLoading({ [index]: false });
    })
    .catch((error) => {
      setLoading({ [index]: false });
      navigate({
        pathname: `/${location}/noresults`,
      })
    });
};
