import axios, { authHeader } from "../api/HttpService";
import {
  setErrorNavbarlinksRole,
  setErrorNotifications,
  setErrorQuickAccess,
  setErrorTokenMedia,
  setNavBarLinksforRole,
  setNotifications,
  setQuickAccess,
  setTokenMedia,
  setPublicNotifications,
  setErrorPublicNotifications,
  setPrivateNotifications,
  setErrorPrivateNotifications,
  setFeaturedNews,
  setDropdowns,
  setErrorDropdowns,
  setNoLocationNotifications,
  setButtonsInHome,
  setLocation,
  setLocations,
  setMyUserInfo,
  setErrorMyUserInfo,
  setNewsletter,
  setErrorNewsletter,
  setErrorGetLocation,
  setBlockPortalRedirections,
} from "../reducers/homeReducer";
import { setUserDetail } from '../reducers/userReducer';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchComponentQuickAccess = async (dispatch, id) => {
  try {
    axios
      .get(API_URL + `api/quick-access-links/location/${id}`)
      .then((response) => {
        const data = response.data;
        const sortedData = data.sort((a, b) => a.order - b.order);
        dispatch(setQuickAccess({ quickaccess: sortedData }));
      });
  } catch (error) {
    // dispatch(setErrorQuickAccess({ errorRecipes: error.response.data }));
    console.error(error)
  }
};

export const getNotifications = async (dispatch) => {
  try {
    axios
      .get(API_URL + `api/notifications`, { headers: authHeader() })
      .then((response) => {
        dispatch(setNotifications({ notifications: response.data.data }));
      });
  } catch (error) {
    // dispatch(setErrorNotifications({ error: error.response.data }));
    console.error(error)
  }
};

export const getPublicNotifications = async (dispatch, id) => {
  if (!id) return;
  try {
    const response = await axios.get(
      API_URL + `api/notifications/MyPublicNotifications/${id}`
    );
    dispatch(
      setPublicNotifications({ publicnotifications: response.data.data })
    );
  } catch (error) {
    // dispatch(setErrorPublicNotifications({ error: error.response.data }));
    console.error(error)
  }
};

export const getPrivateNotifications = async (dispatch) => {
  try {
    axios
      .get(API_URL + `api/notifications/MyPrivateAndPublicNotifications`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(
          setPrivateNotifications({ privatenotifications: response.data.data })
        );
      });
  } catch (error) {
    // dispatch(setErrorPrivateNotifications({ error: error.response.data }));
    console.error(error)
  }
};

export const getNotificationsWithoutLocation = async (dispatch) => {
  try {
    axios
      .get(API_URL + `api/notifications/MyPublicNotificationsWithoutLocation`)
      .then((response) => {
        dispatch(setNoLocationNotifications({ nolocation: response.data }));
      });
  } catch (error) {
    // dispatch(setErrorNotifications({ error: error.response.data }));
    console.error(error)
  }
};

export const getTokenMedia = async (dispatch) => {
  try {
    axios
      .get(API_URL + `api/media-access/get-sas-token`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(setTokenMedia({ tokenMedia: response.data?.meta }));
      });
  } catch (error) {
    // dispatch(setErrorTokenMedia({ error: error.response.data }));
    console.error(error)
  }
};

export const getLinkforRole = async (dispatch) => {
  let role = "town-administrator";
  try {
    axios
      .get(API_URL + `api/user-roles/${role}`, { headers: authHeader() })
      .then((response) => {
        dispatch(setNavBarLinksforRole({ navbarLinksRoles: response.data }));
      });
  } catch (error) {
    dispatch(
      // setErrorNavbarlinksRole({ errorNavBarLinksRoles: error.response.data })
      console.error(error)
    );
  }
};

export const getFeaturedNews = async (dispatch) => {
  try {
    axios
      .get(API_URL + `api/featured-news?populate=image`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(setFeaturedNews({ featuredNews: response.data }));
      });
  } catch (error) {
    console.log(error);
  }
};

export const getButtonsInHome = async (dispatch, id) => {
  try {
    axios
      .get(API_URL + `api/buttons-in-home/location/${id}`)
      .then((response) => {
        dispatch(setButtonsInHome({ buttons: response.data }));
      });
  } catch (error) {
    dispatch(setErrorDropdowns({ error: error.response.data }));
  }
};

export const getLocation = async (dispatch, id) => {
  dispatch(setLocation({location: null}));

  if (!id) {
    dispatch(setLocation({location: null}));
    return;
  }

  try {
    const response = await axios.get(API_URL + `api/locations/${id}`, {
      headers: authHeader(),
    });
    dispatch(setLocation({ location: response.data }));
  } catch (error) {
    // dispatch(setErrorGetLocation({ errorLocation: error.response.data }));
    console.error(error)
  }
};

export const getLocations = async (dispatch) => {
  try {
    const response = await axios.get(
      API_URL + `api/locations/getAllLocations`,
      {
        headers: authHeader(),
      }
    );
    dispatch(setLocations({ locations: response.data || []}));
  } catch (error) {
    console.log(error);
  }
};

export const changeLocation = async (dispatch, id, navigate) => {
  dispatch(setUserDetail({ userDetail: null }));

  try {
    await axios.put(
      API_URL + `api/miia-users/updateCurrentLocation/${id}`,
      {},
      {
        headers: authHeader(),
      }
    ).then(async ({data: { data } }) => {
      dispatch(setUserDetail({ userDetail: data }));
      await getMyUserInfo(dispatch);
      await getLocation(dispatch, id);
      navigate({
        pathname: `/${id}`,
      });
      dispatch(setBlockPortalRedirections(true));
    });
  } catch (error) {
    console.log(error, "error en changeLocation");
  }
};

export const getDropdowns = async (dispatch, id) => {
  // dispatch(setDropdowns({ dropdowns: []}));

  try {
    const response = await axios.get(API_URL + `api/dropdowns/location/${id}`, {
      headers: authHeader(),
    });
    dispatch(setDropdowns({ dropdowns: response.data }));
  } catch (error) {
    // dispatch(setErrorDropdowns({ error: error.response.data }));
    console.error(error)
  }
};

export const getCarouselAutoScrollTime = async () => {
  try {
    const autoScrollTime = await axios.get(
      API_URL + "api/carrousel-slides/time-shown",
      {
        headers: authHeader(),
      }
    );
    return autoScrollTime;
  } catch (error) {
    console.log(error);
    return { data: null }
  }
};

export const getCarouselSlides = async (id) => {
  if (!id) return;
  try {
    const slides = await axios.get(
      API_URL + `api/carrousel-slides/findPublicAndByLocation/${id}?sort=order:desc`,
      {
        headers: authHeader(),
      }
    );

    return slides;
  } catch (error) {
    console.error(error);
    return { data: { data: [] } };
  }
};

export const getMyUserInfo = async (dispatch) => {
  try {
    axios
      .get(API_URL + `api/miia-users/myUserInfo`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(setMyUserInfo({ myUserInfo: response.data }));
      });
  } catch (error) {
    // dispatch(setErrorMyUserInfo({ error: error.response.data }));
    console.error(error)
  }
};

export const getNewsletter = async (dispatch) => {
  try {
    axios.get(API_URL + `api/newsletters/active`).then((response) => {
      dispatch(setNewsletter({ newsletter: response.data }));
    });
  } catch (error) {
    // dispatch(setErrorNewsletter({ error: error.response.data }));
    console.error(error)
  }
};