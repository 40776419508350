import axios from "../api/HttpService";
import {
  setOptionDetail,
  setErrorOptions,
} from "../reducers/optionReducer";
import { authHeader } from '../api/HttpService';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchOptionDetails = async (dispatch, id) => {
  try {
    const response = await axios.get(API_URL + `api/dropdown-options/${id}`, { headers: authHeader() });
    dispatch(setOptionDetail(response.data));
  } catch (error) {
    dispatch(setErrorOptions(error));
  }
};
