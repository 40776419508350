import "../sass/layout/navbar/navbar.css";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Navbar } from "react-bootstrap";
import { FiAlignJustify } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { MdOutlineNotifications } from "react-icons/md";
import UserService from "../services/api/UserService";
import { useNavigate } from "react-router-dom";
import DropdownLocation from "./dropdownLocation/dropdownLocation";
import { useEffect, useState } from "react";
import { usePath } from "../hooks/usePath";
import { Mixpanel } from "../utils/mixpanelIntegration";

const NavbarComponent = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const path = usePath();
  const location = useSelector((state) => state.homeReducer.location?.data);
  const userDetail = useSelector((state) => state.userReducer.userDetail);
  const [miiaEmployee, setMiiaEmployee] = useState(false);
  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  useEffect(() => {
    //CHECK
    sessionStorage.setItem("id", window.location.pathname);
  }, []);

  useEffect(() => {
    if (UserService.isLoggedIn()) {
      setMiiaEmployee(!!userDetail?.portal_user_role?.miia_employee);
    }
  }, [userDetail]);

  const onClickContactMiia = () => {
    if (UserService.isLoggedIn()) {
      navigate({
        pathname: `/${path}/contactMIIAteam`,
      });
    }
  };

  return (
    <Navbar className="no-border" expand="lg">
      {UserService.isLoggedIn() && (
        <MdOutlineNotifications className="icon-notif-tablet" />
      )}
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        className="toggler-custom"
        children={<FiAlignJustify className="icon-menu" />}
      />
      <Navbar.Collapse
        className="navbar-yell"
        id="responsive-navbar-nav justify-content-end"
      >
        <Nav>
          {window.location.pathname !== "/" && (
            <Nav.Item bsPrefix="navbar-label chelmsford-desktop">
              <span
                style={
                  miiaEmployee
                    ? {
                        marginRight: "2.5rem",
                      }
                    : undefined
                }
              >
                {t("homepage.youarein")}
              </span>
              {miiaEmployee ? (
                <DropdownLocation />
              ) : (
                <span className="btn-town icon-dropdown">
                  {location?.title ?? "City not found"}
                </span>
              )}
            </Nav.Item>
          )}
          <Nav.Item bsPrefix="navbar-label">
            <Nav.Link
              href="https://www.emiia.org/about"
              target="_blank"
              bsPrefix="navbar-a"
              onClick={() => {Mixpanel.track("Navbar.About",{ locationName: location?.name })}}
            >
              {t("homepage.about")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item bsPrefix="navbar-label" onClick={onClickContactMiia}>
            <Nav.Link
              onClick={() => {Mixpanel.track("Navbar.Contact",{ locationName: location?.name })}}
              href={
                UserService.isLoggedIn() ? "" : "https://www.emiia.org/contact"
              }
              target={UserService.isLoggedIn() ? "_self" : "_blank"}
              bsPrefix="navbar-a"
            >
              {UserService.isLoggedIn() && id !== "/"
                ? t("homepage.contactmiia")
                : t("homepage.contact")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
