import React, { useEffect, useRef, useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "../../sass/layout/sideBar/sideBar.css";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { Col, Navbar, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import QuickAccessItem from "../quickaccess/quickaccessitem";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AccordionProfile from "../accordion/accordion";
import { usePath } from "../../hooks/usePath";
import UserService from '../../services/api/UserService';
import { fetchComponentQuickAccess, getDropdowns } from '../../services/actions/home.action';

const SideNavBar = () => {
  const quickaccess = useSelector((state) => state.homeReducer.quickaccess);
  const location = useSelector((state) => state.homeReducer.location?.data);
  // const loginStatus = useSelector((state) => state.userReducer.loginStatus);
  const loginStatus = UserService.isLoggedIn();
  const path = usePath();
  const { t } = useTranslation();
  const [isVisible, setisVisible] = useState(false);
  const sidebarRef = useRef(null);
  const sidebarComponent = useRef(null);
  const dispatch = useDispatch();
  const isBasePath = () =>
    !!(window.location.pathname === "/" || !id || id === "/" || !location);

  const id = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  useEffect(() => {
    if (id) {
      fetchComponentQuickAccess(dispatch, id)
      if (loginStatus) {
        getDropdowns(dispatch, id);
      }
    };
  }, [dispatch, id, loginStatus])

  useEffect(() => {
    document.addEventListener("touchstart", handleClickElement, false);
    return () => {
      document.removeEventListener("touchstart", handleClickElement, false);
    };
  }, []);

  const handleClickElement = (event) => {
    if (sidebarRef.current && !sidebarRef?.current?.contains(event.target)) {
      if (sidebarComponent?.current.child.toggle.props.expanded) {
        setisVisible(false);
      }
    }
  };

  return (
    <div ref={sidebarRef}>
      <SideNav
        ref={sidebarComponent}
        expanded={isVisible}
        onToggle={() => {}}
        onClick={() => {
          !isVisible && setisVisible(!isVisible);
        }}
        className="sideBar-container"
      >
        <SideNav.Toggle
          componentClass={() => {
            return (
              <div
                className={
                  !isVisible ? "box-button-collapsed" : "toggle-text-expanded"
                }
              >
                <div
                  className={!isVisible ? "toggle-text" : "toggle-text-normal"}
                >
                  <Col className={isVisible ? "btn-closed" : "button-non"}>
                    <button
                      className="btn-close"
                      onClick={() => setisVisible(!isVisible)}
                    ></button>
                  </Col>
                  {!isVisible && (
                    <Col className="quickAccess-title-sidebar">
                      <h4>{t("quickAccess.title")}</h4>
                    </Col>
                  )}

                  <Col
                    className={
                      isVisible
                        ? "container-title-sutitle"
                        : "container-title-sutitle-non"
                    }
                  >
                    <Navbar.Brand>
                      <Link
                        to={id != "/" ? `${id}` : "/"}
                        className="path-item"
                      >
                        <h1 className="navbar-title-header">MIIA</h1>
                      </Link>
                    </Navbar.Brand>
                    <Navbar.Brand>
                      <Link
                        to={id != "/" ? `${id}` : "/"}
                        className="path-item"
                      >
                        <h5 className="navbar-subtitle-logo qa-logo">
                          {t("homepage.memberportal")}
                        </h5>
                      </Link>
                    </Navbar.Brand>
                  </Col>
                  <div
                    className={
                      isVisible
                        ? "divisor-line"
                        : "divisor-line searchBarIsVisible"
                    }
                  >
                    -
                  </div>
                  {!loginStatus && (
                    <a
                      style={{
                        cursor: "pointer",
                      }}
                      href="https://myassistanceprogram.com/miia-eap/"
                      target={"_blank"}
                      rel="noreferrer"
                      className={
                        isVisible
                          ? "quickAccess-subtitle"
                          : "quickAccess-subtitle searchBarIsVisible"
                      }
                    >
                      MIIA Employee Assistance Program
                    </a>
                  )}
                  {!loginStatus ||
                    (id === "/" && (
                      <h5
                        className={
                          isVisible
                            ? "quickAccess-title"
                            : "quickAccess-title novisible"
                        }
                      >
                        Quick Access
                      </h5>
                    ))}
                </div>
              </div>
            );
          }}
        />
        <SideNav.Nav
          defaultSelected="home"
          className={isVisible && "sideBar-container-nav"}
        >
          {loginStatus ? (
            <NavItem
              className={isVisible ? null : "searchBarIsVisible"}
              eventKey="placed orders"
            >
              <AccordionProfile />
            </NavItem>
          ) : isBasePath() ?
            <div className={!isVisible ? "novisible" : "visible"}>
              <ListGroup className="quickAccess-list-group">
                <a href="articlesList">
                  <ListGroup.Item className="quickAccess-list-group-item">
                    {t("homepage.articles")}
                  </ListGroup.Item>
                </a>
                <a href="recipesList">
                  <ListGroup.Item className="quickAccess-list-group-item">
                    {t("homepage.recipes")}
                  </ListGroup.Item>
                </a>
                <Link to={`/${path}/contactMIIAteam`}>
                  <ListGroup.Item className="quickAccess-list-group-item">
                    {t("homepage.contact2")}
                  </ListGroup.Item>
                </Link>
              </ListGroup>
            </div>
           : quickaccess?.map((itemLink, index) => (
              <NavItem key={index} eventKey="placed orders">
                <NavIcon>
                  <i
                    className="fa fa-fw fa-line-chart"
                    style={{ fontSize: "1.75em" }}
                  />
                </NavIcon>
                <NavText>
                  <QuickAccessItem itemquickacces={itemLink} />
                </NavText>
              </NavItem>
            ))
          }
        </SideNav.Nav>
      </SideNav>
    </div>
  );
};

export default SideNavBar;
