// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-page {
  width: 94%;
  padding-right: 0 !important;
  margin: 0px auto 25px auto !important;
  padding-left: 0 !important;
  font-family: "Lato", sans-serif !important;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/titlePage/titlePage.scss","webpack://./src/sass/utilities/_variables.scss","webpack://./src/sass/layout/titlePage/titlePage.css"],"names":[],"mappings":"AAKA;EACI,UAAA;EACA,2BAAA;EACA,qCAAA;EACA,0BAAA;EACA,0CCRG;ACIP","sourcesContent":["\n@import \"../../utilities/mixins\";\n@import \"../../utilities/variables\";\n \n\n.title-page {\n    width: 94%;\n    padding-right: 0 !important;\n    margin: 0px auto 25px auto !important;\n    padding-left: 0 !important;\n    font-family: $Font;\n}","// Utils\n$DecorationNone: none;\n$Font: \"Lato\", sans-serif !important;\n$Font-size-navbar: 12px;\n$LetterSpacing: 0px;\n\n// Colors\n$Transparent: rgba(0, 0, 0, 0);\n$RedColor: rgb(143, 0, 60, 1);\n$White: rgba(255, 255, 255, 1);\n$Black: rgba(26, 25, 25, 1);\n$MainBackgroundColor: rgba(247, 248, 249, 1);\n$SecondaryBackgroundColor: rgba(221, 208, 208, 0.5);\n$GrayColor: rgba(204, 204, 204, 1);\n$LinkColor: rgba(18, 101, 183, 1);\n$LinkSecondColor: rgba(78, 125, 162, 1);\n",".title-page {\n  width: 94%;\n  padding-right: 0 !important;\n  margin: 0px auto 25px auto !important;\n  padding-left: 0 !important;\n  font-family: \"Lato\", sans-serif !important;\n}/*# sourceMappingURL=titlePage.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
