import { Container } from "react-bootstrap";
import "../../sass/layout/recipeListContainer/recipeListContainer.css";
import RecipePreviewList from "./recipePreviewList";
import { useSelector } from "react-redux";
import usePagination from "../../hooks/usePagination";
import Paginator from "../paginator/paginator";
import { fetchRecipes } from '../../services/actions/recipes.action';

const RecipeListContainer = () => {
  const {
    recipes,
    meta,
  } = useSelector((state) => ({
    recipes: state.recipeReducer.recipesList,
    meta: state.recipeReducer.recipesMeta,
  }));

  const { items, paginationParams, onClickPage } = usePagination(
    fetchRecipes,
    meta,
  );

  return (
    <Container fluid className="recipe-list-container">
      {recipes?.length > 0 && recipes.map((recipe, index) => {
        return (
          <RecipePreviewList
            key={index}
            border={recipe?.id == recipes?.length - 1 ? false : true}
            recipe={recipe}
          />
        );
      })}
      <Container fluid className="pagination-recipes">
        <Paginator
          items={items}
          filterParams={paginationParams}
          metadata={meta}
          onClickPage={onClickPage}
        />
      </Container>
    </Container>
  );
};

export default RecipeListContainer;
