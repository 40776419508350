import { createSlice } from "@reduxjs/toolkit";

const usersReducer = createSlice({
  name: "counter",
  initialState: {
    users: [],
    userEdit: [],
    userEditInfoSuccess:null,
    products: [],
    memberCreated: [],
    roleList: [],
    dropdownsList: [],
    associatedDropdownsList: [],
    productspurchased: [],
    dropdownsForUser: [],
    miiaContacts: [],
    memberCreatedError: [],
    sectionsAndReports: [],
    dropdownSelectOptions: [],
  },
  reducers: {
    setInitialUsers: (state, action) => {
      state.users = action.payload.users;
      state.errorRecipes = null;
    },
    setInitialUserEdit: (state, action) => {
      state.userEdit = action.payload.users;
      state.errorRecipes = null;
    },
    setUserEdit: (state, action) => {
      state.userEditInfoSuccess = action.payload.users;
      state.errorRecipes = null;
    },
    deleteUser: (state, action) => {
      state.users = action.payload.users;
      state.errorRecipes = null;
    },
    fetchProductList: (state, action) => {
      state.products = action.payload.products;
      state.errorRecipes = null;
    },
    memberCreated: (state, action) => {
      state.memberCreated = action.payload.users;
      state.errorRecipes = null;
    },
    fetchRoleList: (state, action) => {
      state.roleList = action.payload.roles;
      state.errorRecipes = null;
    },
    fetchDropdownsList: (state, action) => {
      state.dropdownsList = action.payload.dropdowns;
      state.errorRecipes = null;
    },
    fetchAssociatedDropdownsList: (state, action) => {
      state.dropdownsForUser = action.payload.dropdownAvailable;
      state.errorRecipes = null;
    },
    setProductsPurchased: (state, action) => {
      state.productspurchased = action.payload.products;
      state.errorRecipes = null;
    },
    setMIIAContacts: (state, action) => {
      state.miiaContacts = action.payload.contacts;
      state.errorRecipes = null;
    },
    memberCreatedError: (state, action) => {
      state.memberCreatedError = action.payload.usersError;
    },
    setSectionsAndReports: (state, action) => {
      state.sectionsAndReports = action.payload.sections;
    },
    setDropdownSelectOptions: (state, action) => {
      state.dropdownSelectOptions = action.payload.dropdowns;
    }
  },
});

export const {
  setInitialUsers,
  setInitialUserEdit,
  deleteUser,
  setUserEdit,
  fetchProductList,
  memberCreated,
  fetchRoleList,
  fetchDropdownsList,
  fetchAssociatedDropdownsList,
  setProductsPurchased,
  setMIIAContacts,
  memberCreatedError,
  setSectionsAndReports,
  setDropdownSelectOptions,
} = usersReducer.actions;

export default usersReducer.reducer;
