import "../../sass/layout/carrousel/carrousel.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import upArrow from "../../utils/img/right-arrow.png";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../utils/mixpanelIntegration";
import { useCarouselSlides } from "../../hooks/carousel/useCarousel";
import { useCarouselSpeed } from "../../hooks/carousel/useCarouselSpeed";
import { useCarouselClicked } from "../../hooks/carousel/useCarouselClicked";
import UserService from '../../services/api/UserService';

const Carrousel = () => {
  const location = useSelector((state) => state.homeReducer.location?.data);
  const tokenMedia = useSelector((state) => state.homeReducer.tokenMedia);
  const memberID = location?.uniqueNonGuessableId;
  const carouselSpeed = useCarouselSpeed();
  const carouselSlides = useCarouselSlides(memberID);
  const [clicked, setClicked] = useCarouselClicked(carouselSlides, carouselSpeed);


  const showData = carouselSlides?.find((slide) =>
    clicked != null
      ? slide?.id === clicked
      : slide?.id === carouselSlides[0]?.id
  );

  const style = {
    background: `url(${showData?.attributes.image?.data?.attributes?.url}?${tokenMedia})`,
  };


  const getSlideUrl = (slide) => {
    const url = slide?.attributes?.file?.url || slide?.attributes?.url;
    // If the link is a link to a file in the miia private azure storage system it needs a tokenMedia to get access
    // If the link is not a link to a file in the miia private azure storage system it should not have the tokenMedia
    // Links to private files have this structure name.blob.core.windows....
    const isLinkToPrivateFile = decodeURIComponent(url).includes("blob.core.windows");
    return`${url.startsWith("http") ? "" : "https://"}${url}${isLinkToPrivateFile ? "?" +  tokenMedia : ""}`;
  }

  return (
    <Container fluid className="carrousel-home-container">
      {carouselSlides?.length > 0 && (
        <Col className="carrousel-images-container" style={style}>
          <Row className="carrousel-content-card">
            <a
              href={getSlideUrl(showData)}
              target="_blank"
              className="a-carrousel-link"
              rel="noreferrer"
              onClick={() => {
                Mixpanel.track("Carousel Clicked", {
                  isLogged: UserService.isLoggedIn(),
                  "Carousel Title": showData?.attributes.title,
                  locationName: location?.name,
                });
              }}
            >
              <Col className="carrousel-text">
                <h5 className="h5-carrousel">
                  <strong>{showData?.attributes.title}</strong>
                </h5>
                <p className="description-view">
                  {showData?.attributes.description}
                </p>
              </Col>
              <Col className="see-more">
                <img
                  className="arrow-right"
                  alt="next slide"
                  src={upArrow}
                ></img>
              </Col>
            </a>
          </Row>
          <Container fluid className="carrousel-pagination-card">
            {carouselSlides?.map((element) => {
              return (
                <Button
                  key={element?.id}
                  value={element?.id || carouselSlides[0]?.id}
                  onClick={(e) => setClicked(parseInt(e.target.value))}
                  bsPrefix="carrousel-btns"
                  style={
                    clicked === element?.id
                      ? { backgroundColor: "#1A1919" }
                      : {}
                  }
                />
              );
            })}
          </Container>
        </Col>
      )}
    </Container>
  );
};

export default Carrousel;
