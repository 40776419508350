import { combineReducers } from "redux";
import articleReducer from "./articleReducer";
import homeReducer from "./homeReducer";
import recipeReducer from "./recipeReducer";
import wellnesseventsReducer from "./wellnesseventsReducer";
import userReducer from "./userReducer";
import locationReducer from "./locationReducer";
import usersReducer from "./usersReducer";
import reportsReducer from "./reportsReducer";
import reportReducer from "./reportReducer";
import searchReducer from "./searchReducer";
import optionReducer from "./optionReducer";
import programReducer from "./programReducer";

const rootReducer = combineReducers({
  recipeReducer,
  articleReducer,
  usersReducer,
  userReducer,
  wellnesseventsReducer,
  homeReducer,
  locationReducer,
  reportsReducer,
  reportReducer,
  searchReducer,
  optionReducer,
  programReducer,
});

export default rootReducer;
