import "../../sass/layout/riskManagment/riskManagment.css";
import { Container } from "react-bootstrap";
import upArrow from "../../utils/img/right-arrow.png";
import { NavLink } from "react-router-dom";

const RiskManagment = () => {
  return (
    <Container fluid className="riskManagment-home-container">
      <NavLink to="riskManagement" className="a-riskManagment-link">
        <div className="riskManagment-images-container">
          <div className="riskManagment-text">
            <h5 className="h5-riskManagment">
              <strong>Risk Management</strong>
            </h5>
            <h5 className="h5-riskManagment2">Programs</h5>
          </div>
          <div className="arrow-right">
            <img src={upArrow}></img>
          </div>
        </div>
      </NavLink>
    </Container>
  );
};

export default RiskManagment;
