import "../../sass/layout/articlesList/articlesList.css";

const PrivacyPolicies = () => {
  return (
    <div className="">
      <div className="col-sm-8 offset-sm-2">
        <div>
          <h4>Privacy Notice</h4>
          <p>
            Privacy Notice This Privacy Policy (or, simply, “Policy”) explains
            how we may use or disclose information that we obtain from or about
            you. We have adopted this Policy to provide transparency to you and
            to ensure the confidentiality of your personal information. By
            installing the MIIA Member Portal Mobile Application (the “App”) and
            signing in, you are accepting and consenting to be bound to the
            terms described in this Policy.
          </p>
          <p>
            This App is operated by The Massachusetts Interlocal Insurance
            Association, Inc. (“MIIA” or, simply, “we”, “us” or “our”) whose
            registered address is 3 Center Plaza, Suite 610, Boston, MA 02108.
            We are committed to protecting and preserving the privacy of our
            users when using our App or communicating electronically with us.
          </p>
          <p>
            This Policy sets out how we process any personal data we collect
            from you or that you provide to us through our App and website. We
            confirm that we will keep your information secure and that we will
            comply fully with all applicable United States data protection
            legislation and regulations. Please read the following carefully to
            understand what happens to personal data that you voluntarily
            provide to us, or that we collect from you when you visit this site.
          </p>
          <h4>Table of Contents</h4>
          <div>
            <a href="#1">1. Types of information we may collect from you</a>
          </div>
          <div>
            <a href="#2">2. How we may use the information we collect</a>
          </div>
          <div>
            <a href="#3">3. Disclosure of your information</a>
          </div>
          <div>
            <a href="#4">4. Third party links</a>
          </div>
          <div>
            <a href="#5">5. How long do we keep your information?</a>
          </div>
          <div>
            <a href="#6">6. Do we collect information from minors?</a>
          </div>
          <div>
            <a href="#7">7. What are your privacy rights?</a>
          </div>
          <div>
            <a href="#8">8. Controls for do-not-track features</a>
          </div>
          <div>
            <a href="#9">
              9. Do California residents have specific privacy rights?
            </a>
          </div>
          <div>
            <a href="#10">10. Do we make updates to this notice?</a>
          </div>
          <div>
            <a href="#11">11. How can you contact us about this notice?</a>
          </div>
          <br />
          <h4 id="1">1. Types of information we may collect from you</h4>
          <p>
            <div>
              <strong>Non-Personal Information automatically collected</strong>
            </div>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> Some information – such as your
              Internet Protocol (IP) address and/or browser and device
              characteristics – is collected automatically when you visit our
              website or when using our App.
            </i>
          </p>
          <p>
            We may collect, store and use certain information, including
            non-personal information, about individuals who visit, use or
            navigate to our website or use our App or through the use of
            commonly-used information gathering tools. These tools may also
            include “Cookies”. Non-personal information does not reveal your
            specific identity, but may include technical information such as a
            truncated and anonymized version of your Internet Protocol (IP)
            address, browser type and version, operating system and platform;
            also, information referred to functionality and error delivered by
            the app stores.
          </p>
          <p>
            Like many businesses, we may also collect information through
            Cookies and similar technologies. The App might use Cookies from the
            third-party platform that delivers the sign in service, which are
            aimed to provide more security to the log in process.
          </p>
          <p>
            <strong>Personal information you voluntarily disclose to us</strong>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> We collect personal information that
              you voluntarily provide to us.
            </i>
          </p>
          <p>
            You may voluntarily supply us with personal information (or “PII”)
            about you through our website. This includes information you provide
            when you submit the following: Certificate of Insurance requests,
            claim forms, and applications. The information you give us may
            include your name, address, e-mail address and phone number,
            information related to dates and locations, and digital documents.
            PII also includes any written text or communications, notes, or
            inquires that you provide on our website or by e-mail communication
            directly or indirectly with us.
          </p>
          <p>The App does not collect any type of information from users.</p>
          <p>
            <h4 id="2">2. How we may use the information we collect</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> We use the information in the following
              ways:
            </i>
          </p>
          <p>
            Information you voluntarily supply to us. We will use this
            information to provide you with information and/or services that you
            request from us.
          </p>
          <p>
            Information we automatically collect about you. We will use this
            information:
            <ul>
              <li>
                to administer our App including troubleshooting and statistical
                purposes;
              </li>
              <li>
                to improve our website to ensure that content is presented in
                the most effective manner for you and for your computer;
              </li>
              <li>
                security and debugging as part of our efforts to keep our app
                safe and secure.
              </li>
            </ul>
          </p>
          <p>
            This information is collected anonymously and is not linked to
            information that identifies you as an individual. Find out how
            Google uses your data at 
            <a href="https://support.google.com/analytics/answer/6004245">
              https://support.google.com/analytics/answer/6004245
            </a>
            .
          </p>
          <p>
            <strong>Consent to Use of Personal Information:</strong>
          </p>
          <p>
            You consent to these processes by providing information including
            PII. You may update your PII, change or correct any PII, have us
            delete your PII, inquire as to our use of your PII, or “opt out”
            from receiving certain communications and correspondence from us at
            any time through email correspondence sent to{" "}
            <a href="mailto:miiaportalsupport@mma.org">
              miiaportalsupport@mma.org
            </a>
          </p>
          <p>
            <h4 id="3">3. Disclosure of your information</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> We only share information with your
              consent, to comply with laws, to provide you with services, to
              protect your rights, or to fulfill business obligations.
            </i>
          </p>
          <p>
            Any information you provide to us will either be emailed directly to
            us or may be stored on a secure server located in the United States.
            We use a trusted third-party, Keycloak, to facilitate the running
            and management of this App for the sign in process. Keycloak meets
            high data protection and security standards and is bound to keep any
            information they process on our behalf confidential. Any data that
            may be collected through this website that Keycloak processes, is
            kept secure and only processed in the manner we instruct them to.
            Keycloak cannot access, provide, rectify or delete any data that
            they store on our behalf without permission.
          </p>
          <p>
            We do not rent, sell or share PII about you with other people or
            non-affiliated companies.
          </p>
          <p>
            We consider your use of our App and website to be private. We may,
            however, access or disclose information about you and/or the content
            of your communications with us, in order to: (1) comply with the law
            or legal process served on us; (2) enforce and investigate potential
            violations of our Terms of Service, including use of this App or
            website to participate in, or facilitate, activities that violate
            the law; or (3) protect the rights, property, or safety of our
            employees, customers, or the public. From time to time, we may
            disclose or share your information with other organizations or
            entities that are affiliated with us.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of your data
            transmitted to our site; any transmission is at your own risk. Once
            we have received your information, we will use strict procedures and
            security features to try to prevent unauthorized access.
          </p>
          <p>
            You consent to the access and disclosures outlined in this section.
          </p>
          <p>
            <h4 id="4">4. Third party links</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> Our App and/or website may, from time
              to time, contain links to and from the third-party websites. If
              you follow a link to any of these websites, please note that these
              websites have their own privacy policies and that we do not accept
              any responsibility or liability for these policies. Please check
              these policies before you submit any personal data to these
              websites.
            </i>
          </p>
          <p>
            <h4 id="5">5. How long do we keep your information?</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy notice
              unless otherwise required by law.
            </i>
          </p>
          <p>
            We will only keep our personal information for as long as it is
            necessary for the purposes set out in this Policy, unless a longer
            retention period is required or permitted by law (such as tax,
            accounting or other legal requirements).
          </p>
          <p>
            <h4 id="6">6. Do we collect information from minors?</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> We do not knowingly collect data from
              or market to children under 18 years of age.
            </i>
          </p>
          <p>
            By using our App and/or website, you represent that you are at least
            eighteen (18) years of age. and that you (and your use effects)
            consent for an individual that is under age 18 or otherwise a minor
            to use of our website, whether or not under your direct supervision
            at the time.
          </p>
          <p>
            We do not knowingly solicit, collect, or retain information or data
            from individuals who are under eighteen (18) years or age, or market
            to individuals who are under age18. If we learn that PII from a user
            or users originates from someone that is under age 18, we will take
            steps to prevent that individual, or minor, from subsequent data
            entry in the future, including to deactivate the account and take
            reasonable measures to promptly delete such data from our records.
          </p>
          <p>
            If you become aware of any data we may have collected from someone
            under age 18, please contact us at{" "}
            <a href="mailto:miiaportalsupport@mma.org">
              miiaportalsupport@mma.org
            </a>
            .
          </p>
          <p>
            <h4 id="7">7. What are your privacy rights?</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> You may review, change, or terminate
              your account at any time.
            </i>
          </p>
          <p>
            Where applicable law requires (and subject to any exceptions), you
            may have the right to request MIIA to provide you with your PII or
            to delete your PII that is held by us. You may also have the right
            to restrict or object to use or processing of your PII. We will
            respond in a reasonable timely manner to requests made by you for
            access to such information. You may exercise these rights by
            contacting us through email correspondence sent to{" "}
            <a href="mailto:miiaportalsupport@mma.org">
              miiaportalsupport@mma.org
            </a>
            .
          </p>
          <p>
            In certain situations, you may have the right to lodge a complaint
            with an appropriate supervisory authority, including the Information
            Commissioner’s Office in the UK. You can find their contact details
            here:{" "}
            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            . If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:{" "}
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
          </p>
          <p>
            <h4 id="8">8. Controls for do-not-track features</h4>
          </p>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track (“DNT”) feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
          <p>
            <h4 id="9">
              9. Do California residents have specific privacy rights?
            </h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> Yes, if you are a resident of
              California, you may be granted specific rights regarding access to
              your personal information.
            </i>
          </p>
          <p>
            In 2020, California’s Consumer Privacy Act (CCPA) went into effect.
            You may have rights under the CCPA, including rights to ask us to
            disclose what PII we have about you and what we do with that
            information, to ask us to delete your PII and not sell your PII, to
            be notified of the type of PII we collect and what we do with that
            information. If California Civil Code Section 1798.83, also known as
            the “Shine The Light” law, permits users who are California
            residents to request and obtain from us, once a year and free of
            charge, information about categories of PII (if any) we disclosed to
            third parties for direct marketing purposes and the names and
            addresses of all third parties with which we shared personal
            information in the immediately preceding calendar year.
          </p>
          <p>
            If you are a California resident and would like to make such a
            request, please submit your request in writing to us using the
            contact information provided below.
          </p>
          <p>
            <h4 id="10">10. Do we make updates to this notice?</h4>
          </p>
          <p>
            <i>
              <strong>In Short:</strong> Yes, we will update this notice as
              necessary to stay compliant with relevant laws.
            </i>
          </p>
          <p>
            MIIA reserves the right to revise this Policy from time-to-time at
            our discretion. If MIIA makes modifications, we will post the
            revised Policy on the website, which will take effect immediately
            upon posting. It is your responsibility to periodically review this
            Policy and understand its contents.
          </p>
          <p>
            <h4 id="11">11. How can you contact us about this notice?</h4>
          </p>
          <p>
            Questions, comments and requests regarding this Privacy Policy are
            welcomed and should be addressed to{" "}
            <a href="mailto:miiaportalsupport@mma.org">
              miiaportalsupport@mma.org
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicies;
