import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "../../sass/layout/programListContainer/programListContainer.css";
import ProgramPreviewList from "./programPreviewList";
import Pagination from "react-bootstrap/Pagination";


const ProgramListContainer = ({ programs }) => {
  const [programsList, setProgramsList] = useState([]);
  const [active, setActive] = useState(1);
  const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);

  
  const onClickPage = (number) => {
    if (number === 1) {
      setIndex(0);
    } else {
      setIndex(4 * (number - 1));
    }
  };

  useEffect(() => {
    if (programs?.length > 0) {
      setProgramsList(programs);
      const cantPage = Math.ceil(programs.length / 4);
      if (items?.length !== cantPage) {
        for (let number = 1; number <= cantPage; number++) {
          items.push(
            <Pagination.Item
              onClick={() => onClickPage(number)}
              key={number}
              activeLabel={""}
            >
              {number}
            </Pagination.Item>
          );
        }
      }
    }
  }, [programs.length]);

  const PageButtons = () => {
    const items = [];
    const cantPage = Math.ceil(programs.length / 4);

    for (let number = 1; number <= cantPage; number++) {
      items.push(
        <Pagination.Item
          onClick={() => onClickPage(number)}
          key={number}
          activeLabel={""}
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  }

  return (
    <Container fluid className="program-container">
      {programsList.slice(index, index + 4).map((program, index) => {
        return (
          <ProgramPreviewList
            key={index}
            border={program?.id == programsList?.length - 1 ? false : true}
            program={program}
          />
        );
      })}
      <Container fluid className="pagination-programs">
        <Pagination>
          <PageButtons />
        </Pagination>
      </Container>
    </Container>
  );
};

export default ProgramListContainer;
