import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../sass/layout/divLinks/divLinks.css";
import searchIcon from "../utils/img/search.png";
import exportar from "../utils/img/share.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getButtonsInHome } from "../services/actions/home.action";
import { Mixpanel } from "../utils/mixpanelIntegration";
import UserService from "../services/api/UserService";

const DivLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useSelector((state) => state.homeReducer.location?.data);
  const buttons = useSelector((state) => state.homeReducer.buttons);

  useEffect(() => {
    if (location) {
      getButtonsInHome(dispatch, location?.uniqueNonGuessableId);
    }
  }, [dispatch, location]);

  return (
    <Container
      fluid
      className={
        buttons.length > 1
          ? "divLink-container"
          : "divLink-container moreThanOne"
      }
    >
      {buttons.map((button, index) => (
        <a
          key={index}
          href={button.link}
          className="first-box"
          target="_blanck"
          onClick={() =>
            Mixpanel.track("Button.Clicked", {
              isLogged: UserService.isLoggedIn(),
              buttonName: button.name,
              locationName: location?.name,
            })
          }
        >
          <div className="divLink-text-container">
            <div className="a-href-color-white">
              <label>{button.name}</label>
            </div>
          </div>
          <img src={exportar} className="divLink-image"></img>
        </a>
      ))}
      <a
        href="https://www.fepblue.org/pilot/login"
        className="first-box"
        target="_blanck"
      >
        <div className="divLink-text-container">
          <div>
            <label>{t("divlinks.myblue")}</label>
          </div>
        </div>
        <img src={exportar} className="divLink-image"></img>
      </a>
      <a href="https://www.mychart.com/" target="_blank" className="first-box">
        <div className="divLink-text-container">
          <label>{t("divlinks.healthPortal")}</label>
        </div>
        <img src={searchIcon} className="divLink-image"></img>
      </a>
      <a className="first-box">
        <div className="divLink-text-container">
          <label>{t("divlinks.phone")}</label>
        </div>
      </a>
    </Container>
  );
};

export default DivLinks;
