import { Row, Col } from "react-bootstrap";
import "../../sass/layout/homePage/homepage.css";
import "../../sass/layout/riskManagment/riskManagment.css";
import Notification from "../notifications/notification";
import { useState } from "react";
import ProgramRisk from "../programs/risk-management/programs-risk";
import { useTranslation } from "react-i18next";
import osha from "../../utils/img/risk-managment/osha.png";
import bestPractices from "../../utils/img/risk-managment/best-practices.png";
import advisories from "../../utils/img/risk-managment/advisories.png";
import { REGISTER_FOR_A_TRAINING } from "../../utils/constants";

const RiskManagmentLanding = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  return (
    <div className="homePage-container">
      <Col md={12} xl={12} className="homePage-content">
        <Row style={{ padding: "0rem 1rem", margin: "0px" }}>
          <Notification
            setShow={setShow}
            show={show}
            notificationpage={true}
            className="homePage-notification"
          />
        </Row>
        <Row style={{ padding: "0rem", margin: "0px" }}>
          <Col md={12} xl={12} className="homePage-programs">
            <ProgramRisk />
          </Col>
          <Col className="col-img-risk">
            <div className="img-risk-managment">
              <h3>{t("riskmanagement.riskTraining")}</h3>
              <Row className="row-risk">
                <Col className="col-risk left-corner">
                  <a
                    className="link-risk"
                    href={REGISTER_FOR_A_TRAINING}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t("riskmanagement.register")}
                  </a>
                </Col>
                <Col className="col-risk">
                  <a
                    className="link-risk"
                    href="https://www.emiia.org/risk-management-resources/schedule-a-training"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("riskmanagement.request")}
                  </a>
                </Col>
                <Col className="col-risk">
                  <a
                    className="link-risk"
                    href="https://www.emiia.org/risk-management-resources/recordings-handouts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("riskmanagement.recorded")}
                  </a>
                </Col>
                <Col className="col-risk right-corner">
                  <a
                    className="link-risk"
                    href="https://www.emiia.org/risk-management-resources/osha-municipal-cert-programs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("riskmanagement.osha")}
                  </a>
                </Col>
              </Row>
            </div>
          </Col>
          <Row className="row-risk-items">
            <Col className="col-risk-item">
              <a href="https://www.emiia.org/risk-management-resources/communications-archive">
                <h4 className="h4-risk h4-risk-first">
                  {t("riskManagment.advisories")}
                </h4>
                <img src={advisories} alt="advisories"></img>
              </a>
            </Col>
            <Col className="col-risk-item">
              <a href="https://www.emiia.org/risk-management-resources/best-practices-and-resources">
                <h4 className="h4-risk">{t("riskManagment.bestPractices")}</h4>
                <img src={bestPractices} alt="bestPractices"></img>
              </a>
            </Col>
            <Col className="col-risk-item">
              <a href="https://www.emiia.org/risk-management-resources/osha-municipal-cert-programs">
                <h4 className="h4-risk">{t("riskManagment.osha")}</h4>
                <img src={osha} alt="osha"></img>
              </a>
            </Col>
          </Row>
        </Row>
      </Col>
    </div>
  );
};

export default RiskManagmentLanding;
