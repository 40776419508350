import "../../sass/layout/welcome/welcome.css";
import { Row, Col } from "react-bootstrap";
import "../../sass/layout/homePage/homepage.css";
import FeaturedArticules from "../featuredArticules";
import FeaturedRecipes from "../featuredRecipes";
import TwitterTimelineComponent from "../twitterTimeline";
import { useDispatch } from "react-redux";
import Notification from "../notifications/notification";
import { useEffect, useState } from "react";
import {
  getFeaturedNews,
  getTokenMedia,
} from "../../services/actions/home.action";
import UserService from "../../services/api/UserService";
import { getNotificationsWithoutLocation } from "../../services/actions/home.action";
import { setStatus } from "../../services/actions/report.action";
import NewsLetter from "./newsletter";
import { setLocation } from "../../services/reducers/homeReducer";

const showTwitterFeed = process.env.REACT_APP_SHOW_TWITTER_FEED === "true";

const Welcome = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const isUserLoggedIn = UserService.isLoggedIn();

  useEffect(() => {
    if (isUserLoggedIn) {
      getFeaturedNews(dispatch);
    }
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (!isUserLoggedIn && window.location.pathname === "/") {
      dispatch(setLocation({ location: null }));
    }
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    getNotificationsWithoutLocation(dispatch);
    getTokenMedia(dispatch);
    setStatus(dispatch);
  }, [dispatch]);

  return (
    <div className="homePage-container main-content">
      <Col md={12} xl={12} className="homePage-content">
        <Row style={{ padding: "0rem 1rem", margin: "0px" }}>
          <Notification
            setShow={setShow}
            show={show}
            notificationpage={true}
            className="homePage-notification"
          />
        </Row>
        <Row className="homePage-content-img">
          <Col sm={12} md={12} className="homePage-img-container">
            <div className="img-homepage">
              Welcome to <strong className="strong-miia">MIIA</strong>
              Member Portal
            </div>
          </Col>
        </Row>
        <Row style={{ padding: "0px", margin: "0px", marginBottom: "-5rem" }}>
          {showTwitterFeed && (
            <Col md={{ order: 1 }} xl={6} className="homePage-twitter-timeline">
              <TwitterTimelineComponent />
            </Col>
          )}

          <Col md={{ order: 1 }} xl={12}   className="homePage-featured-articles">
            <NewsLetter />
          </Col>
        {/* </Row>

        <Row style={{ padding: "0px", marginTop: "7rem" }}> */}
          <Col md={{order: 2}} xl={6} className="homePage-featured-recipes" style={{marginTop: "1rem"}} >
            <FeaturedRecipes />
          </Col>
          <Col md={{order: 2}} xl={6} className="homePage-featured-recipes" style={{marginTop: "1rem"}}>
            <FeaturedArticules />
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Welcome;
