import "../sass/layout/titlePage/titlePage.css";
import Container from 'react-bootstrap/Container';

const TitlePage = ({ boldText, commonText }) => {
    return (
        <Container fluid className="title-page">
            <h3>
                <b>{boldText} </b>
                {commonText}
            </h3>
        </Container>
    );
};

export default TitlePage;