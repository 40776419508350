import axios from "axios";
import UserService from "./UserService";
import i18n from "i18next";

// Add a request interceptor
axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      "accept-language": i18n.language,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    // add X-Frame-Options to response header
    response.headers["X-Frame-Options"] = "SAMEORIGIN";
    return response;
  },
  async function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;

export const authHeader = () => {
  if (UserService.getToken()) {
    UserService.updateToken();
    return { Authorization: `Bearer ${UserService.getToken()}` };
  } else {
    return new Error("Problemas para obtener el token de keycloak");
  }
};
