import { createSlice } from "@reduxjs/toolkit";

const userReducer = createSlice({
  name: "counter",
  initialState: {
    user: null,
    loginStatus: false,
    errorUser: null,
    infoUser: null,
    userDetail: null,
    token: null,
  },
  reducers: {
    setLoginStatus: (state, action) => {
      state.loginStatus = action.payload.loginStatus;
      state.token = action.payload.token;
      state.errorUser = null;
    },
    setInfoUser: (state, action) => {
      state.infoUser = action.payload.infoUser;
      state.errorUser = null;
    },
    setUserError: (state, action) => {
      state.errorUser = action.payload.errorUser;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload.userDetail;
    },
    cleanState: () => {
      return userReducer.initialState;
    },
    cleanUserEdit: (state, action) => {
      state.userDetail = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoginStatus,
  setUserDetail,
  cleanState,
  setUserError,
  setInfoUser,
  cleanUserEdit,
} = userReducer.actions;

export default userReducer.reducer;
