// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  background-color: rgb(255, 255, 255);
  align-self: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.item {
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 10px;
  justify-content: flex-start !important;
  align-items: center !important;
  align-content: center !important;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/itemContainerGeneral/itemContainerGeneral.scss","webpack://./src/sass/layout/itemContainerGeneral/itemContainerGeneral.css"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;ACDF;;ADIA;EACE,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sCAAA;EACA,8BAAA;EACA,gCAAA;ACDF","sourcesContent":[" \n\n.content {\n  background-color: rgb(255, 255, 255);\n  align-self: center;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n}\n\n.item {\n  background-color: rgb(255, 255, 255);\n  border-radius: 12px;\n  padding: 15px;\n  margin-bottom: 10px;\n  justify-content: flex-start !important;\n  align-items: center !important;; \n  align-content: center !important;\n}\n\n",".content {\n  background-color: rgb(255, 255, 255);\n  align-self: center;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n}\n\n.item {\n  background-color: rgb(255, 255, 255);\n  border-radius: 12px;\n  padding: 15px;\n  margin-bottom: 10px;\n  justify-content: flex-start !important;\n  align-items: center !important;\n  align-content: center !important;\n}/*# sourceMappingURL=itemContainerGeneral.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
