import {
  setErrorRecipes,
  setInitialRecipes,
  setRecipes,
  setRecipesMeta,
} from "../reducers/recipeReducer";
import axios from "../api/HttpService";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchInitialRecipes = async (dispatch) => {
  try {
    axios
      .get(API_URL + "api/recipes?populate=image&pagination[pageSize]=10&sort=order:desc")
      .then((response) => {
        dispatch(setInitialRecipes({ recipesHome: response.data.data }));
      });
  } catch (error) {
    dispatch(setErrorRecipes({ errorRecipes: error.response.data }));
  }
};

export const fetchRecipes = async (dispatch, page, pageSize) => {
  try {
    axios
      .get(API_URL + `api/recipes?populate=image&pagination[pageSize]=${pageSize}&pagination[page]=${page}&sort=order:desc`)
      .then((response) => {
        dispatch(setRecipes({ recipesList: response.data.data }));
        dispatch(setRecipesMeta({ recipesMeta: response.data.meta }));
      });
  } catch (error) {
    dispatch(setErrorRecipes({ errorRecipes: error.response.data }));
  }
};

export const fetchRecipeById = async (id) => {
  try {
    const recipe = await axios.get(API_URL + `api/recipes/${id}`);
    return recipe;
  } catch (error) {
    console.log(error);
    return { data: null };
  }
};