import "../../sass/layout/programsList/programsList.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgramListContainer from "../programs/programsListContainer";
import MenuPath from "../path/MenuPath";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import miiaGrants from "../../utils/img/risk-managment/miia-grants.png";
import cybernet from "../../utils/img/risk-managment/cybernet.png";
import eap from "../../utils/img/risk-managment/eap.png";
import enquiron from "../../utils/img/risk-managment/enquiron.png";
import localGov from "../../utils/img/risk-managment/localgov.png";
import mfaa from "../../utils/img/risk-managment/mfaa.png";
import miiaAgility from "../../utils/img/risk-managment/miia-agility.png";
import miiaWinners from "../../utils/img/risk-managment/miia-winners.png";
import oshaCertificate from "../../utils/img/risk-managment/osha-certificate.png";
import police from "../../utils/img/risk-managment/police.png";

const RiskProgramsList = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const programsRisk = [{
      attributes: {
        title: "MIIA Grants",
        description:
          "",
        image: miiaGrants,
        link: "https://www.emiia.org/risk-management-resources/grant-program",
      },
    },
    {
      attributes: {
        title: "MIIA's Employee Assistance Program",
        description:
          "",
        image: eap,
        link: "https://myassistanceprogram.com/miia-eap/",
      },
    },
    {
      attributes: {
        title: "Cybernet",
        description:
          "",
        image: cybernet,
        link: "https://www.emiia.org/cyber-liability-protection/coverage-overview",
      },
    },
    {
      attributes: {
        title: "LocalGov",
        description:
          "",
        image: localGov,
        link: "https://www.localgovu.com/miia/",
      },
    },
    {
      attributes: {
        title: "Osha Certificate",
        description:
          "",
        image: oshaCertificate,
        link: "https://www.emiia.org/risk-management-resources/osha-municipal-cert-programs",
      },
    },
    {
      attributes: {
        title: "Enquiron",
        description:
          "",
        image: enquiron,
        link: "https://www.emiia.org/risk-management-resources/enquiron",
      },
    },
    {
      attributes: {
        title: "MFAA",
        description:
          "",
        image: mfaa,
        link: "https://www.emiia.org/risk-management-resources/231/view-additional-resource?title=MFAA+Sponsorship+Program",
      },
    },
    {
      attributes: {
        title: "Police Certification Accreditation Program",
        description:"",
        image: police,
        link: "https://www.emiia.org/risk-management-resources/20/view-additional-resource?title=Police+Certification-Accreditation+Program",
      },
    },
    {
      attributes: {
        title: "MIIA Agility",
        description:"",
        image: miiaAgility,
        link: "https://www.emiia.org/risk-management-resources/disaster-recovery",
      },
    },
  ];


  return (
    <div>
      <Row className="row-box-container">
        <Col className="container-data-programs-list">
          <Row className="row-menu-container">
            <MenuPath name={t("programs.programs")} />
          </Row>
          <ProgramListContainer programs={programsRisk} />
        </Col>
      </Row>
    </div>
  );
};

export default RiskProgramsList;
