import {
  setInitialUserEdit,
  setInitialUsers,
  setUserEdit,
  fetchProductList,
  memberCreated,
  fetchRoleList,
  fetchDropdownsList,
  fetchAssociatedDropdownsList,
  setProductsPurchased,
  setMIIAContacts,
  memberCreatedError,
  setSectionsAndReports,
  setDropdownSelectOptions,
} from "../reducers/usersReducer";

import axios, { authHeader } from "../api/HttpService";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchInitialUsers = async (dispatch, page, pageSize) => {
  try {
    const response = await axios.get(
      API_URL +
        `api/miia-users/findPortalUsers?extended=true&populate=portal_user_role&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
      {
        headers: authHeader(),
      }
    );
    dispatch(setInitialUsers({ users: response.data }));
  } catch (error) {
    console.log("api/miia-users/findPortalUsers", error.response.data);
  }
};

export const userEditPlaceholder = async (dispatch, user) => {
  try {
    await axios
      .get(API_URL + `api/miia-users/findOnePortalUser/${user.id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(setInitialUserEdit({ users: response.data.data }));
      });
  } catch (error) {
    console.log(error);
  }
};

export const userEditInfo = async (dispatch, body, id) => {
  try {
    const response = await axios.put(
      API_URL + `api/miia-users/updatePortalUser/${id}`,
      body,
      {
        headers: authHeader(),
      }
    );
    dispatch(setUserEdit({ users: response }));
  } catch (error) {
    console.log(`api/miia-users/updatePortalUser/${id}`, error.response.data);
  }
};

export const fetchProducts = async (dispatch) => {
  try {
    await axios
      .get(API_URL + "api/products?_sort=title", {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(fetchProductList({ products: response.data }));
      });
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (dispatch, id) => {
  try {
    await axios
      .delete(API_URL + `api/miia-users/deletePortalUser/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(
          deleteUser({ deletedUser: response.data }),
        );
      });
  } catch (error) {
    console.log(error);
  }
};

export const createMember = async (dispatch, user, dropdowns, roleDetails) => {
  const { email, first_name, last_name, role, title, location } = user;

  const roleSectionsAndReports = roleDetails?.sections_and_reports?.map(
    ({ id }) => `${id}`
  );

  const portal_user_custom_entitlements_allowed_sections_and_reports =
    dropdowns.filter((sr) => !roleSectionsAndReports?.includes(sr));

  const portal_user_custom_entitlements_denied_sections_and_reports =
    roleSectionsAndReports.filter((sr) => !dropdowns?.includes(sr));

  var data = {
    data: {
      email: email,
      location: location,
      first_name: first_name,
      last_name: last_name,
      title: title,
      portal_user_custom_entitlements_allowed_sections_and_reports,
      portal_user_custom_entitlements_denied_sections_and_reports,
      portalUserRoleId: role,
      portal_user_custom_entitlements_user_administration: "NONE",
    },
  };

  try {
    const response = await axios.post(
      API_URL + "api/miia-users/createPortalUser?sendEmail=true",
      data,
      {
        headers: authHeader(),
      }
    );
    dispatch(memberCreated({ users: response.status }));
  } catch (error) {
    dispatch(memberCreatedError({ usersError: error }));
  }
};

export const resetStatusCode = async (dispatch) => {
  dispatch(memberCreatedError({ usersError: 0 }));
};

export const fetchRoles = async (dispatch) => {
  try {
    const response = await axios.get(API_URL + "api/portal-user-roles", {
      headers: authHeader(),
    });
    dispatch(fetchRoleList({ roles: response.data }));
  } catch (error) {
    console.log("Error api/portal-user-roles: ", error.response.data);
  }
};

export const fetchAllRoles = async (dispatch) => {
  try {
    const response = await axios.get(
      API_URL + "api/portal-user-roles/options",
      {
        headers: authHeader(),
      }
    );
    dispatch(fetchRoleList({ roles: response.data }));
  } catch (error) {
    console.log("Error api/portal-user-roles/options: ", error.response.data);
  }
};

export const fetchDropdowns = async (dispatch, id) => {
  try {
    const response = await axios.get(API_URL + `api/dropdowns/location/${id}`, {
      headers: authHeader(),
    });
    dispatch(fetchDropdownsList({ dropdowns: response.data }));
  } catch (error) {
    console.log("api/dropdowns fetchDropdowns", error.response.data);
  }
};

export const fetchAssociatedDropdowns = async (dispatch, id) => {
  try {
    const response = await axios.get(API_URL + `api/portal-user-roles/${id}`, {
      headers: authHeader(),
    });
    dispatch(
      fetchAssociatedDropdownsList({
        dropdownAvailable: response.data.data,
      })
    );
  } catch (error) {
    console.log("error api/portal-user-roles/${id}", error.response.data);
  }
};

export const fetchPurchasedProducts = async (dispatch, id) => {
  //when there are more locations, connect the appropriate identification
  try {
    await axios
      .get(API_URL + `api/locations/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(setProductsPurchased({ products: response.data.data }));
      });
  } catch (error) {
    console.log(error);
  }
};

export const resetStatus = async (dispatch) => {
  dispatch(memberCreated({ users: null }));
};

export const fetchMIIAContacts = async (dispatch, page, pageSize) => {
  try {
    const response = await axios.get(
      API_URL +
        `api/contacts?pagination[page]=${page}&pagination[pageSize]=${pageSize}&sort=contact_role.order:asc`,
      {
        headers: authHeader(),
      }
    );
    dispatch(setMIIAContacts({ contacts: response?.data }));
  } catch (error) {
    console.log(error);
  }
};

export const fetchSectionsAndReports = async (dispatch) => {
  try {
    const response = await axios.get(API_URL + "api/dropdowns/options", {
      headers: authHeader(),
    });
    dispatch(setSectionsAndReports({ sections: response.data }));
  } catch (error) {
    console.log(error);
  }
};

export const requestPasswordReset = async (email) => {
  return axios.put(
    API_URL + `api/miia-users/requestPasswordReset`,
    { data: { email } },
    {
      headers: authHeader(),
    }
  );
};

export const fetchDropdownSelectOptions = async (dispatch) => {
  return axios
    .get(API_URL + `api/dropdowns/options`, { headers: authHeader() })
    .then(({ data }) => {
      dispatch(setDropdownSelectOptions({ dropdowns: data }));
    })
    .catch((error) => {
      console.log(error);
    });
};
