export default {
  login: {
    email: "Correo electrónico",
    password: "Contraseña",
    forgotpassword: "Olvidó su contraseña?",
    login: "Iniciar sesión"
  },
  profile: {
    healthcoverage: "Beneficios de salud | Resumen de cobertura",
    retireesonly: "Sólo retirados",
    wellnessclass: "Agenda de clases de bienestar para ",
    registerClasses: "Regístrese para clases de bienestar en línea",
    healthVisionDental: "Formularios de salud, visión y servicios dentales",
    contactenrollment: "Contacte a su responsable de inscripciones",
    classesscheduled: "Clases que agendó",
    featuredrecipes: "Recetas Destacadas",
    featuredarticles: "Artículos destacados",
    riskmanagement: "Entrenamiento en Manejo de Riesgos",
    employeeAssistanceProgram: "Programa de Asistencia para Empleados (EAP)",
  },
  recipe: {
    printThisRecipe: "Imprimir esta receta",
    seeRecipe: "Ver esta receta",
    recipes: "Recetas",
    instructions: "Instrucciones",
    ingredients: "Ingredientes",
    printThisRecipe: "Imprimir esta receta",
  },
  divlinks: {
    myblue: "Acceso instantáneo a MyBlue",
    healthPortal: "Encuentre su portal de salud",
    phone:
      "Comuníquese con Servicios para Miembros de Blue Cross: 1-800-262-2583",
  },
  article: {
    more: "Más",
    articles: "Artículos",
    seeThisArticle: "Ver este artículo",
  },
  retirees: {
    retirees: "Sólo",
    only: "retirados",
  },
  healthportal: {
    findportal: "Encuentre su portal de salud",
  },
  path: {
    home: "Página Principal",
    retirees: "Retirados",
    findyourportal: "Encuentre su portal de salud",
    programs: "Salud y Bienestar",
    healthandwellness: "Programas de ",
  },
  wellnessEvents: {
    wellnesstitle1: "Bienestar",
    wellnesstitle2: "eventos próximos",
    wellnessPath: "Clases de Bienestar",
    register: "Regístrese",
    descriprion: "DESCRIPCIÓN",
    whocanattend: "QUIÉN PUEDE ASISTIR?",
    freeProgram: "PROGRAMA GRATIS",
    registration: "REGISTRO",
    noInformation: "No hay datos disponibles",
    noClassSizeLimit: "Sin límite",
    extraInstructions: "INSTRUCCIONES EXTRA",
    serviceProvider: "PROOVEDOR DEL SERVICIO",
    classLimit: "LÍMITE DE TAMAÑO DE LA CLASE",
    date: "FECHA",
    time: "HORA",
    registrationInfo: "INFORMACIÓN DE REGISTRO",
    location: "UBICACIÓN",
    locationInstructions: "INSTRUCCIONES DE LA UBICACIÓN",
    registerOnsite:
      "Por favor regístrese con Mary Harrington en mharrington@mma.org. Los miembros de MIIA/Blue Cross tienen prioridad en el registro cuando hay problemas de espacio. Por favor informe a Mary si es un miembro de  MIIA/Blue Cross. Sólo los miembros de  MIIA/Blue Cross son elegibles para premios.",
  },
  utils: {
    by: "Por",
  },
  contact: {
    link: "Contacte al equipo de MIIA",
    title: "Contacte su equipo de MIIA",
    department: "Departamento",
    name: "Nombre Completo",
    email: "Correo Electrónico",
    phoneNumber: "Número de Teléfono",
    position: "Posición",
  },
  riskManagment: {
    advisories: "Avisos/ Comunicaciones",
    bestPractices: "Mejores Prácticas",
    osha: "Recursos de OSHA",
  },
  programs: {
    miiaWinners: "Programas Online, video, recetas.",
    headSpace: "Aplicación de Mindfulness",
    quizzify: "Literatura de Salud",
    omPractice: "Clases de Yoga",
    mindWise: "Examen de Salud Mental",
    learnToLive: "Herramienta de Comportamiento de Saludo",
    goodHealth: "Administración de la Diabetes",
    smartShopper: "Herramienta de ahorro de Salud",
    miiaEmployee: "Programa de Asistencia al Empleado",
    programs: "Programas",
    miiaGrants: "Programa de gestión de riesgos y subvenciones flexibles",
    cybernet: "Plataforma de formación y recursos cibernéticos",
    eap: "Programa de Asistencia al Empleado",
    enquiron: "Plataforma Online de Recursos Humanos",
    localGov: "Plataforma de E-learning",
    mfaa: "Programa de Patrocinio MIIA/MFAA",
    miiaAgility: "Programa de preparación y recuperación ante desastres",
    oshaCertificate: "Programa de Fundamentos de Seguridad y Salud de OSHA",
    police: "Programa de Reembolso de Certificación/Acreditación Policial",
    viewAll: "Ver descripcion de programas",
  },
  notifications: {
    title: "Notificaciones",
  },
  navbar: {
    pcInsuranceRecords: "Registros de seguros de P&C",
    certificatesOfInsurances: "Solicite un certificado de Seguro (COI)",
    contracts: "Cotnrato",
    reportAClaim: "Reporte un Reclamo",
    contractSchedules: "Agenda de contratos para el 1/1",
    declarationPages: "Páginas de Declaración",
    endorsementActivitySummary: "resumen de actividad de respaldo",
    premiumBreakdown: "Solicitar un Detalle Premium",
    fleet: "Flota",
    fleetmanagement: "Manejo de Flota",
    cyberLiaibility: "Responsabilidad Cibernética",
    applications: "Aplicaciones",
    coverageChangeRequests: "Formularios de solicitud de cambio de cobertura",
    autoChangeRequest: "Solicitud de Cambio Vehicular",
    coverageForms: "Formularios de Cobertura",
    payroll: "Nómina",
    autoChangeRequestTransfer: "Dpto. de solicitud de transferencia vehicular",
    equipmentChangeRequest: "Solicitud de cambio de equipamento",
    professionalLiabilitySchool: "Responsabilidad Profesional - Consejo Escolar",
    professionalLiabilityPublicOfficials:
      "Responsabilidad Profesional - Oficiales Públicos",
    professionalLiabilityLawEnforcement:
      "Responsabilidad Profesional - Cumplimiento de la Ley",
    statementsOfValues: "Statements of Values",
    specialPropertySupplement: "Declaraciones de Valores",
    schedules: "Agendas de Contratos (ver e imprimir)",
    bluecrossshieldbenefits: "Beneficios de Blue Cross Blue Shield",
    coverageRecs: "Formularios de cobertura de P&L",
    reportaClaim: "Reporte un Reclamo",
    certificatesAndInvoices: "Certificados y Facturas",
    enroll_training: "Como inscribirse - video instruccional",
    workersCompensation: "Compensación de Trabajadores",
    workersCompensationPostingNotice: "Aviso de publicación de compensación laboral",
    propertyLiability: "Responsabilidad y Propiedad",
    pandlcontractperiodreports: "Resumen de reportes de contrato de P&L",
    propertyCassualty: "Propiedad y Accidentes",
    professionalLiability: "Responsabilidad Profesional",
    pandlOpenClaimsDetail: "Detalle de reclamos abiertos de P&L",
    workersCompOpenClaimsDetail: "Detalle de reclamos abiertos de compensación laboral",
    cyberLiability: "Responsabilidad cibernética / reporte una vulnerabilidad",
    WCAuditActivity: "Actividad de Auditoría de WC",
    firstReport: "Primer Reporte",
    auto: "Auto",
    current: "Actual",
    previous: "Anterior",
    unusedCredits: "Créditos sin uso",
    property: "Propiedad",
    generalLiability: "Responsabilidad General",
    unemployment: "Servicios de Desempleo",
    billingStatementsInvoices: "Facturación",
    billing: "Facturación",
    memberStatements: "Declaraciones de Miembros",
    endorsementActivity: "Actividad de Respaldo",
    invoces: "Certificados y Facturas",
    rewardsStatusReports: "Reporte de Estado de Recompensas de MIIA",
    training: "Capacitación",
    registertraining: "Regístrese para una capacitación",
    historytraining: "Historial de capacitaciones",
    recordedtraining: "Capacitaciones grabadas",
    trainingCertificate: "Solicite certificados de capacitación",
    requesttraining: "Solicitar una capacitación",
    oshatraining: "Capacitación OSHA",
    oshaChecklist: "Lista de cheques OSHA",
    oshaFormLog: "Registros de formulario 300 de OSHA",
    oshaFormSumarry: "Resumen del formularo 300 de OSHA",
    unemploymentService: "Desempleo",
    fnp: "FNP",
    utmc: "UTMC",
    riskManagement: "Manejo de Riesgos",
    grantsrmflex: "Subsidios (RM y Flex)",
    advisoryCommunication: "Asesoramiento/Comunicaciones",
    bestPracticesAndResources: "Buenas Prácticas y Recursos",
    rewards: "Recompensas",
    servicePlan: "Plan de Servicio",
    updateFleetSchedules: "Actualizar Agendas de Flota",
    resources: "Recursos",
    logogo: "LocalGovU",
    injuredOnDuty: "Policía, Bomboeros, Accidentes - Lesionados en Deber",
    enquiron: "Enquiron",
    agilityPreparedness: "Agilidad/Preparación - Recuperación de Desastres",
    employeeAssistance: "Programa de Asistencia de Empleados (EAP)",
    cybernet: "CyberNET",
    drugAndAlcohol: "Pruebas de Drogas y Alcohol",
    mffasponsor: "Espónsor de MFAA",
    wellness: "Bienestar",
    health: "Salud",
    claimexperience: "Informes de experiencia de reclamo",
    lossruns: "Registros de Pérdida",
    wcsummary: "Resumend de Reporte de WC",
    wcCheckregister: "Registro de Cheques de WC",
    seeAll: "Ver Todo",
    actionPlan: "Plan de Acción",
    sensitiveInformation: "Información Sensible",
    employeeAssistanceProgram: "Programa de Asistencia de Empleados (EAP)",
    winnersMiialogin: "Iniciar sesión en MIIA Winners",
    wellnessGrantprogram: "Programa de Subsidios de Bienestar",
    scheduleWellnessClass: "Agendar una Clase de Bienestar",
    ekit: "eKit",
    enrollmentsForms: "Formularios de Registro",
    supportingforms: "Formularios de Soporte",
    bluecrossshield: "Blue Cross Blue Shield",
    workersComp: "Registro de Cheques de Compensación Laboral",
    uspenrollmentApplication: "Aplicación de Registro de USP",
    portalAdministration: "Administración del Portal",
  },

  portalAdministration: {
    title: "Administración del Portal",
    createUser: "CREAR USUARIO",
    titleEditUser: "EDITAR USUARIO",
    deleteUser: "ELIMINAR USUARIO",
    createUserTag: "Crear un Usuario",
    noContact: "Ningún Contacto ha sido encontrado",
  },
  portalAdministrationList: {
    title: "Portal de administración",
    name: "NOMBRE",
    email: "EMAIL",
    role: "ROL",
    status: "ESTADO",
    title: "TITULO",
    edit: "EDITAR",
    delete: "ELIMINAR",
  },
  editUser: {
    name: "Nombre",
    role: "Rol",
    title: "Título",
    email: "Correo Electrónico",
    password: "Contraseña",
    lastName: "Apellido",
    dropdown1: "Salud",
    dropdown2: "Reportar un Reclamo",
    dropdown3: "Facturación",
    dropdown4: "Capacitación",
    dropdown5: "Desempleo",
    dropdown6: "Manejo de Riesgos",
    dropdown7: "Recursos",
    dropdown8: "Bienestar",
    dropdown9: "Informes de Experiencia de Reclamo",
    dropdown10: "Información Sensible",
    dropdown11: "Propiedad y Responsabilidad",
    dropdown12: "Editar y Crear Usuarios",
    cancel: "Cancelar",
    saveChanges: "Guardar Cambios",
    discard: "Descartar Cambios",
    success: "El usuario ha sido editado exitosamente",
    successCreate: "El usuario ha sido creado exitosamente",
    editUser: "Editar Usuario",
    ok: "Ok",
    accesssettings: "Opciones de Acceso",
    sectionsreports: "Secciones y Rportes",
    lostchanges:
      "Está seguro de que desea cancelar la edición? Sus cambios serán perdidos.",
    selectRole: "Seleccione un Rol",
  },

  claimform: {
    memberCommunityDistrict: "Miembro de la comunidad/Distrito",
    memberContactPerson: "Persona de contacto del miembro",
    memberContactEmail: "Correo electrónico del miembro",
    contactPhoneNumber: "Número de teléfono de contacto",
    dateOfIncident: "Fecha del incidente",
    locationOfIncident: "Ubicación del incidente",
    descriptionOfIncident: "Descripción del incidente",
    fullnameclaimpresenter: "Nombre completo del presentador de reclamos",
    claimantphonenumber: "Número de teléfono del reclamante",
    claimantAdress: "Dirección del reclamante",
    claimantAdressLine2: "Dirección del reclamante 2",
    claimantCity: "Ciudad del reclamante",
    typeVehicle: "Tipo de vehículo",
    districtDepartment: "Departamento del distrito",
    generalcomments: "Comentarios generales",
    titleBoxData: "IMPORTANTE",
    bodyClaims1:
      "Los reclamos, o reclamos potenciales pueden darse de varias formas. Algunos pueden ser asuntos obvios de reportar y otros pueden resultar confusos. Su regla general debería ser:",
    itemBoxData1: "Ante la duda, ¡Reporte!",
    bodyClaims2:
      "Al hacerlo, protegerá el monto de la cobertura solicitada y nos asistirá en conducir investigaciones inmediatas y efectivas. Ayúdenos a controlar situaciones potencialmente problemáticas y permita la pronta intervención o consejo legal según sea apropiado.",
    itemBoxData2:
      "Reporte todos los reclamos o potenciales reclamos inmediatamente, incluso si no todos los detalles están disponibles en el momento.",
    itemBoxData3: "Qué reportar:",
    report1: "Daños o pérdidas en vehículos, edificios o propiedad municipal.",
    report2:
      "Daños o pérdidas en vehículos de otros, potencial o presuntamente causados por la municipalidad o sus empleados",
    report3: "Lesiones en empleados municipales, agentes o voluntarios",
    report4:
      "Lesiones o pérdidas de otros, potencial o presuntamente causadas por la municipalidad o sus empleados",
    itemBoxData4: "Qué incluir al reportar:",
    report5: "Fecha de la pérdida o el incidente",
    report6: "Persona de contacto en la ubicación, con número de teléfono",
    report7: "Año, modelo y marca del vehículo municipal para reportes vehiculares",
    report8:
      "Nombre, dirección y número de teléfono de la persona o entidad involucrada en el incidente.",
    report9: "Breve descripción del incidente o alegato",
    report10:
      "Toda correspondencia, documentos, reportes o noticias asociadas con el incidente",
    cancel: "Cancelar",
    submitClaim: "Enviar",
    titleForm: "Envío de formulario de reclamo de Responsabilidad de Propiedad",
    claimreport: "Reporte de reclamo",
    propertyLiability: "Responsabilidad de propiedad",
    attachFiles: "Adjuntar archivos",
  },
  reports: {
    data: "Data",
    wareHouse: "Warehouse",
  },
  coi: {
    title: "Solicitar un certificado de seguro",
    namebussiness: "Nombre de la empresa",
    subtitle: "Completar para el titular del certificado",
    city: "Ciudad",
    address: "Dirección",
    state: "Estado",
    zipcode: "Código postal",
    explaincoverage: "Explique la cobertura necesaria",
    datecoi: "Fecha en que se necesita COI",
    attachfiles: "Adjuntar copia del contrato y otros documentos de respaldo",
    emailcoi: "Correo electrónico del miembro que solicita COI",
    submit: "Enviar",
    fileslimit: "Seleccione hasta 10 archivos",
    rightsidebarwarning: "Si el titular del certificado es un ASEGURADO ADICIONAL, el contrato(s) debe estar respaldado. Una declaración de este certificado no confiere derechos al titular en relación a esos respaldos.",
  },
  errors: {
    fieldrequired: "Este campo es obligatorio",
    tooShort: "Muy corto!",
    tooLong: "Muy largo!",
    max10: "Seleccione hasta 10 archivos",
    mustbenumber: "Este campo debe ser un número",
    emailFormat: "Formato de correo electrónico inválido",
    invalidDate: "La fecha de solicitud del certificado no puede ser una fecha pasada",
    emailformat: "Este campo debe contener un correo electrónico de formato válido",
  },
  homepage: {
    featuredarticles: "Nuevos Articulos",
    featuredarticle: "Nuevo Articulo",
    featuresrecipes: "Nuevas recetas",
    seeallrecipes: "Mirar todas las recetas",
    seeallarticles: "Mirar todos los articulos",
    seeallclasses: "Mirar todas las clases",
    wellnessupcomingclasses: "Próximas clases de bienestar",
    quick: "Accesos ",
    access: "Rápidos",
    contactmiia: "CONTACTA MIIA",
    about: "ACERCA DE",
    contact: "CONTACTO",
    login: "INICIAR SESIÓN",
    logout: "Cerrar sesión",
    search: "Buscar...",
    welcome: "Bienvenido a el  ",
    member: "portal de MIIA  ",
    of: "DE",
    articles: "Artículos",
    recipes: "Recetas",
    contact2: "Contacto",
    youarein: "Usted esta en:",
    youareseeing: "USTED ESTA VIENDO:",
    memberportal: "Portal de miembros",
    portaladministration: "Portal de administración",
    twitter: "Tweets recientes",
    administratorLogin: "Acceso de administrador",
    membership: "es un Servicio de Membresía de la",
  },
  quickAccess: {
    title: "Acceso Rápido",
  },
  contentDownload: {
    title: "Descarga de Contenido",
  },
  newsletter: {
    title: "Lea la última edición",
  },
  footer: {
    about: "Acerca de",
    contact: "Contacto",
    articles: "Artículos",
    recipes: "Recetas",
    miiaAddress: "Dirección de MIIA",
    phone: "Teléfono",
    legal: "© 2022 MIIA. Todos los derechos reservados.",
    userAgreement: "Acuerdo Legal",
    privacy: "Política de Privacidad",
  },
  results: {
    title: "Resultados",
    noResults: "No pudimos encontrar lo que estaba buscando.",
  },
  riskmanagement: {
    programs: "Programas de Manejo de Riesgo",
    notifications: "NOTIFICACIONES",
    riskTraining: "Capacitaciones de Manejo de Riesgo",
    register: "Registrarse para Capacitación",
    request: "Solicitar Capacitación",
    recorded: "Capacitaciones Grabados",
    osha: "Capacitaciones OSHA",
  },
};
