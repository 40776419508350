import { createSlice } from '@reduxjs/toolkit'

const locationReducer = createSlice({
    name: 'location',
    initialState: {
        locationId: null,
        location: null,
        locationsList: null,
        errorLocation: null
    },
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload.location
            state.errorLocation = null
        },
        setAllLocation: (state, action) => {
            state.locationsList = action.payload.locationsList
            state.errorLocation = null
        },
        setErrorLocation: (state, action) => {
            state.location = null
            state.locationsList = null
            state.errorLocation = action.payload.errorLocation
        },
        setLocationId: (state, action) => {
            state.locationId = action.payload.locationId
        },
        cleanState: (state) => {
            state = locationReducer.initialState
        }
    },
})

export const { setLocation, cleanState, setErrorLocation, setAllLocation, setLocationId } = locationReducer.actions

export default locationReducer.reducer