import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { usePath } from "../hooks/usePath";
import UserService from '../services/api/UserService';

const ProtectedRoute = ({ children }) => {
  let location = useLocation();
  const path = usePath();

  if (!UserService.isLoggedIn()) {
    return <Navigate to={`/${path}`} state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;