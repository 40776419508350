// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../navbar/navbar.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.retiree-content {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  margin-left: 20px;
  margin-top: 10px;
}

.item-container {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 10px;
}

.menu-path {
  margin-left: 2rem;
}

.icon-retiree {
  font-size: 50px;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/retireesContainer/retireesContainer.scss","webpack://./src/sass/layout/retireesContainer/retireesContainer.css"],"names":[],"mappings":"AAKA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;EACA,uBAAA;EACA,qBAAA;EACA,iBAAA;EACA,gBAAA;ACHF;;ADMA;EACE,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;ACHF;;ADMA;EACE,iBAAA;ACHF;;ADUA;EACE,eAAA;EACA,kBAAA;ACPF","sourcesContent":["@import \"../../layout/navbar/navbar.css\";\n@import \"../../utilities/mixins\";\n@import \"../../utilities/variables\";\n \n\n.retiree-content {\n  display: flex;\n  flex-direction: column;\n  align-self: center;\n  justify-content: center;\n  align-items: flex-start;\n  align-content: center;\n  margin-left: 20px;\n  margin-top: 10px;\n}\n\n.item-container {\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n  background-color: rgba(255, 255, 255, 1);\n  border-radius: 12px;\n  padding: 15px;\n  margin-bottom: 10px;\n}\n\n.menu-path {\n  margin-left: 2rem;\n}\n\n\n\n\n\n.icon-retiree {\n  font-size: 50px;\n  align-self: center;\n}\n\n\n\n","@import \"../../layout/navbar/navbar.css\";\n.retiree-content {\n  display: flex;\n  flex-direction: column;\n  align-self: center;\n  justify-content: center;\n  align-items: flex-start;\n  align-content: center;\n  margin-left: 20px;\n  margin-top: 10px;\n}\n\n.item-container {\n  flex-direction: column;\n  justify-content: center;\n  width: 100%;\n  background-color: rgb(255, 255, 255);\n  border-radius: 12px;\n  padding: 15px;\n  margin-bottom: 10px;\n}\n\n.menu-path {\n  margin-left: 2rem;\n}\n\n.icon-retiree {\n  font-size: 50px;\n  align-self: center;\n}/*# sourceMappingURL=retireesContainer.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
