import { createSlice } from "@reduxjs/toolkit";

const homeReducer = createSlice({
  name: "counter",
  initialState: {
    carrouselTime: null,
    quickaccess: null,
    errorQuickAccess: null,
    dropdowns: null,
    errorDropdowns: null,
    navbarLinks: null,
    errorNavBarLinks: null,
    notifications: [],
    errorNotifications: null,
    tokenMedia: null,
    errorTokenMedia: null,
    navbarLinksRoles: null,
    errorNavBarLinksRoles: null,
    publicnotifications: [],
    errorPublicNotifications: null,
    privatenotifications: [],
    errorPrivateNotifications: null,
    errorCarrouselSlides: null,
    myUserInfo: null,
    errorMyUserInfo: null,
    newsletter: null,
    errorNewsletter: null,
    noLocation: [],
    buttons: [],
    location: [],
    locations: [],
    carrouselTime: [],
    errorLocation: null,
    blockPortalRedirections: false,
  },
  reducers: {
    setQuickAccess: (state, action) => {
      state.quickaccess = action.payload.quickaccess;
      state.errorQuickAccess = null;
    },
    setErrorGetLocation: (state, action) => {
      state.quickaccess = null;
      state.location = null;
      state.errorLocation = action.payload.errorLocation;
    },
    setErrorQuickAccess: (state, action) => {
      state.errorQuickAccess = action.payload.errorQuickAccess;
      state.quickaccess = null;
    },
    setDropdowns: (state, action) => {
      state.dropdowns = action.payload.dropdowns;
      state.errorDropdowns = null;
    },
    setButtonsInHome: (state, action) => {
      state.buttons = action.payload.buttons;
    },
    setErrorDropdowns: (state, action) => {
      state.errorDropdowns = action.payload.errorDropdowns;
      state.dropdowns = null;
    },
    setTokenMedia: (state, action) => {
      state.tokenMedia = action.payload.tokenMedia;
      state.errorTokenMedia = null;
    },
    setErrorTokenMedia: (state, action) => {
      state.tokenMedia = null;
      state.errorTokenMedia = action.payload.error;
    },
    setLocation: (state, action) => {
      state.location = action.payload.location;
    },
    setLocations: (state, action) => {
      state.locations = action.payload.locations;
    },

    setNoLocationNotifications: (state, action) => {
      state.noLocation = action.payload.nolocation;
    },

    setNotifications: (state, action) => {
      state.notifications = action.payload.notifications;
      state.errorNotifications = null;
    },
    setErrorNotifications: (state, action) => {
      state.notifications = null;
      state.errorNotifications = action.payload.error;
    },
    setNavBarLinks: (state, action) => {
      state.navbarLinks = action.payload.navbarLinks;
      state.errorNavBarLinks = null;
    },
    setNavBarLinksforRole: (state, action) => {
      state.navbarLinksRoles = action.payload.navbarLinksRoles;
      state.errorNavBarLinksRoles = null;
    },
    setErrorNavbarlinksRole: (state, action) => {
      state.errorNavBarLinksRoles = action.payload.errorNavBarLinksRoles;
      state.navbarLinksRoles = null;
    },
    setPublicNotifications: (state, action) => {
      state.publicnotifications = action.payload.publicnotifications;
    },
    setErrorPublicNotifications: (state, action) => {
      state.publicnotifications = null;
      state.errorPublicNotifications = action.payload.error;
    },
    setPrivateNotifications: (state, action) => {
      state.privatenotifications = action.payload.privatenotifications;
    },
    setErrorPrivateNotifications: (state, action) => {
      state.privatenotifications = null;
      state.errorPrivateNotifications = action.payload.error;
    },
    setFeaturedNews: (state, action) => {
      state.featuredNews = action.payload.featuredNews;
    },
    setCarrouselSlides: (state, action) => {
      state.carrouselSlides = action.payload.carrouselSlides.data;
    },
    setCarrouselTime: (state, action) => {
      state.carrouselTime = action.payload.carrouselTimeSlide;
    },
    setErrorCarrouselSlides: (state, action) => {
      state.carrouselSlides = null;
      state.errorCarrouselSlides = action.payload.error;
    },
    setMyUserInfo: (state, action) => {
      state.myUserInfo = action.payload.myUserInfo.data;
    },
    setErrorMyUserInfo: (state, action) => {
      state.myUserInfo = null;
      state.errorMyUserInfo = action.payload.error;
    },
    setNewsletter: (state, action) => {
      state.newsletter = action.payload.newsletter;
    },
    setErrorNewsletter: (state, action) => {
      state.newsletter = null;
      state.errorNewsletter = action.payload.error;
    },
    setBlockPortalRedirections: (state, action) => {
      state.blockPortalRedirections = action.payload;
    },
    cleanState: (state) => {
      state = homeReducer.initialState;
    },
  },
});

export const {
  setQuickAccess,
  setErrorQuickAccess,
  setDropdowns,
  setErrorDropdowns,
  setNavBarLinks,
  setErrorGetLocation,
  setNotifications,
  setErrorNotifications,
  setErrorTokenMedia,
  setTokenMedia,
  setNavBarLinksforRole,
  setErrorNavbarlinksRole,
  setPublicNotifications,
  setErrorPublicNotifications,
  setPrivateNotifications,
  setErrorPrivateNotifications,
  setFeaturedNews,
  setNoLocationNotifications,
  setButtonsInHome,
  setLocation,
  setLocations,
  setCarrouselSlides,
  setErrorCarrouselSlides,
  setMyUserInfo,
  setErrorMyUserInfo,
  setNewsletter,
  setErrorNewsletter,
  setCarrouselTime,
  setBlockPortalRedirections,
} = homeReducer.actions;

export default homeReducer.reducer;
