import { setErrorReport, createReport } from "../reducers/reportReducer";
import axios, { authHeader } from "../api/HttpService";

const API_URL = process.env.REACT_APP_API_URL;

export const createReportForm = (dispatch, reportClaim) => {
  axios
    .post(API_URL + `api/claim-property-and-liabilities`, reportClaim, {
      headers: authHeader(),
    })
    .then((response) => {
      dispatch(createReport({ report: response }));
    })
    .catch((error) => {
      dispatch(setErrorReport(error));
    });
};

export const createCertificateHolderForm = async (dispatch, formData) => {
  try {
    await axios
      .post(API_URL + `api/certificates-of-insurances`, formData, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch(createReport({ report: response }));
      });
  } catch (error) {
    dispatch(setErrorReport(error));
  }
};

export const setStatus = async (dispatch, formData) => {
  dispatch(createReport({ report: null }));
};
