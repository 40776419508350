import "../../sass/layout/articlesList/articlesList.css";
import Row from "react-bootstrap/Row";
import ArticleListContainer from "../articles/articlesListContainer";
import MenuPath from "../path/MenuPath";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ArticleList = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Row className="row-menu-container">
        <MenuPath name={t("article.articles")} />
      </Row>
      <ArticleListContainer />
    </div>
  );
};

export default ArticleList;
