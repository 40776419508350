// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wellness-badge {
  background-color: rgb(239, 176, 176);
  border-radius: 345px;
  padding: 3px 15px;
  font-family: "Lato", sans-serif !important;
  height: 2em;
  width: max-content;
}

.wellness-badge-text {
  font-size: 0.8em;
}`, "",{"version":3,"sources":["webpack://./src/sass/layout/wellness-badge/wellnessbadge.scss","webpack://./src/sass/layout/wellness-badge/wellnessbadge.css"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,oBAAA;EACA,iBAAA;EACA,0CAAA;EACA,WAAA;EACA,kBAAA;ACCD;;ADEA;EACC,gBAAA;ACCD","sourcesContent":[".wellness-badge {\n\tbackground-color: rgb(239, 176, 176);\n\tborder-radius: 345px;\n\tpadding: 3px 15px;\n\tfont-family: \"Lato\", sans-serif !important;\n\theight: 2em;\n\twidth: max-content;\n}\n\n.wellness-badge-text {\n\tfont-size: .8em;\n}",".wellness-badge {\n  background-color: rgb(239, 176, 176);\n  border-radius: 345px;\n  padding: 3px 15px;\n  font-family: \"Lato\", sans-serif !important;\n  height: 2em;\n  width: -moz-max-content;\n  width: max-content;\n}\n\n.wellness-badge-text {\n  font-size: 0.8em;\n}/*# sourceMappingURL=wellnessbadge.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
