import "../../sass/layout/requestcoi/requestcoi.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import MenuPath from "../path/MenuPath";
import { useTranslation } from "react-i18next";
import CertificateOfInsurance from "../certificateofinsurance/certificateofinsurance";

const RequestCOI = () => {
  const { t } = useTranslation();

  return (
    <div>
        <Row className="row-menu-container">
            <MenuPath
              name={t("navbar.propertyLiability")}
              nameItem={t("coi.title")}
            />
          </Row>
          <CertificateOfInsurance />
    </div>
  );
};

export default RequestCOI;
