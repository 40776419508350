import "../../sass/layout/welleneseventscontainer/wellnesseventscontainer.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import MenuPath from "../path/MenuPath";
import { useTranslation } from "react-i18next";
import DetailEvent from "../wellnessevents/detailEvent";
import { wellness } from "../../utils/wellnessclasses";
import { useNavigate, useParams } from "react-router-dom";
import { usePath } from "../../hooks/usePath";
import { useDispatch } from "react-redux";
import { getOnlineClasses } from "../../services/actions/wellnessclasses.action";

const WellnessEventDetail = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const path = usePath();
  const dispatch = useDispatch();

  const wellnessClassSelected = useSelector(
    (state) => state.wellnesseventsReducer.wellnessClassSelected
  );

  const locationId = useSelector(
    (state) => state.homeReducer.location?.data
  )?.uniqueNonGuessableId;

  const { id } = useParams();

  const classes = useSelector(
    (state) => state.wellnesseventsReducer.wellnessClasses
  );

  useEffect(() => {
  }, [classes, dispatch])

  useEffect(() => {
    if (locationId) getOnlineClasses(dispatch, locationId, id);
  }, [dispatch, locationId])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const onClickClassesList = () => {
    locationId !== "/"
    ? navigate(`/${path}/wellnessevents`)
    : navigate("/wellnessevents");
  };

  return (
    <div>
      {wellnessClassSelected && (
        <Col md={12} lg={12} xl={12} className="container-data-wellness">
          <Container fluid className="detail-container">
            <MenuPath
              name={t("wellnessEvents.wellnessPath")}
              nameItem={wellnessClassSelected ? wellnessClassSelected[wellness?.TITLE_EVENT] : ""}
              onClickItem={onClickClassesList}
            />
            <DetailEvent />
          </Container>
        </Col>
      )}
    </div>
  );
};

export default WellnessEventDetail;
