import "../sass/layout/twitterTimeline/twitterTimeline.css";
import TwitterTimeline from "react-twitter-embedded-timeline";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TwitterTimelineComponent = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className="twitter-container">
      <h4 className="font-twitter-title">{t("homepage.twitter")}</h4>
      <div className="container-tw">
        <TwitterTimeline
          user="https://twitter.com/miiasocial"
          chrome=" noheader noscrollbar noborders"
          height={420}
          loading="Loading tweets..."
        />
      </div>
    </Container>
  );
};

export default TwitterTimelineComponent;
