import { useState, useEffect } from "react";
import { fetchArticleById } from "../../services/actions/articles.action";

const fetchArticleData = async (articleId) => {
  const { data } = await fetchArticleById(articleId);
  return data;
};

export const useFetchArticleById = (articleId) => {
  const [article, setArticle] = useState();

  useEffect(() => {
    const getArticle = async () => {
      const articleData = await fetchArticleData(articleId);
      setArticle(articleData);
    };

    getArticle();
  }, [articleId]);

  return article;
};
